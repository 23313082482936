export type AssetStatusUpdateForm = {
    assetStatusId: string[] | string;
    threatStatusId: string[] | string;
    scheduleAssessmentTask1SiteAnalysisAndGeomapsSpatialDataPercentage: Number;
    scheduleAssessmentTask1HistoricalResearchPercentage: Number;
    scheduleAssessmentTask1ArchaeologicalResearchPercentage: Number;
    scheduleAssessmentTask3SiteVisitPercentage: Number;
    scheduleAssessmentTask2MataurangaPercentage: Number;
    scheduleAssessmentTask4ThreatRiskPercentage: Number;
    scheduleAssessmentTask5WanangaPercentage: Number;
    scheduleAssessmentTask5ManagementResponsePercentage: Number;
    planningStatusId: string[] | string;
    managementRecommendations: string[] | string;
    comments: string;
    lastMwCommsRecord: string;
    lastSiteVisit: Date | null;
    actionsRequired: string;
};

export const DefaultAssetStatusUpdateForm = {
    assetStatusId: [],
    threatStatusId: [],
    scheduleAssessmentTask1SiteAnalysisAndGeomapsSpatialDataPercentage: 0,
    scheduleAssessmentTask1HistoricalResearchPercentage: 0,
    scheduleAssessmentTask1ArchaeologicalResearchPercentage: 0,
    scheduleAssessmentTask3SiteVisitPercentage: 0,
    scheduleAssessmentTask2MataurangaPercentage: 0,
    scheduleAssessmentTask4ThreatRiskPercentage: 0,
    scheduleAssessmentTask5WanangaPercentage: 0,
    scheduleAssessmentTask5ManagementResponsePercentage: 0,
    planningStatusId: [],
    managementRecommendations: [],
    comments: "",
    lastMwCommsRecord: "",
    lastSiteVisit: null,
    actionsRequired: "",
};
