import { LogoutPath } from "components/header/NavMenu";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import {
    ApplicationPaths,
    QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

interface AuthorizeComponentState {
    ready: boolean;
    authenticated: boolean;
    isTokenExpired: boolean;
}

export default class AuthorizeComponent extends Component<
    any,
    AuthorizeComponentState
> {
    _subscription: number | undefined;

    constructor(props: { children: JSX.Element }) {
        super(props);
        this.state = {
            ready: false,
            authenticated: false,
            isTokenExpired: false,
        } as AuthorizeComponentState;
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() =>
            this.authenticationChanged()
        );
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        if (this._subscription) authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, isTokenExpired } = this.state;
        var link = document.createElement("a");
        link.href = window.location.pathname;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${
            QueryParameterNames.ReturnUrl
        }=${encodeURIComponent(returnUrl)}`;
        if (!ready) {
            return <div></div>;
        }
        const { children } = this.props;
        if (ready && authenticated && isTokenExpired) {
            return (
                <Navigate
                    to={ApplicationPaths.LogOut}
                    state={{ local: true }}
                />
            );
        }

        return authenticated ? <>{children}</> : <Navigate to={redirectUrl} />;
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const expired = await authService.isTokenExpired();
        this.setState({ ready: true, authenticated, isTokenExpired: expired });
    }

    async authenticationChanged() {
        this.setState({
            ready: false,
            authenticated: false,
            isTokenExpired: false,
        });
        await this.populateAuthenticationState();
    }
}
