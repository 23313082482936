import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

export type InputSliderProps = {
    modelKey: string,
    translatePrefix?: string,
    value: Number,
    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string[]> | Event, property: string): void
}

export function InputSlider(props: InputSliderProps) {

    const minSliderValue = "0";
    const maxSliderValue = "100";

    return (
        <Box>
            <InputLabel><FormattedMessage id={`${props.translatePrefix}.${props.modelKey}`} /></InputLabel>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        value={typeof props.value === 'number' ? props.value : 0}
                        onChange={(e) => props.onChange(e, props.modelKey)}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item>
                    <MuiInput
                        type="Number"
                        value={props.value}
                        size="small"
                        onChange={(e) => props.onChange(e, props.modelKey)}
                        inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                        onBlur={onBlur}
                    />
                </Grid>
            </Grid>
        </Box>
    );

    function onBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (Number(e.target.value) < Number(minSliderValue)) {
            e.target.value = minSliderValue;
        } else if (Number(e.target.value) > Number(maxSliderValue)) {
            e.target.value = maxSliderValue;
        }
        props.onChange(e, props.modelKey);
    };

}