import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { ExistingImpactsState } from "types/ExistingImpacts";
import { FutureThreatsState } from "types/FutureThreats";

interface RadioGroupProps {
    name: keyof FutureThreatsState | keyof ExistingImpactsState;
    value?: number | null;
    index?: number;
    onChange: (
        property: keyof FutureThreatsState | keyof ExistingImpactsState,
        value: string,
        index?: number
    ) => void;
}

export function RadioGroupInput({
    name,
    value,
    index,
    onChange,
}: RadioGroupProps) {
    return (
        <RadioGroup
            row
            aria-labelledby="radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ padding: 0, justifyContent: "flex-end" }}
            value={value ?? false}
            onChange={(event) => onChange(name, event.target.value, index)}
        >
            <FormControlLabel
                value={0}
                control={<Radio />}
                label={<Typography fontSize="small">N/A</Typography>}
                labelPlacement="bottom"
            />
            <FormControlLabel
                value={1}
                control={<Radio />}
                label={<Typography fontSize="small">1</Typography>}
                labelPlacement="bottom"
            />
            <FormControlLabel
                value={2}
                control={<Radio />}
                label={<Typography fontSize="small">2</Typography>}
                labelPlacement="bottom"
            />
            <FormControlLabel
                value={3}
                control={<Radio />}
                label={<Typography fontSize="small">3</Typography>}
                labelPlacement="bottom"
            />
            <FormControlLabel
                value={4}
                control={<Radio />}
                label={<Typography fontSize="small">4</Typography>}
                labelPlacement="bottom"
            />
            <FormControlLabel
                value={5}
                control={<Radio />}
                label={<Typography fontSize="small">5</Typography>}
                labelPlacement="bottom"
            />
        </RadioGroup>
    );
}
