export type EditDateCompleted = {
    field: DateCompletedField;
    date: Date | null;
};

export enum DateCompletedField {
    AssessmentProcessStarted,
    TraditionalKnowledgeComplete,
    TechnicalSiteAssessmentComplete,
    SiteVisitComplete,
    ThreatIssuesComplete,
    ManagementConfirmed,
    GovernanceNotified,
    PropertiesIdentified,
    LandownerAccessSought,
    LandownerApprovalReceived,
    FeedbackToLandownerGovernance,
}
