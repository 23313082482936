import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useEffect, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { MapUrl } from "types/MapUrl";

interface UrlContextState extends BaseContextState {
    alertLayerDetails?: MapUrl;
}

const contextDefaultValues: UrlContextState = {
    alertLayerDetails: undefined,
    error: undefined,
    isLoading: true,
};

export const AlertLayerUrlContext =
    createContext<UrlContextState>(contextDefaultValues);

export function AlertLayerUrlProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();

    const [alertLayerState, setAlertLayerState] =
        useState<UrlContextState>(contextDefaultValues);

    function GetCouncilMapUrl() {
        apiGet({
            path: `MapForIwi/GetAlertLayerUrl`,
            onSuccess: (data) =>
                setAlertLayerState({
                    ...contextDefaultValues,
                    alertLayerDetails: data,
                    isLoading: false,
                }),
            onError: (error) =>
                setAlertLayerState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                }),
        });
    }

    useEffect(() => {
        GetCouncilMapUrl();
    }, []);

    return (
        <AlertLayerUrlContext.Provider value={alertLayerState}>
            {children}
        </AlertLayerUrlContext.Provider>
    );
}
