export type ManagementResponseType = {
    activeProtectionLayerAlertLayer: boolean;
    scheduling: boolean;
    covenant: boolean;
    reserve: boolean;
    landAcquisition: boolean;
    transferOfPowers: boolean;
    heritageOrders: boolean;
    hnzptListing: boolean;
    precinct: boolean;
    managementPlan: boolean;
    coManagementGovernance: boolean;
    naming: boolean;
    totalUrbanDesignResponse: boolean;
    iwiManagementPlanSchedule: boolean;
    jointManagementAgreement: boolean;
    schedule6: boolean;
    schedule7: boolean;
    schedule9: boolean;
    schedule11: boolean;
    schedule12: boolean;
    schedule14: boolean;
    summary: string;
};

export const DefaultManagementResponseType: ManagementResponseType = {
    activeProtectionLayerAlertLayer: false,
    scheduling: false,
    covenant: false,
    reserve: false,
    landAcquisition: false,
    transferOfPowers: false,
    heritageOrders: false,
    hnzptListing: false,
    precinct: false,
    managementPlan: false,
    coManagementGovernance: false,
    naming: false,
    totalUrbanDesignResponse: false,
    iwiManagementPlanSchedule: false,
    jointManagementAgreement: false,
    schedule6: false,
    schedule7: false,
    schedule9: false,
    schedule11: false,
    schedule12: false,
    schedule14: false,
    summary: "",
};
