import { Card, CardContent, Typography, useTheme } from "@mui/material";
import { IntlShape, useIntl } from "react-intl";

interface ProgressCardProps {
    title: string;
    date?: Date | null;
    showText?: boolean;
}

export function ProgressCard(props: ProgressCardProps) {
    const theme = useTheme();

    const intl: IntlShape = useIntl();
    const section: string = "ProgressOutput";

    const title: string = intl.formatMessage({
        id: `${section}.${props.title}`,
    });

    const completed: string = intl.formatMessage({
        id: `${section}.Completed`,
    });

    const date = props.date && new Date(props.date);

    return (
        <Card
            sx={{
                width: props.showText
                    ? theme.card.widthWithText
                    : theme.card.widthWithoutText,
                height: props.showText
                    ? theme.card.heightWithText
                    : theme.card.heightWithoutText,
                borderRadius: theme.card.borderRadius,
                border: theme.card.border,
                borderColor: theme.card.borderColor,
            }}
        >
            <CardContent
                sx={{
                    backgroundColor: props.date
                        ? theme.card.completedBackgroundColor
                        : theme.card.inProgressBackgroundColor,
                    height: theme.card.cardContent.height,
                }}
            >
                {props.showText && (
                    <Typography
                        fontSize={theme.card.typography.fontSize}
                        color={
                            props.date
                                ? theme.card.completedTextColor
                                : theme.card.inProgressTextColor
                        }
                        textAlign="center"
                    >
                        {props.date ? `${title} ${completed}` : title}
                    </Typography>
                )}
                {props.showText && (
                    <Typography
                        fontSize={theme.card.typography.fontSize}
                        textAlign="center"
                    >
                        {date &&
                            date.toLocaleDateString("en-nz", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}
