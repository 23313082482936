import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DropDown } from "components/DropDown";
import { ChangeEvent } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CurrentManagementOfTheMaoriHeritageAssetForm } from "types/CurrentManagementOfTheMaoriHeritageAssetForm";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { CurrentManagementOfTheMaoriHeritageAssetOptions } from "../../types/Option";

const section: string = "CurrentManagementOfTheMaoriHeritageAsset";

type CurrentManagementOfTheMaoriHeritageAssetDisplayProps = {
    state: NominatedSiteState;
    setState: SetNominatedSiteState;
};

export function CurrentManagementOfTheMaoriHeritageAsset({
    state,
    setState,
}: CurrentManagementOfTheMaoriHeritageAssetDisplayProps) {
    const intl: IntlShape = useIntl();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="current-management-of-the-mch-asset-content"
                id="current-management-of-the-mch-asset-content-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5">
                    <FormattedMessage id={`${section}.heading`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(e, "siteExtentConfirmed")
                                }
                                checked={state.siteExtentConfirmed}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.siteExtentConfirmed`,
                        })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(e, "managementStatement")
                                }
                                checked={state.managementStatement}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.managementStatement`,
                        })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.managementStatementNotes`,
                        })}
                        variant="outlined"
                        value={state.managementStatementNotes}
                        onChange={(e) =>
                            OnChange(e, "managementStatementNotes")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500}}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.iwiManagementPlan`,
                        })}
                        variant="outlined"
                        value={state.iwiManagementPlan}
                        onChange={(e) => OnChange(e, "iwiManagementPlan")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500}}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.treatySettlementCulturalCommercialRedress`,
                        })}
                        value={state.treatySettlementCulturalCommercialRedress}
                        onChange={(e) =>
                            OnChange(
                                e,
                                "treatySettlementCulturalCommercialRedress"
                            )
                        }
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.statutoryAcknowledgementArea`,
                        })}
                        variant="outlined"
                        value={state.statutoryAcknowledgementArea}
                        onChange={(e) =>
                            OnChange(e, "statutoryAcknowledgementArea")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(
                                        e,
                                        "haurakiGulfMarineParkAct"
                                    )
                                }
                                checked={state.haurakiGulfMarineParkAct}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.haurakiGulfMarineParkAct`,
                        })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(
                                        e,
                                        "waitakereRangesHeritageAreaAct"
                                    )
                                }
                                checked={state.waitakereRangesHeritageAreaAct}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.waitakereRangesHeritageAreaAct`,
                        })}
                    />
                    <DropDown
                        modelKey={"leases"}
                        translatePrefix={section}
                        values={state.leasesId}
                        options={
                            CurrentManagementOfTheMaoriHeritageAssetOptions.leases
                        }
                        onChange={(e) => OnChange(e, "leasesId")}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.leasesNotes`,
                        })}
                        variant="outlined"
                        value={state.leasesNotes}
                        onChange={(e) => OnChange(e, "leasesNotes")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({ id: `${section}.chiId` })}
                        variant="outlined"
                        value={state.chiId}
                        onChange={(e) => OnChange(e, "chiId")}
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({ id: `${section}.nzaaId` })}
                        variant="outlined"
                        value={state.nzaaId}
                        onChange={(e) => OnChange(e, "nzaaId")}
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.heritageNewZealandPouhereTaongaListIdD`,
                        })}
                        value={state.heritageNewZealandPouhereTaongaListIdD}
                        onChange={(e) =>
                            OnChange(
                                e,
                                "heritageNewZealandPouhereTaongaListIdD"
                            )
                        }
                        variant="outlined"
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.precinctId`,
                        })}
                        variant="outlined"
                        value={state.precinctId}
                        onChange={(e) => OnChange(e, "precinctId")}
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.paupSitesOfValueToManaWhenuaId`,
                        })}
                        variant="outlined"
                        value={state.paupSitesOfValueToManaWhenuaId}
                        onChange={(e) =>
                            OnChange(e, "paupSitesOfValueToManaWhenuaId")
                        }
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule12SiteOfSignificantToManaWhenua`,
                        })}
                        variant="outlined"
                        value={state.schedule12SiteOfSignificantToManaWhenua}
                        onChange={(e) =>
                            OnChange(
                                e,
                                "schedule12SiteOfSignificantToManaWhenua"
                            )
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(
                                        e,
                                        "schedule12SiteRuleException"
                                    )
                                }
                                checked={state.schedule12SiteRuleException}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.schedule12SiteRuleException`,
                        })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule14HistoricHeritageProtection`,
                        })}
                        variant="outlined"
                        value={state.schedule14HistoricHeritageProtection}
                        onChange={(e) =>
                            OnChange(e, "schedule14HistoricHeritageProtection")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule6OutstandingNaturalFeature`,
                        })}
                        variant="outlined"
                        value={state.schedule6OutstandingNaturalFeature}
                        onChange={(e) =>
                            OnChange(e, "schedule6OutstandingNaturalFeature")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule7OutstandingNaturalLandscape`,
                        })}
                        variant="outlined"
                        value={state.schedule7OutstandingNaturalLandscape}
                        onChange={(e) =>
                            OnChange(e, "schedule7OutstandingNaturalLandscape")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule9VolcanicViewshaft`,
                        })}
                        variant="outlined"
                        value={state.schedule9VolcanicViewshaft}
                        onChange={(e) =>
                            OnChange(e, "schedule9VolcanicViewshaft")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.schedule11LocalPublicViewshaft`,
                        })}
                        variant="outlined"
                        value={state.schedule11LocalPublicViewshaft}
                        onChange={(e) =>
                            OnChange(e, "schedule11LocalPublicViewshaft")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.hgipAppendix1fMaoriHeritage`,
                        })}
                        variant="outlined"
                        value={state.hgipAppendix1fMaoriHeritage}
                        onChange={(e) =>
                            OnChange(e, "hgipAppendix1fMaoriHeritage")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.notableTreeId`,
                        })}
                        variant="outlined"
                        value={state.notableTreeId}
                        onChange={(e) => OnChange(e, "notableTreeId")}
                        multiline
                        inputProps={{ maxLength: 1500 }}
                    />
                    <DropDown
                        modelKey={"overlaysThatApplys"}
                        translatePrefix={section}
                        values={state.overlaysThatApplys}
                        options={
                            CurrentManagementOfTheMaoriHeritageAssetOptions.overlaysThatApplys
                        }
                        onChange={(e) => OnChange(e, "overlaysThatApplys")}
                        multiple
                    />
                    <DropDown
                        modelKey={"landUses"}
                        translatePrefix={section}
                        values={state.landUses}
                        options={
                            CurrentManagementOfTheMaoriHeritageAssetOptions.landUses
                        }
                        onChange={(e) => OnChange(e, "landUses")}
                        multiple
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.landUseNotes`,
                        })}
                        variant="outlined"
                        value={state.landUseNotes}
                        onChange={(e) => OnChange(e, "landUseNotes")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.landCover`,
                        })}
                        variant="outlined"
                        value={state.landCover}
                        onChange={(e) => OnChange(e, "landCover")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) => onChangeBoolean(e, "covenant")}
                                checked={state.covenant}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.covenant`,
                        })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.covenantNotes`,
                        })}
                        variant="outlined"
                        value={state.covenantNotes}
                        onChange={(e) => OnChange(e, "covenantNotes")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) => onChangeBoolean(e, "reserve")}
                                checked={state.reserve}
                            />
                        }
                        label={intl.formatMessage({ id: `${section}.reserve` })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.reserveNotes`,
                        })}
                        variant="outlined"
                        value={state.reserveNotes}
                        onChange={(e) => OnChange(e, "reserveNotes")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(e, "transferOfPowers")
                                }
                                checked={state.transferOfPowers}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.transferOfPowers`,
                        })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.transferOfPowersNotes`,
                        })}
                        variant="outlined"
                        value={state.transferOfPowersNotes}
                        onChange={(e) => OnChange(e, "transferOfPowersNotes")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    onChangeBoolean(
                                        e,
                                        "coManagementGovernanceAgreements"
                                    )
                                }
                                checked={state.coManagementGovernanceAgreements}
                            />
                        }
                        label={intl.formatMessage({
                            id: `${section}.coManagementGovernanceAgreements`,
                        })}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.coManagementGovernanceAgreementsNotes`,
                        })}
                        variant="outlined"
                        value={state.coManagementGovernanceAgreementsNotes}
                        onChange={(e) =>
                            OnChange(e, "coManagementGovernanceAgreementsNotes")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <DropDown
                        modelKey={"culturalValuesAssignedB652s"}
                        translatePrefix={section}
                        values={state.culturalValuesAssignedB652s}
                        options={
                            CurrentManagementOfTheMaoriHeritageAssetOptions.culturalValuesAssignedB652s
                        }
                        onChange={(e) =>
                            OnChange(e, "culturalValuesAssignedB652s")
                        }
                        multiple
                    />
                    <DropDown
                        modelKey={"environmentalAndCulturalRisks"}
                        translatePrefix={section}
                        values={state.environmentalAndCulturalRisks}
                        options={
                            CurrentManagementOfTheMaoriHeritageAssetOptions.environmentalAndCulturalRisks
                        }
                        onChange={(e) =>
                            OnChange(e, "environmentalAndCulturalRisks")
                        }
                        multiple
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.environmentalAndCulturalRisksNotes`,
                        })}
                        variant="outlined"
                        value={state.environmentalAndCulturalRisksNotes}
                        onChange={(e) =>
                            OnChange(e, "environmentalAndCulturalRisksNotes")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.prohibitedActivitiesAndBehaviour`,
                        })}
                        variant="outlined"
                        value={state.prohibitedActivitiesAndBehaviour}
                        onChange={(e) =>
                            OnChange(e, "prohibitedActivitiesAndBehaviour")
                        }
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500}}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );

    function OnChange(
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string[] | string | Event>,
        property: keyof CurrentManagementOfTheMaoriHeritageAssetForm
    ) {
        setState({ ...state, [property]: event.target.value });
    }

    function onChangeBoolean(
        event: ChangeEvent<HTMLInputElement>,
        property: keyof CurrentManagementOfTheMaoriHeritageAssetForm
    ) {
        setState({ ...state, [property]: event.target.checked });
    }
}
