import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
    FileManagerState,
    FileManagerWrapper,
} from "components/FileManager/FileManagerWrapper";
import ProgressInput from "components/ProgressInput";
import { RoleContext } from "context/roleContext";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";

interface ReviewOfTask1Props {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task2.ReviewOfTask1";

export function ReviewOfTask1({
    dateCompleted,
    progressOnClick,
}: ReviewOfTask1Props) {
    const [fileState, setFileState] = useState<FileManagerState>({
        files: [],
        uploading: false,
        numberOfFiles: 0,
    });

    const roleContext = useContext(RoleContext);
    const canAddOrDelete: boolean =
        roleContext.roles?.isSysAdmin ||
        roleContext.roles?.isCouncilUser ||
        roleContext.roles?.isIwiUser ||
        false;

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="task1-review-content"
                id="task1-review-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>

                    <Typography component="h1" variant="h5" align="right">
                        {fileState.numberOfFiles}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.ReferToGuide`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage
                                id={`${section}.ReviewKnownInformationTitle`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.AddFileDescription`}
                            />
                        </Typography>

                    </Grid>
                    <Grid item md={12}>

                        <FileManagerWrapper
                            documentType="BaselineAssessment"
                            state={fileState}
                            setState={setFileState}
                            enableAddFile={canAddOrDelete}
                            disableDeleteFile={!canAddOrDelete}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="assessmentProcessStarted"
                            useStartIcon
                            field={DateCompletedField.AssessmentProcessStarted}
                            dateCompleted={dateCompleted}
                            progressOnClick={progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
