import PrintIcon from "@mui/icons-material/Print";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { DropDown } from "components/DropDown";
import ProgressInput from "components/ProgressInput";
import { ChangeEvent } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SiteVisitRecordOptions } from "types/Option";
import { SiteVisitContributors, SiteVisitRecord } from "types/SiteVisitRecord";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { SiteInformationSummary } from "types/SiteInformationSummary";
import { Margins } from "pdfmake/interfaces";
import { DateTime } from "luxon";
import { TechnicalSiteAssessment } from "types/TechnicalSiteAssessment";
import { TechnicalSiteAssessmentEntry } from '../../types/TechnicalSiteAssessment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    'Roboto': {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    }
};

interface SiteVisitRecordProps {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
    state: SiteVisitRecord;
    siteInformationSummaryState: SiteInformationSummary;
    techSiteState: TechnicalSiteAssessment;
    setState: (value: SiteVisitRecord) => void;
    nominatedSiteState?: NominatedSiteState;
}

const section: string = "Task3.SiteVisitRecord";

export function SiteVisitRecordForm(props: SiteVisitRecordProps) {
    const intl: IntlShape = useIntl();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5" align="left">
                    <FormattedMessage id={`${section}.SectionTitle`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails id={`${section}-contents`}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item md={12}>
                        <Button variant="outlined" startIcon={<PrintIcon />} onClick={printRecord}>
                            <FormattedMessage
                                id={`${section}.GenerateTaskSummaryReport`}
                            />
                        </Button>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl>
                            <FormLabel required>
                                <FormattedMessage
                                    id={`${section}.siteVisitDate`}
                                />
                            </FormLabel>
                            <DatePicker
                                label="dd/mm/yyyy"
                                value={props.state.siteVisitDate}
                                onChange={(newValue: Date | null | undefined) =>
                                    newValue &&
                                    onChangeDate(newValue, "siteVisitDate")
                                }
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        onChangeBoolean(
                                            e,
                                            "isExistingScheduleInformationConsistent"
                                        )
                                    }
                                    checked={
                                        props.state
                                            .isExistingScheduleInformationConsistent
                                    }
                                />
                            }
                            label={intl.formatMessage({
                                id: `${section}.isExistingScheduleInformationConsistent`,
                            })}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.oralEvidence`,
                            })}
                            value={props.state.oralEvidence}
                            onChange={(event) =>
                                onChangeEntry(
                                    "oralEvidence",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <DropDown
                            modelKey={"siteCondition"}
                            translatePrefix={section}
                            values={props.state.siteCondition}
                            options={SiteVisitRecordOptions.siteConditions}
                            onChange={(e) => onChange(e, "siteCondition")}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.natureOfDamage`,
                            })}
                            value={props.state.natureOfDamage}
                            onChange={(event) =>
                                onChangeEntry(
                                    "natureOfDamage",
                                    event.target.value
                                )
                            }
                            placeholder={intl.formatMessage({
                                id: `${section}.natureOfDamage.Placeholder`,
                            })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.currentManagementAppropriateness`,
                            })}
                            value={props.state.currentManagementAppropriateness}
                            onChange={(event) =>
                                onChangeEntry(
                                    "currentManagementAppropriateness",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.inappropriateActivities`,
                            })}
                            value={props.state.inappropriateActivities}
                            onChange={(event) =>
                                onChangeEntry(
                                    "inappropriateActivities",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.recommendedManagementTechniques`,
                            })}
                            value={props.state.recommendedManagementTechniques}
                            onChange={(event) =>
                                onChangeEntry(
                                    "recommendedManagementTechniques",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        onChangeBoolean(e, "isLandmark")
                                    }
                                    checked={props.state.isLandmark}
                                />
                            }
                            label={intl.formatMessage({
                                id: `${section}.isLandmark`,
                            })}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        onChangeBoolean(
                                            e,
                                            "isInCoastalMarineArea"
                                        )
                                    }
                                    checked={props.state.isInCoastalMarineArea}
                                />
                            }
                            label={intl.formatMessage({
                                id: `${section}.isInCoastalMarineArea`,
                            })}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        onChangeBoolean(
                                            e,
                                            "isInMeanHighWaterSpring"
                                        )
                                    }
                                    checked={
                                        props.state.isInMeanHighWaterSpring
                                    }
                                />
                            }
                            label={intl.formatMessage({
                                id: `${section}.isInMeanHighWaterSpring`,
                            })}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.audioVideoRecord`,
                            })}
                            value={props.state.audioVideoRecord}
                            onChange={(event) =>
                                onChangeEntry(
                                    "audioVideoRecord",
                                    event.target.value
                                )
                            }
                            placeholder={intl.formatMessage({
                                id: `${section}.audioVideoRecord.Placeholder`,
                            })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        onChangeBoolean(e, "wasGpsRecorded")
                                    }
                                    checked={props.state.wasGpsRecorded}
                                />
                            }
                            label={intl.formatMessage({
                                id: `${section}.wasGpsRecorded`,
                            })}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="h5">
                            <FormattedMessage id={`${section}.Contributors`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.manaWhenua`,
                            })}
                            value={props.state.contributors.manaWhenua}
                            onChange={(event) =>
                                onChangeContributors(
                                    "manaWhenua",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.planner`,
                            })}
                            value={props.state.contributors.planner}
                            onChange={(event) =>
                                onChangeContributors(
                                    "planner",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.researcher`,
                            })}
                            value={props.state.contributors.researcher}
                            onChange={(event) =>
                                onChangeContributors(
                                    "researcher",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.archaeologist`,
                            })}
                            value={props.state.contributors.archaeologist}
                            onChange={(event) =>
                                onChangeContributors(
                                    "archaeologist",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.historian`,
                            })}
                            value={props.state.contributors.historian}
                            onChange={(event) =>
                                onChangeContributors(
                                    "historian",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.parks`,
                            })}
                            value={props.state.contributors.parks}
                            onChange={(event) =>
                                onChangeContributors(
                                    "parks",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.property`,
                            })}
                            value={props.state.contributors.property}
                            onChange={(event) =>
                                onChangeContributors(
                                    "property",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.consultants`,
                            })}
                            value={props.state.contributors.consultants}
                            onChange={(event) =>
                                onChangeContributors(
                                    "consultants",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.contributors.landowners`,
                            })}
                            value={props.state.contributors.landowners}
                            onChange={(event) =>
                                onChangeContributors(
                                    "landowners",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="siteVisitComplete"
                            field={DateCompletedField.SiteVisitComplete}
                            dateCompleted={props.dateCompleted}
                            progressOnClick={props.progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    function onChangeContributors(
        property: keyof SiteVisitContributors,
        value: string | boolean | undefined
    ) {
        props.setState({
            ...props.state,
            contributors: { ...props.state.contributors, [property]: value },
        });
    }

    function onChangeEntry(
        property: keyof SiteVisitRecord,
        value: string | boolean | undefined
    ) {
        props.setState({ ...props.state, [property]: value });
    }

    function onChangeDate(
        value: Date | undefined | null,
        property: keyof SiteVisitRecord
    ) {
        props.setState({ ...props.state, [property]: value });
    }

    function onChange(
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string[] | string>,
        property: keyof SiteVisitRecord
    ) {
        props.setState({ ...props.state, [property]: event.target.value[0] });
    }

    function onChangeBoolean(
        event: ChangeEvent<HTMLInputElement>,
        property: keyof SiteVisitRecord
    ) {
        props.setState({ ...props.state, [property]: event.target.checked });
    }

    function headerTableRow(header: string) {
        return [
            { colSpan: 2, text: header, style: ['subheader', 'shadedBox'] },
            {}
        ];
    }

    function textEntryTableRow(title: string, value: string | string[], placeholder: string, blankRowCount: number) {
        var content = [];
        if (placeholder) {
            content.push({ text: placeholder, style: 'placeholder' });
        }
        content.push({ text: (value || '').toString() });
        for (var i = 0; i < blankRowCount; i++) {
            content.push({ text: '\xa0' });
        }

        return [
            { text: title, style: 'boxHeader' },
            content
        ];
    }

    function singleLineTableRow(title: string, value: string | string[], placeholder: string) {
        return textEntryTableRow(title, value, placeholder, 3);
    }

    function multilineTableRow(title: string, value: string | string[], placeholder: string) {
        return textEntryTableRow(title, value, placeholder, 7);
    }

    function yesNoText(title: string, val: boolean) {
        return {
            text: [
                { text: 'YES', bold: val, color: val ? '#000' : '#888' },
                ' / ',
                { text: 'NO', bold: !val, color: !val ? '#000' : '#888' },
                '     ',
                title
            ]
        };
    }

    function yesNoTableRow(title: string, value: boolean, placeholder: string) {
        var content = [];
        if (placeholder) {
            content.push({ text: placeholder, style: 'placeholder' });
        }
        content.push(yesNoText('', value));

        return [
            { text: title, style: 'boxHeader' },
            content
        ];
    }

    function siteAssessmentEntryTableRow(title: string, siteAssessmentEntry: TechnicalSiteAssessmentEntry) {
        return [
            { text: title, style: 'boxHeader' },
            [
                { text: siteAssessmentEntry.description || '\xa0' },
                { text: siteAssessmentEntry.source || '\xa0' },
                yesNoText('Sensitive Information Held', siteAssessmentEntry.hasSensitiveInformation!),
                { text: '\xa0' },
                { text: '\xa0' },
                { text: '\xa0' },
                { text: '\xa0' },
                { text: '\xa0' },
                { text: '\xa0' },
                { text: '\xa0' }
            ]
        ];
    }

    function printRecord() {
        var siteVisitDate = DateTime.fromJSDate(props.state.siteVisitDate!);
        var docDefinition = {
            content: [
                { text: props.nominatedSiteState?.assetName!, style: 'header' },
                { text: 'Māori Cultural Heritage Programme Technical Review of Māori Heritage and Site Visit Record [Template to assist Task 3]', style: 'paragraph' },
                { text: 'Task 1 | Information Gathering', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: [100, '*'],
                        body: [
                            [
                                {
                                    colSpan: 2,
                                    style: 'shadedBox',
                                    ul: [
                                        intl.formatMessage({
                                            id: `${section}.SiteInfo.List1`
                                        }),
                                        intl.formatMessage({
                                            id: `${section}.SiteInfo.List2`
                                        }),
                                        intl.formatMessage({
                                            id: `${section}.SiteInfo.List3`
                                        }),
                                    ]
                                },
                                {}
                            ],
                            headerTableRow('Summary'),
                            singleLineTableRow('Your name', '', ''),
                            singleLineTableRow('Site name', props.nominatedSiteState?.assetName!, 'This will include reference name of place(s)'),
                            singleLineTableRow('Address', props.nominatedSiteState?.addressLocation!, 'Physical street address(es) or location by GPS'),
                            multilineTableRow('Details', props.nominatedSiteState?.keyAssetFeaturesText!, ''),
                            multilineTableRow(
                                'Physical description of site or feature',
                                props.siteInformationSummaryState.physicalDescription!,
                                'Physical features or characteristics of place - land, waterway or waterbody, other'),
                            multilineTableRow('Legal description', props.nominatedSiteState?.legalDescription!, 'Legal site description details - may be list of titles here'),
                            singleLineTableRow('Current zoning', props.nominatedSiteState?.zonings!, 'Unitary Plan zone references'),
                            singleLineTableRow('Overlays', props.nominatedSiteState?.overlaysThatApplys!, 'Existing overlays identitifed on site'),
                            multilineTableRow(
                                'Land Tenure or Status of Land',
                                props.nominatedSiteState?.landStatusId!,
                                'i. Fee simple; ii. Reserve (type of reserve); iii. Conservation Area; iv. Crown land; v. Other status (eg. Auckland Domain Act)'),
                            multilineTableRow(
                                'Site or Place of Value to Mana Whenua',
                                props.nominatedSiteState?.manaWhenuaRohes!,
                                'Reference to any specific site(s) or place(s) of Mana Whenua covered by the site - including number reference'),
                            multilineTableRow(
                                'Site of Significance to Mana Whenua',
                                props.siteInformationSummaryState.sitesOfSignificance!,
                                'Reference to any scheduled site(s) or place(s) in Unitary Plan, including number and name reference'),
                            [
                                { text: 'Existing Screening Values', style: 'boxHeader' },
                                [
                                    yesNoText('Mauri', props.siteInformationSummaryState.screeningValueMauri!),
                                    yesNoText('W\u0101hi Tapu', props.siteInformationSummaryState.screeningValueWahiTapu!),
                                    yesNoText('Rawa Tuturu / Customary Resources', props.siteInformationSummaryState.screeningValueCustomaryResources!),
                                    yesNoText('Hiahiatanga Tuturu / Customary Needs', props.siteInformationSummaryState.screeningValueCustomaryNeeds!),
                                    yesNoText('Whakaaronui O Te Wa / Contemporary Esteem', props.siteInformationSummaryState.screeningValueContemporaryEsteem!)
                                ]
                            ],
                            headerTableRow('Other Existing Information Indicating \'Mana Whenua Values\' From Historic Files (Auckland Council)'),

                            multilineTableRow('CHI File References', props.siteInformationSummaryState.chiFileReferences!, 'Information held in CHI'),
                            multilineTableRow(
                                'Other Physical Site File References',
                                props.siteInformationSummaryState.otherPhysicalFileReferences!,
                                'Archaeological survey, earlier CIA assessments or similar considered potentially relevant to the site'),
                            headerTableRow('Current Land Use Record (Auckland Council)'),
                            multilineTableRow('Property Owner Detail', props.siteInformationSummaryState.physicalOwner!, 'Information on current land owner, including contact details and name'),
                            multilineTableRow('Leasees', props.siteInformationSummaryState.leasees!, 'If relevant'),
                            multilineTableRow('Current Land Use(s)', props.siteInformationSummaryState.currentLandUse!, 'Site description details of current activities on the site'),
                            headerTableRow('Previous Protection Status'),
                            multilineTableRow('Legacy Plan', props.siteInformationSummaryState.previousProtectionStatus.legacyPlan!, ""),
                            multilineTableRow('Property Owner Detail', props.siteInformationSummaryState.physicalOwner!, ""),
                            headerTableRow('Research by Council'),
                        ]
                    }
                },
                { text: 'Task 2 | Mātauranga Māori', style: 'header' },
                {
                    table: {
                        widths: [100, '*'],
                        body: [
                            headerTableRow('Review Known Public / External Information'),
                            headerTableRow('Collation of Knowledge'),
                        ]
                    }
                },
                { text: 'Technical Site Assessment', style: 'header' },
                {
                    table: {
                        widths: [100, '*'],
                        body: [
                            [{
                                colSpan: 2,
                                stack: [
                                    { text: 'PAUP Criteria', style: 'subheader' },
                                    { text: 'Description of Mana Whenua values and associations with the site or feature', style: 'paragraph' }
                                ],
                                style: 'shadedBox'
                            }, {}],
                            siteAssessmentEntryTableRow('Mauri', props.techSiteState.mauri!),
                            siteAssessmentEntryTableRow('Wāhi Tapu', props.techSiteState.wahiTapu),
                            siteAssessmentEntryTableRow('Korero Tuturu / Historical', props.techSiteState.historical),
                            siteAssessmentEntryTableRow('Rawa Tuturu / Customary Resources', props.techSiteState.customaryResources),
                            siteAssessmentEntryTableRow('Hiahiatanga Tuturu / Customary Needs', props.techSiteState.customaryNeeds),
                            siteAssessmentEntryTableRow('Whakaaronui o te Wa / Contemporary Esteem', props.techSiteState.contemporaryEsteem),
                            siteAssessmentEntryTableRow('Horopaki', props.techSiteState.horopaki)
                        ]
                    }
                },
                { text: 'Task 3 | Landown Engagement / Site Visit', style: 'header' },
                {
                    table: {
                        widths: [100, '*'],
                        body: [
                            headerTableRow('Landowner / Iwi / Council Governance Communication'),
                            headerTableRow('Confirm Properties'),
                            [{
                                colSpan: 2,
                                stack: [
                                    { text: 'Site Visit Record', style: 'subheader' },
                                    { text: 'For Mana Whenua to lead completion', style: 'paragraph' }
                                ],
                                style: 'shadedBox'
                            }, {}],
                            singleLineTableRow('Site visit date', siteVisitDate.isValid ? siteVisitDate.toFormat('yyyy LLL dd') : '', ''),
                            yesNoTableRow(
                                'Is the name and location of the site / area consistent with existing schedule information (if applicable)?',
                                props.state.isExistingScheduleInformationConsistent!, ''),
                            multilineTableRow('Oral evidence provided by the Kaumatua if permission granted', props.state.oralEvidence!, ''),
                            singleLineTableRow('What is the condition of the site (1 - Poor, 5 - Good)', props.state.siteCondition!, ''),
                            multilineTableRow('What is the nature of any damage to the site', props.state.natureOfDamage, '(Integrity – intact, altered, reversible)'),
                            multilineTableRow('Are the current management techniques appropriate', props.state.currentManagementAppropriateness!, ''),
                            multilineTableRow('What activities are inappropriate for the area', props.state.inappropriateActivities!, ''),
                            multilineTableRow('Management techniques recommended by iwi representatives eg. Kaumatua, governance', props.state.recommendedManagementTechniques!, ''),
                            yesNoTableRow('Are there views or vistas to the item / area', props.state.isLandmark!, ''),
                            yesNoTableRow('Is the item / area within the Coastal Marine Area (CMA)', props.state.isInCoastalMarineArea!, ''),
                            [
                                { text: 'Does the item surrounds extend into the Mean High Water Spring (MHWS), and to what extent', style: 'boxHeader' },
                                [
                                    yesNoText('Does the item surrounds extend into the MHWS', props.state.isInMeanHighWaterSpring!),
                                    props.state.extentInMeanHighWaterSpring! || '',
                                    { text: '\xa0' },
                                    { text: '\xa0' },
                                    { text: '\xa0' },
                                    { text: '\xa0' },
                                    { text: '\xa0' },
                                    { text: '\xa0' },
                                    { text: '\xa0' }
                                ]
                            ],
                            multilineTableRow('Photographic / Video / Audio record', props.state.audioVideoRecord!, 'Describe locations where photos/videos/audio are taken. Attach photos/video/audio in the website.'),
                            yesNoTableRow(
                                'GPS Record',
                                props.state.wasGpsRecorded!,
                                'Did you record GPS coordinates on the Map in Task 3? Use the tool to record polygons of the site if larger than an individual point / if the exact location is sensitive.'),
                            headerTableRow('Contributors'),
                            singleLineTableRow('Mana Whenua', props.state.contributors.manaWhenua!, ''),
                            singleLineTableRow('Planner', props.state.contributors.planner!, ''),
                            singleLineTableRow('Researcher', props.state.contributors.researcher!, ''),
                            singleLineTableRow('Archaeologist', props.state.contributors.archaeologist!, ''),
                            singleLineTableRow('Historian', props.state.contributors.historian!, ''),
                            singleLineTableRow('Parks', props.state.contributors.parks!, ''),
                            singleLineTableRow('Property', props.state.contributors.property!, ''),
                            singleLineTableRow('Consultants', props.state.contributors.consultants!, ''),
                            singleLineTableRow('Landowners', props.state.contributors.landowners!, '')
                        ]
                    }
                },

            ],
            styles: {
                header: {
                    margin: [0, 20, 0, 0] as Margins,
                    fontSize: 20,
                    bold: true
                },
                subheader: {
                    fontSize: 16,
                    bold: true
                },
                writingSpace: {
                    margin: [0, 40, 0, 0] as Margins
                },
                multilineWritingSpace: {
                    margin: [0, 100, 0, 0] as Margins
                },
                boxHeader: {
                    bold: true
                },
                placeholder: {
                    italics: true,
                    color: '#888'
                },
                yesNo: {
                    margin: 5
                },
                paragraph: {
                    margin: [0, 20, 0, 0] as Margins
                },
                shadedBox: {
                    fillColor: '#D3D3D3'
                }
            }
        };

        pdfMake.createPdf(docDefinition).download(
            props.nominatedSiteState?.assetName + ".pdf");
    }
}
