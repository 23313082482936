import { Add, ChevronLeft, Delete } from "@mui/icons-material";
import {
    Alert,
    AlertProps,
    Button,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FetchError } from "../components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "../hooks/useApiFetch";
import { Option } from "../types/Option";

const section: string = "LookupManagement";

export function LookupManagement() {
    const navigate = useNavigate();
    const { apiGet, apiPost } = useApiFetch();
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const intl: IntlShape = useIntl();

    const [councilResearchCategories, setCouncilResearchCategories] =
        useState<Option[]>();
    const [optionToAdd, setOptionToAdd] = useState("");
    const [loadData, setLoadData] = useState(true);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            getCouncilResearchCategories();
        }
    }, [loadData]);

    const listItems = councilResearchCategories?.map(
        (councilResearchCategory) => (
            <OptionListItem option={councilResearchCategory} />
        )
    );

    return (
        <Container maxWidth="md">
            <Typography component="h1" variant="h5">
                <FormattedMessage id={`${section}.SectionTitle`} />
            </Typography>

            <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id="option-label">
                    <FormattedMessage id={`${section}.LookupDropdownLabel`} />
                </InputLabel>
                <Select labelId="option-label" label="Option" value={1}>
                    <MenuItem value={1} key={1}>
                        <FormattedMessage
                            id={`${section}.CouncilResearchCategory`}
                        />
                    </MenuItem>
                </Select>
            </FormControl>

            <Typography component="h2" variant="h6">
                <FormattedMessage id={`${section}.EntriesTitle`} />
            </Typography>

            <List>{listItems}</List>

            <Paper
                component="form"
                sx={{ display: "flex", alignItems: "center" }}
            >
                <TextField
                    fullWidth
                    name="description"
                    onChange={(event) => setOptionToAdd(event.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && onAddClick(e)}
                    value={optionToAdd}
                />

                <IconButton onClick={onAddClick}>
                    <Add />
                </IconButton>
            </Paper>

            <Button
                variant="contained"
                startIcon={<ChevronLeft />}
                onClick={onCloseClick}
                sx={{ mt: 2 }}
            >
                Back
            </Button>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Container>
    );

    function OptionListItem({ option }: { option: Option }) {
        const [editing, setEditing] = useState(false);
        let newOptionName = "";

        return (
            <ListItem
                secondaryAction={
                    <IconButton onClick={onDeleteClick}>
                        <Delete />
                    </IconButton>
                }
                key={option.id}
                divider
            >
                <ListItemText
                    primary={option.name}
                    onClick={() => setEditing(true)}
                    sx={editing ? { display: "none" } : null}
                />

                <TextField
                    fullWidth
                    name="description"
                    defaultValue={option.name}
                    onChange={(event) => (newOptionName = event.target.value)}
                    onBlur={() => updateOption(newOptionName)}
                    onKeyDown={(e) =>
                        e.key === "Enter" && updateOption(newOptionName)
                    }
                    inputRef={(input) => input && input.focus()}
                    sx={!editing ? { display: "none" } : null}
                />
            </ListItem>
        );

        function updateOption(newOptionName: string) {
            setEditing(false);
            if (newOptionName && newOptionName.trim() !== option.name) {
                apiPost({
                    path: `CouncilResearchCategory/Update?id=${
                        option.id
                    }&name=${newOptionName.trim()}`,
                    onSuccess: () => {
                        setSnackbar({
                            children: `[${option.name}] ${intl.formatMessage({
                                id: `${section}.Updated`,
                            })}`,
                            severity: "success",
                        });
                        getCouncilResearchCategories();
                    },
                    onError: (error) => handleFetchError(error),
                });
            } else {
                setSnackbar({
                    children: intl.formatMessage({
                        id: `${section}.NameUnchangedErrorMessage`,
                    }),
                    severity: "error",
                });
            }
        }

        function onDeleteClick(event: React.MouseEvent<HTMLElement>) {
            apiPost({
                path: `CouncilResearchCategory/Delete?id=${option.id}`,
                onSuccess: () => {
                    setSnackbar({
                        children: `[${option.name}] ${intl.formatMessage({
                            id: `${section}.Deleted`,
                        })}`,
                        severity: "success",
                    });
                    getCouncilResearchCategories();
                },
                onError: (error) => handleFetchError(error),
            });
            event.stopPropagation();
        }
    }

    function getCouncilResearchCategories() {
        apiGet({
            path: `CouncilResearchCategory/List`,
            onSuccess: (data) => setCouncilResearchCategories(data),
            onError: handleFetchError,
        });
    }

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function onAddClick(event: React.SyntheticEvent<HTMLElement>) {
        event.preventDefault();
        if (optionToAdd) {
            if (
                councilResearchCategories?.some((x) => x.name === optionToAdd)
            ) {
                setSnackbar({
                    children: `${optionToAdd} already exists`,
                    severity: "error",
                });
            } else {
                apiPost({
                    path: `CouncilResearchCategory/Add?name=${optionToAdd}`,
                    onSuccess: () => {
                        setSnackbar({
                            children: `[${optionToAdd}] ${intl.formatMessage({
                                id: `${section}.Added`,
                            })}`,
                            severity: "success",
                        });
                        setOptionToAdd("");
                        getCouncilResearchCategories();
                    },
                    onError: (error) => handleFetchError(error),
                });
            }
        } else {
            setSnackbar({
                children: intl.formatMessage({
                    id: `${section}.EmptyOptionToAddErrorMessage`,
                }),
                severity: "error",
            });
        }
    }

    function onCloseClick() {
        navigate(-1);
    }
}
