
import Container from "@mui/material/Container";
import { CouncilDocumentsFileManager } from "../components/FileManager/CouncilDocumentsFileManager";

export function SharedCouncilDocuments() {
    return (
        <Container maxWidth="md">
            <h2>Shared Council Documents</h2>

            <CouncilDocumentsFileManager viewSharedFilesOnly />
        </Container>
    );
}
