import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompletedDates, SetCompletedDates } from "types/CompletedDates";

interface CompletedDatesContextState {
    completedDates?: CompletedDates;
    setCompletedDates?: SetCompletedDates;
}

const contextDefaultValues: CompletedDatesContextState = {
    completedDates: undefined,
};

export const CompletedDatesContext =
    createContext<CompletedDatesContextState>(contextDefaultValues);

export function CompletedDatesProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();
    const params = useParams();

    const [completedDates, setCompletedDates] = useState<CompletedDates>();

    useEffect(() => {
        apiGet({
            path: `DateCompletedRecord/GetCompletedDates?id=${params.id}`,
            onSuccess: setCompletedDates,
        });
    }, []);

    return (
        <CompletedDatesContext.Provider
            value={{ completedDates, setCompletedDates }}
        >
            {children}
        </CompletedDatesContext.Provider>
    );
}
