import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import {
    FileManagerState,
    FileManagerWrapper,
} from "components/FileManager/FileManagerWrapper";
import { RoleContext } from "context/roleContext";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";

const section: string = "Task5.ManagementOutcomes";

export function ManagementStatement() {
    const theme = useTheme();
    const [state, setState] = useState<FileManagerState>({
        files: [],
        numberOfFiles: 0,
        uploading: false
    });

    const roleContext = useContext(RoleContext);
    const canAddOrDelete: boolean =
        roleContext.roles?.isSysAdmin ||
        roleContext.roles?.isCouncilUser ||
        false;

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    <FormattedMessage id={`${section}.ManagementStatement`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item md={12}>
                        <FileManagerWrapper
                            documentType="ManagementResponseSummary"
                            state={state}
                            setState={setState}
                            enableAddFile={canAddOrDelete}
                            disableDeleteFile={!canAddOrDelete}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
