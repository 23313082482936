/**
 * DO NOT ALTER: This component is extracted from the legacy @beca/common-react
 * Technical Debt item: #86766
 */

export interface FetchParams extends RequestInit {
    url?: string;
    body?: any;
    bearer?: string | null;
    headers?: Headers;
    disableStringify?: boolean;
    onSuccess?: (data: any) => void;
    onError?: (error: FetchError) => void;
}

export interface FetchError {
    message: string;
    status?: number;
    statusText?: string;
}

export default function useFetchHook() {
    function useFetch(fetchParams: FetchParams) {
        const { url, body, bearer, disableStringify, onSuccess, onError } =
            fetchParams;

        fetchParams.headers = fetchParams.headers || new Headers();
        if (!disableStringify) {
            fetchParams.headers.append("Content-Type", "application/json");
        }

        if (bearer)
            fetchParams.headers.append("Authorization", `Bearer ${bearer}`);

        if (
            body &&
            (disableStringify === undefined || disableStringify === false)
        ) {
            fetchParams.body = JSON.stringify(body);
        } else if (body) {
            fetchParams.body = body;
        }

        fetch(url || "", fetchParams)
            .then((response) => {       
                if (response.ok) {
                    if (onSuccess) {
                        const contentType =
                            response.headers.get("content-type");
                        if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                        ) {
                            response.json().then(onSuccess);
                        } else if (
                            contentType &&
                            contentType.indexOf("application/octet-stream") !==
                                -1
                        ) {
                            const header = response.headers.get('Content-Disposition');
                            if (header) {
                                const parts = header!.split(';');
                                const filename = parts[1].split('=')[1];
                                response.blob().then(blob => onSuccess({filename, blob}));
                            }
                            else {
                                response.blob().then(onSuccess);
                            }
                        } else response.text().then(onSuccess);
                    }
                } else {
                    if (onError) {
                        response.text().then((errorMessage) =>
                            onError({
                                ...response,
                                message: `An unexpected error occured (Error ${response.status} ${response.statusText}) ${errorMessage}`,
                                status: response.status
                            })
                        );
                    }
                }
            })
            .catch((error: Error) => {
                if (onError) {
                    const fetchError: FetchError = { ...error };
                    onError(fetchError);
                }
            });
    }

    return { useFetch };
}
