import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useContext, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { Iwi } from "types/Iwi";
import { CurrentUserContext } from "./currentUserContext";

interface IwiContextState extends BaseContextState {
    iwi: Iwi | null;
    fetch?: (id?: number | null) => void;
}

const contextDefaultValues: IwiContextState = {
    iwi: null,
    error: undefined,
    isLoading: true,
    fetch: undefined,
};

export const IwiContext = createContext<IwiContextState>(contextDefaultValues);

export function IwiProvider({ children }: { children: ReactNode }) {
    const currentUserContext = useContext(CurrentUserContext);
    const { apiGet } = useApiFetch();

    const [iwiState, setIwiState] = useState<IwiContextState>({
        ...contextDefaultValues,
        fetch: getIwi,
    });

    function getIwi(id?: number | null) {
        apiGet({
            path: `Iwi/GetIwi?id=${id}`,
            onSuccess: (data) =>
                setIwiState({
                    ...contextDefaultValues,
                    iwi: data,
                    fetch: getIwi,
                }),
            onError: (error) =>
                setIwiState({
                    ...contextDefaultValues,
                    error: error,
                    fetch: getIwi,
                }),
        });
    }

    return (
        <IwiContext.Provider value={iwiState}>{children}</IwiContext.Provider>
    );
}
