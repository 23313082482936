import PrintIcon from "@mui/icons-material/Print";
import { Alert, AlertProps, Button, Grid, Snackbar } from "@mui/material";
import { useApiFetch } from "hooks/useApiFetch";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const section: string = "Reports";

export function Reports() {
    const { apiGet } = useApiFetch();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const [loading, setLoading] = useState(false);

    return (
        <>
            <Grid container spacing={1} justifyContent="center">
                <Grid item md={12}>
                    <h2>Reports</h2>
                </Grid>
                <Grid item md={12}>
                    <Button
                        variant="outlined"
                        startIcon={<PrintIcon />}
                        onClick={onClick}
                        disabled={loading}
                    >
                        {loading ? (
                            <FormattedMessage
                                id={`${section}.GeneratingSummaryReport`}
                            />
                        ) : (
                            <FormattedMessage id={`${section}.SummaryReport`} />
                        )}
                    </Button>
                </Grid>
            </Grid>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function onClick() {
        setLoading(true);
        apiGet({
            path: `Report`,
            onSuccess: (data) => {
                onDownloadSuccess(data.blob);
                setLoading(false);
            },
            onError: (error) => {
                setSnackbar({
                    children: "Report download failed",
                    severity: "error",
                });
                setLoading(false);
            },
        });
    }

    function onDownloadSuccess(blob: Blob) {
        var blobAsFile = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = blobAsFile;
        a.download = `Summary Report ${new Date().toLocaleDateString("en-nz", {
            year: "numeric",
            month: "long",
            day: "numeric",
        })}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(blobAsFile);
        document.body.removeChild(a);
    }
}
