import { Alert, AlertProps, Snackbar } from "@mui/material";
import axios from "axios";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import DeleteModal from "components/FileManager/DeleteModal";
import {
    FileItem,
    FileManager,
    FolderItem,
    Item,
} from "components/FileManager/FileManager";
import { useApiFetch } from "hooks/useApiFetch";
import useDocumentUploader, { FileCreatedDto } from "hooks/useDocumentUploader";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ValidFileExtension } from "types/ValidFileExtension";

export interface CouncilDocumentsFileManagerProps {
    viewSharedFilesOnly?: boolean;
}



export function CouncilDocumentsFileManager(
    props: CouncilDocumentsFileManagerProps
) {
    const { apiGet, apiPost, apiDelete } = useApiFetch();
    const [files, setFiles] = useState<Item[]>();
    const [sharedFiles, setSharedFiles] = useState<string[]>();
    const [parentFolder, setParentFolder] = useState<string | undefined>(
        undefined
    );
    const [loadData, setLoadData] = useState(true);
    const inputFile = useRef<HTMLInputElement>(null);
    const [deleteFile, setDeleteFile] = useState<FileItem>();
    const [extensions, setExtensions] = useState<string>();
    const { uploadDocuments } = useDocumentUploader({
        config: {
            parentFolder: parentFolder
        },
        onError: (error) => handleError(error),
        onSuccess: (files) => onUploadSuccess(files)
    });

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            getCouncilDocuments();
            getSharedFiles();
            getValidFileExtensions();
        }
    }, [loadData]);

    return (
        <>
            {!loadData && files && (
                <FileManager
                    files={files}
                    firstNodeExpanded
                    deleteable={!props.viewSharedFilesOnly}
                    shareable={!props.viewSharedFilesOnly}
                    uploadable={!props.viewSharedFilesOnly}
                    downloadable
                    shared={sharedFiles}
                    onShareChange={onChangeShare}
                    onUpload={onUpload}
                    onDelete={onDelete}
                    onDownload={onDownload}
                    maxWidth="800px"
                />
            )}
            <input
                type="file"
                id="file"
                ref={inputFile}
                accept={extensions}
                style={{ display: "none" }}
                onChange={(event) => onFileChange(event)}
                multiple
            />
            <DeleteModal
                open={!!deleteFile}
                fileName={deleteFile?.fileName}
                fileId={deleteFile?.id}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
            />

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function onDelete(fileId: string) {
        if (!files) {
            return;
        }
        const file = findInFiles(fileId, files);
        if (!file) {
            return;
        }
        setDeleteFile(file);
    }

    function onDeleteCancel() {
        setDeleteFile(undefined);
    }

    function onDeleteConfirm() {
        function onSuccess(filename: string) {
            setSnackbar({
                children: `${filename} deleted`,
                severity: "success",
            });
            getCouncilDocuments();
        }

        if (!deleteFile) {
            setSnackbar({
                children: "Error deleting file, selected file was null",
                severity: "error",
            });
            return;
        }
        apiDelete({
            path: `GeneralDocuments/SoftDelete?id=${deleteFile?.id}`,
            onSuccess: () => onSuccess(deleteFile.fileName),
            onError: (error) => handleFetchError(error),
        });
        setDeleteFile(undefined);
    }

    function isFolder(item: FolderItem | FileItem): item is FolderItem {
        return !!(item as FolderItem).children;
    }

    function onDownload(fileId: string) {
        if (!files) {
            return;
        }
        const file = findInFiles(fileId, files);
        if (!file) {
            return;
        }
        // 
        const filename = file.fileName;
        apiGet({
            path: `GeneralDocuments/DownloadById?id=${fileId}`,
            onSuccess: (sasUrl) => axios.get(sasUrl, { responseType: "blob" })
                .then((data) => onDownloadSuccess(filename, data.data))
                .catch((error) => handleError(error.message)),
            onError: handleFetchError,

        });
    }

    function findInFiles(
        fileId: string,
        filesIn: Item[]
    ): FileItem | undefined {
        for (let f of filesIn) {
            if (isFolder(f)) {
                const found = findInFiles(fileId, f.children);
                if (found) {
                    return found;
                }
            } else {
                if (f.id == fileId) {
                    return f;
                }
            }
        }

        return undefined;
    }

    function onDownloadSuccess(filename: string, data: Blob) {
        const blobAsFile = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = blobAsFile;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(blobAsFile);
        document.body.removeChild(a);
    }

    function onUpload(folderId: string) {
        setParentFolder(folderId);
        inputFile.current?.click();
    }

    function getCouncilDocuments() {
        apiGet({
            path: props.viewSharedFilesOnly
                ? `GeneralDocuments/GetSharedDocuments`
                : `GeneralDocuments/List`,
            onSuccess: (data) => setFiles(data),
            onError: (error) => handleFetchError(error),
        });
    }

    function getSharedFiles() {
        apiGet({
            path: `GeneralDocuments/GetSharedDocumentIds`,
            onSuccess: (data) => setSharedFiles(data),
            onError: (error) => handleFetchError(error),
        });
    }


    function onFileChange(event: ChangeEvent<HTMLInputElement>) {

        if (!event.target.files) {
            return;
        }
        const files = Array.from(event.target.files);

        uploadDocuments(files);
    }

    function onUploadSuccess(files: FileCreatedDto[]) {
        setSnackbar({
            children: `${files.length > 1 ? `${files.length} Files` : files[0].fileName} uploaded`,
            severity: "success",
        });
        getCouncilDocuments();
    }

    function handleFetchError(error: FetchError) {
        handleError(error.message);
    }

    function handleError(error: string) {
        setSnackbar({ children: error, severity: "error" });
    }

    function onChangeShare(newShared: string[]) {
        const makeShared = newShared.filter((x) => !sharedFiles?.includes(x));
        const removeShared = sharedFiles?.filter(
            (x) => !newShared?.includes(x)
        );

        apiSetShared(makeShared, true);
        apiSetShared(removeShared ?? [], false);
    }

    function onChangeShareSuccess() {
        setSnackbar({
            children: `Shared files updated`,
            severity: "success",
        });
        getSharedFiles();
    }

    function apiSetShared(ids: string[], shared: boolean) {
        if (!ids) {
            return;
        }
        apiPost({
            path: `GeneralDocuments/SetShared?isShared=${shared}`,
            body: ids,
            onSuccess: (data) => onChangeShareSuccess(),
            onError: (error) => handleFetchError(error),
        });
    }

    function getValidFileExtensions() {
        apiGet({
            path: "ValidFileExtension/List",
            onSuccess: (data) => onValidFileExtensionsGetSuccess(data),
            onError: handleFetchError,
        });
    }

    function onValidFileExtensionsGetSuccess(data: ValidFileExtension[]) {
        const extensions = data.map((e) => `.${e.extension.trim()}`).join(", ");
        setExtensions(extensions);
    }
}
