import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Typography,
} from "@mui/material";

export type DeleteModalProps = DialogProps & {
    fileName?: string;
    fileId?: string;
    onConfirm: () => void;
    onCancel: () => void;
};

export default function DeleteModal({
    fileName,
    fileId,
    onConfirm,
    onCancel,
    ...dialogProps
}: DeleteModalProps) {
    return (
        <Dialog {...dialogProps} onClose={onCancel}>
            <DialogTitle>Confirm deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this file?
                </DialogContentText>
                <DialogContentText>{fileName}</DialogContentText>
                <Typography variant="overline" color="lightgray">
                    ID: {fileId}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="error" variant="outlined">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
