import { Stack } from "@mui/material";
import { CompletedDatesContext } from "context/completedDatesContext";
import { useContext } from "react";
import { ProgressCard } from ".";

export function LandownerEngagementProgress() {
    const completedDatesContext = useContext(CompletedDatesContext);

    return (
        <Stack
            justifyContent="space-evenly"
            alignItems="center"
            direction={{ xs: "column", sm: "row" }}
        >
            <ProgressCard
                title="GovernanceNotifiedIwiCouncil"
                date={completedDatesContext.completedDates?.governanceNotified}
                showText={true}
            />
            <ProgressCard
                title="PropertiesIdentified"
                date={
                    completedDatesContext.completedDates?.propertiesIdentified
                }
                showText={true}
            />
            <ProgressCard
                title="LandownerAccessSought"
                date={
                    completedDatesContext.completedDates?.landownerAccessSought
                }
                showText={true}
            />
            <ProgressCard
                title="LandownerApprovalReceived"
                date={
                    completedDatesContext.completedDates
                        ?.landownerApprovalReceived
                }
                showText={true}
            />
            <ProgressCard
                title="SiteVisitCompleted"
                date={completedDatesContext.completedDates?.siteVisitComplete}
                showText={true}
            />
            <ProgressCard
                title="FeedbacktoLandownerGovernance"
                date={
                    completedDatesContext.completedDates
                        ?.feedbackToLandownerGovernance
                }
                showText={true}
            />
        </Stack>
    );
}
