import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MchpLogo from "assets/MchpLogo.png";
import { AuthenticatedContext } from "context/authenticatedContext";
import { RefreshFromGisContext } from "context/refreshFromGisContext";
import { RoleContext } from "context/roleContext";
import { useAlertLayerUrl } from "hooks/useAlertLayerUrl";
import { useCouncilMapUrl } from "hooks/useCouncilMapUrl";
import { useIwiList } from "hooks/useIwiList";
import { useContext, useMemo } from "react";
import { Link as RouterLink, Outlet, useParams } from "react-router-dom";
import { ResponsiveMenu } from "./ResponsiveMenu";

const Icon = styled("img")(() => ({
    height: 30,
}));

export function Header() {
    const roleContext = useContext(RoleContext);
    const authenticatedContext = useContext(AuthenticatedContext);
    const iwiList = useIwiList();
    const alertLayerUrl = useAlertLayerUrl();
    const councilMapUrl = useCouncilMapUrl();
    const refreshFromGisContext = useContext(RefreshFromGisContext);
    const { iwiId } = useParams();
    const dataGridIwi = useMemo(() => iwiList?.find(x => x.id == Number(iwiId)), [iwiList, iwiId]);

    return (
        <Box height="100%">
            <AppBar
                position="static"
                sx={{ bgcolor: "white", paddingTop: 2, paddingBottom: 2 }}
            >
                <Toolbar variant="dense">
                    <Box>
                        <Button component={RouterLink} to="/">
                            <Icon src={MchpLogo} alt="Logo" />
                        </Button>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    {authenticatedContext.isAuthenticated && (
                        <ResponsiveMenu
                            roles={roleContext.roles}
                            iwiList={iwiList}
                            councilMapUrl={councilMapUrl}
                            alertLayerUrl={alertLayerUrl}
                            refreshFromGisContext={refreshFromGisContext}
                            dataGridIwi={dataGridIwi}
                        />
                    )}
                </Toolbar>
            </AppBar>

            {/* TODO use  useStyles & theme spacing to calculate the 116px (100 + 2x padding)*/}
            <Box height="calc(100% - 116px)">
                <Box padding={2} height="100%">
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}
