import { Close, Done } from "@mui/icons-material/";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Snackbar,
    SnackbarOrigin,
    TextField,
    Typography,
} from "@mui/material";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iwi } from "types/Iwi";

export function AddUser() {
    const paperStyle = { padding: "20px", width: "80vw", margin: "20px auto" };

    const navigate = useNavigate();

    const { apiGet, apiPost } = useApiFetch();
    const [error, setError] = useState<FetchError>();
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    const [allRoles, setAllRoles] = useState<string[]>();
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [allIwi, setAllIwi] = useState<Iwi[]>();
    const [userIwi, setUserIwi] = useState<Iwi | null>(null);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    useEffect(() => {
        getAllRoles({ onSuccess: setAllRoles, onError: setError });
        getAllIwi({ onSuccess: setAllIwi, onError: setError });
    }, []);

    const snackbarOrigin: SnackbarOrigin = {
        vertical: "bottom",
        horizontal: "center",
    };

    return (
        <Grid component={Paper} style={paperStyle}>
            <Box m={1}>
                <Typography variant="h5">Add User</Typography>
            </Box>

            <Box m={1}>
                <Divider />
            </Box>

            <Box m={1}>
                <Typography fontWeight="bold">Email Address</Typography>
                <TextField
                    id="email-address"
                    variant="standard"
                    onChange={(event) => setEmail(event.target.value)}
                    fullWidth
                />
            </Box>

            <Box m={1}>
                <Typography fontWeight="bold">Name</Typography>
                <TextField
                    id="user-name"
                    variant="standard"
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                />
            </Box>

            <Box m={1}>
                <Typography fontWeight="bold">Iwi</Typography>
                <Autocomplete
                    id="user-iwi"
                    options={allIwi ?? []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setUserIwi(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Add an Iwi"
                        />
                    )}
                />
            </Box>

            <Box m={1}>
                <Typography fontWeight="bold">Roles</Typography>
                <Autocomplete
                    multiple
                    id="user-roles"
                    options={allRoles ?? []}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => setUserRoles(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Add a role"
                        />
                    )}
                />
            </Box>

            <Box m={1} component="span">
                <Button
                    variant="contained"
                    startIcon={<Done />}
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </Box>

            <Box m={1} component="span">
                <Button
                    variant="contained"
                    startIcon={<Close />}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Box>

            <Snackbar
                open={submitSuccess}
                onClose={handleSuccessAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleSuccessAlertClose}
                    variant="filled"
                    severity="success"
                >
                    User added successfully.
                </Alert>
            </Snackbar>

            <Snackbar
                open={error !== undefined}
                onClose={handleErrorAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleErrorAlertClose}
                    variant="filled"
                    severity="error"
                >
                    {error?.message}
                </Alert>
            </Snackbar>
        </Grid>
    );

    function onSubmit() {
        apiPost({
            path: `Account/AddUser`,
            body: {
                name: name,
                email: email,
                roles: userRoles,
                iwiId: userIwi?.id,
            },
            onSuccess: () => setSubmitSuccess(true),
            onError: (error) => setError(error),
        });
    }

    function onCancel() {
        navigate("/users");
    }

    function getAllRoles({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `Account/GetAllRoles`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getAllIwi({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `IwiAdmin/GetAllIwi`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function handleSuccessAlertClose() {
        setSubmitSuccess(false);
    }

    function handleErrorAlertClose() {
        setError(undefined);
    }
}
