import SaveIcon from "@mui/icons-material/Save";
import { Alert, AlertProps, Fab, Grid, Snackbar } from "@mui/material";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { LandownerEngagementProgress } from "components/progressCard/LandownerEngagementProgress";
import { useApiFetch } from "hooks/useApiFetch";
import {
    ConfirmLandowners,
    defaultConfirmLandownersState,
} from "pages/forms/ConfirmLandowners";
import { GovernanceCommunication } from "pages/forms/GovernanceCommunication";
import { LandownerEngagementBlurb } from "pages/forms/LandownerEngagementBlurb";
import { SiteVisitRecordForm } from "pages/forms/SiteVisitRecord";
import { UndeleteFilesCard } from "pages/UndeleteFiles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompletedDates } from "types/CompletedDates";
import { EditDateCompleted } from "types/EditDateCompleted";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { DefaultSiteVisitRecord, SiteVisitRecord } from "types/SiteVisitRecord";
import { DefaultSiteInformationSummary, SiteInformationSummary } from "types/SiteInformationSummary";
import { OnTheGroundReview } from "../../pages/forms/OnTheGroundReview";
import { DefaultTechnicalSiteAssessment, TechnicalSiteAssessment, TechnicalSiteAssessmentEntry } from "types/TechnicalSiteAssessment";

interface LandownerEngagementProps {
    nominatedSiteState: NominatedSiteState;
    setNominatedSiteState: SetNominatedSiteState;
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

export function LandownerEngagement(props: LandownerEngagementProps) {
    const [confirmLandownersState, setConfirmLandownersState] = useState(
        defaultConfirmLandownersState
    );
    const [siteVisitRecordState, setSiteVisitRecordState] =
        useState<SiteVisitRecord>(DefaultSiteVisitRecord);
    const [siteInformationSummaryState, setSiteInformationSummaryState] =
        useState<SiteInformationSummary>(DefaultSiteInformationSummary);
    const [techSiteState, setTechSiteState] = useState<TechnicalSiteAssessment>(
        DefaultTechnicalSiteAssessment
    );
    const [loadData, setLoadData] = useState(true);
    const { apiGet, apiPost } = useApiFetch();
    const { id } = useParams();
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            getConfirmLandowners({
                onSuccess: (data) => {
                    if (!data.numberOfFiles) {
                        data.numberOfFiles = 0;
                    }
                    setConfirmLandownersState(data);
                },
                onError: handleFetchError,
            });
            getSiteVisitRecord({
                onSuccess: (data: SiteVisitRecord) => {
                    setSiteVisitRecordState({
                        ...DefaultSiteVisitRecord,
                        ...data,
                    });
                },
                onError: handleFetchError,
            });
            getSiteInformationSummary({
                onSuccess: (data: SiteVisitRecord) => {
                    setSiteInformationSummaryState({
                        ...DefaultSiteInformationSummary,
                        ...data,
                    });
                },
                onError: handleFetchError,
            });
            getTechnicalAssessmentData({
                onSuccess: onGetSuccess,
                onError: handleFetchError,
            });
        }
    }, [loadData]);
    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item md={12}>
                    <LandownerEngagementProgress />
                </Grid>
                <Grid item md={6}>
                    <LandownerEngagementBlurb />
                    <GovernanceCommunication
                        completedDates={props.completedDates}
                        progressOnClick={props.progressOnClick}
                    />
                    <ConfirmLandowners
                        state={confirmLandownersState}
                        setState={(value) => {
                            setConfirmLandownersState(value);
                        }}
                        completedDates={props.completedDates}
                        progressOnClick={props.progressOnClick}
                    />
                    <Grid item md={12}>
                        <UndeleteFilesCard />
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <OnTheGroundReview />
                    <SiteVisitRecordForm
                        dateCompleted={props.completedDates?.siteVisitComplete}
                        progressOnClick={props.progressOnClick}
                        state={siteVisitRecordState}
                        siteInformationSummaryState={siteInformationSummaryState}
                        techSiteState={techSiteState}
                        setState={setSiteVisitRecordState}
                        nominatedSiteState={props.nominatedSiteState}
                    />
                </Grid>

                <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="save"
                    sx={{ position: "fixed", bottom: "16px" }}
                    onClick={saveForm}
                >
                    <SaveIcon sx={{ mr: 1 }} /> Save Task 3
                </Fab>
            </Grid>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function getConfirmLandowners({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetNominatedSite?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getSiteVisitRecord({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetSiteVisitRecord?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getSiteInformationSummary({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetSiteInformationSummary?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getTechnicalAssessmentData({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetTechnicalSiteAssessment?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function onGetSuccess(data: any) {
        if (data) {
            setTechSiteState(parseApiData(data));
        }
    }

    function parseApiData(data: TechnicalSiteAssessment) {
        data.historical = parseAssessmentEntry(data.historical);
        data.mauri = parseAssessmentEntry(data.mauri);
        data.wahiTapu = parseAssessmentEntry(data.wahiTapu);
        data.customaryResources = parseAssessmentEntry(data.customaryResources);
        data.customaryNeeds = parseAssessmentEntry(data.customaryNeeds);
        data.contemporaryEsteem = parseAssessmentEntry(data.contemporaryEsteem);
        data.horopaki = parseAssessmentEntry(data.horopaki);
        return data;
    }

    function parseAssessmentEntry(data: TechnicalSiteAssessmentEntry) {
        if (data.lastUpdate) {
            data.lastUpdate = new Date(data.lastUpdate);
        }

        if (data.commentsLastUpdate) {
            data.commentsLastUpdate = new Date(data.commentsLastUpdate);
        }
        return data;
    }

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function onSaveSuccess(message: string) {
        setSnackbar({
            children: message,
            severity: "success",
        });
        setLoadData(true);
    }

    function saveForm() {
        saveLandownerEngagement();
        saveSiteVisitRecord();
    }

    function saveLandownerEngagement() {
        apiPost({
            path: `NominatedSite/UpdateLandownerEngagement?nominatedSiteId=${id}`,
            body: {
                landownerFeedback: confirmLandownersState.landownerFeedback,
                existingRelationship:
                    confirmLandownersState.existingRelationship,
                propertyAccess: confirmLandownersState.propertyAccess,
            },
            onSuccess: (data) =>
                onSaveSuccess("Landowner Engagement form successfully saved"),
            onError: (error) => handleFetchError(error),
        });
    }

    function saveSiteVisitRecord() {
        apiPost({
            path: `NominatedSite/UpdateSiteVisitRecord?nominatedSiteId=${id}`,
            body: siteVisitRecordState,
            onSuccess: (data) =>
                onSaveSuccess("Site Visit Record form successfully saved"),
            onError: (error) => handleFetchError(error),
        });
    }


}
