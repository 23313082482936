import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ProgressInput from "components/ProgressInput";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";
import {
    TechnicalSiteAssessment,
    TechnicalSiteAssessmentEntry,
} from "types/TechnicalSiteAssessment";

interface PartialProps {
    sectionName: string;
    state: TechnicalSiteAssessmentEntry;
    onChange: (newValue: TechnicalSiteAssessmentEntry) => void;
    placeholder?: string
}

interface TechnicalSiteAssessmentProps {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
    state: TechnicalSiteAssessment;
    setState: (value: TechnicalSiteAssessment) => void;
}

const section: string = "Task2.TechnicalSiteAssessment";

export function TechnicalSiteAssessmentForm({
    dateCompleted,
    progressOnClick,
    state,
    setState,
}: TechnicalSiteAssessmentProps) {
    const intl: IntlShape = useIntl();
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                id="technical-site-assessment-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5" align="left">
                    <FormattedMessage id={`${section}.SectionTitle`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.ReferToGuide`} />
                        </Typography>
                        <Typography fontStyle="italic" variant="body2">
                            <FormattedMessage id={`${section}.Subtitle`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Stack spacing={1}>
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.KōreroTūturu`,
                                })}
                                state={state.historical}
                                onChange={(x) => onChange("historical", x)}
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.WāhiTapu`,
                                })}
                                state={state.wahiTapu}
                                onChange={(x) => onChange("wahiTapu", x)}
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.Mauri`,
                                })}
                                state={state.mauri}
                                onChange={(x) => onChange("mauri", x)}
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.CustomaryResources`,
                                })}
                                state={state.customaryResources}
                                onChange={(x) =>
                                    onChange("customaryResources", x)
                                }
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.CustomaryNeeds`,
                                })}
                                state={state.customaryNeeds}
                                onChange={(x) => onChange("customaryNeeds", x)}
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.ContemporaryEsteem`,
                                })}
                                state={state.contemporaryEsteem}
                                onChange={(x) =>
                                    onChange("contemporaryEsteem", x)
                                }
                            />
                            <TechnicalSiteAssessmentPartial
                                sectionName={intl.formatMessage({
                                    id: `${section}.SurroundingLandscape`,
                                })}
                                state={state.horopaki}
                                onChange={(x) => onChange("horopaki", x)}
                                placeholder={intl.formatMessage({
                                    id: `${section}.DescriptionStatementOfValues.Horopaki.Placeholder`
                                })}
                            />

                            <ProgressInput
                                title="technicalSiteAssessmentComplete"
                                field={
                                    DateCompletedField.TechnicalSiteAssessmentComplete
                                }
                                dateCompleted={dateCompleted}
                                progressOnClick={progressOnClick}
                                editable
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    function onChange(
        property: keyof TechnicalSiteAssessment,
        value: TechnicalSiteAssessmentEntry
    ) {
        setState({ ...state, [property]: value });
    }
}

function TechnicalSiteAssessmentPartial({
    sectionName,
    state,
    onChange,
    placeholder
}: PartialProps) {
    const intl: IntlShape = useIntl();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    {sectionName}
                </Typography>
            </AccordionSummary>
            <AccordionDetails id={`${sectionName}-contents`}>
                <Grid container justifyContent="center">
                    <Grid item md={12} p={2}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.DescriptionStatementOfValues`,
                            })}
                            value={state.description}
                            onChange={(event) =>
                                onChangeEntry("description", event.target.value)
                            }
                            placeholder={placeholder}
                            InputLabelProps={{ shrink: true }}
                            multiline
                        />
                    </Grid>
                    <Grid item md={12} p={2}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.SourceInformationReference`,
                            })}
                            value={state.source}
                            onChange={(event) =>
                                onChangeEntry("source", event.target.value)
                            }
                            placeholder={intl.formatMessage({
                                id: `${section}.SourceInformationReference.Placeholder`,
                            })}
                            InputLabelProps={{ shrink: true }}
                            multiline
                        />
                    </Grid>
                    <Grid item md={8} p={2}>
                        <FormControl>
                            <FormLabel>Sensitive Information Held?</FormLabel>
                            <RadioGroup
                                value={state.hasSensitiveInformation ?? false}
                                onChange={(event) =>
                                    onChangeEntry(
                                        "hasSensitiveInformation",
                                        event.target.value == "true"
                                    )
                                }
                                row
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label={intl.formatMessage({
                                        id: `${section}.Yes`,
                                    })}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label={intl.formatMessage({
                                        id: `${section}.No`,
                                    })}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} p={2}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Last Updated:
                        </Typography>
                        {state.lastUpdate && (
                            <Typography variant="body1">
                                {intl.formatTime(state.lastUpdate)}{" "}
                                {intl.formatDate(state.lastUpdate)}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item md={8} p={2}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.CommentsAndFeedback`,
                            })}
                            value={state.comments ?? ""}
                            onChange={(event) =>
                                onChangeEntry("comments", event.target.value)
                            }
                            placeholder={intl.formatMessage({
                                id: `${section}.CommentsAndFeedback.Placeholder`
                            })}
                            InputLabelProps={{ shrink: true }}
                            multiline
                        />
                    </Grid>
                    <Grid item md={4} p={2}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Last Updated:
                        </Typography>
                        {state.commentsLastUpdate && (
                            <Typography variant="body1">
                                {intl.formatTime(state.commentsLastUpdate)}{" "}
                                {intl.formatDate(state.commentsLastUpdate)}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    function onChangeEntry(
        property: keyof TechnicalSiteAssessmentEntry,
        value: string | boolean | undefined
    ) {
        onChange({ ...state, [property]: value });
    }
}
