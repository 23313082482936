import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { MapUrl } from "types/MapUrl";

interface UrlContextState extends BaseContextState {
    councilMapDetails?: MapUrl;
    fetch?: () => void;
}

const contextDefaultValues: UrlContextState = {
    councilMapDetails: undefined,
    error: undefined,
    isLoading: true,
    fetch: undefined,
};

export const CouncilMapUrlContext =
    createContext<UrlContextState>(contextDefaultValues);

export function CouncilMapUrlProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();

    const [councilMapState, setCouncilMapState] = useState<UrlContextState>({
        ...contextDefaultValues,
        fetch: getCouncilMapUrl,
    });

    function getCouncilMapUrl() {
        apiGet({
            path: `MapForCouncil/GetCouncilMapUrl`,
            onSuccess: (data) =>
                setCouncilMapState({
                    ...contextDefaultValues,
                    councilMapDetails: data,
                    isLoading: false,
                    fetch: getCouncilMapUrl,
                }),
            onError: (error) =>
                setCouncilMapState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                    fetch: getCouncilMapUrl,
                }),
        });
    }

    return (
        <CouncilMapUrlContext.Provider value={councilMapState}>
            {children}
        </CouncilMapUrlContext.Provider>
    );
}
