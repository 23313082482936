import {
    Alert,
    AlertProps,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useApiFetch } from "../hooks/useApiFetch";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import { ChangeEvent, useEffect, useState } from "react";
import { ValidFileExtension } from "types/ValidFileExtension";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

const section: string = "ValidFileExtensions";

export function ValidFileExtensions() {
    const { apiGet, apiPost } = useApiFetch();
    const [fileExtensions, setFileExtensions] = useState<ValidFileExtension[]>([]);
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const intl: IntlShape = useIntl();
    const navigate = useNavigate();
    const [newFieldVal, setNewFieldVal] = useState<string>("");


    useEffect(() => {
        getValidFileExtensions()
    }, [])

    return (
        <Container maxWidth="lg">
            <Box>
                <Typography variant="h4" gutterBottom>
                    <FormattedMessage id={`${section}.SectionTitle`} />
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    <FormattedMessage id={`${section}.Description`} />
                </Typography>
            </Box>
            <List sx={{ py: 4, width: '100%', maxWidth: 850 }}>
                <ListItem sx={{ textDecoration: "underline" }} divider>
                    <Grid container>
                        <Grid item xs={4}>
                            <ListItemText primary="#" />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" gutterBottom>
                                <FormattedMessage id={`${section}.ColumnTitle`} />
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItem>
                {fileExtensions.map((extensions, index) => (
                    <ListItem divider>
                        <Grid container>
                            <Grid item xs={4}>
                                <ListItemText primary={index + 1} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ display: "flex" }}>
                                    <TextField value={extensions.extension} onBlur={(e) => updateValidFileExtension(extensions)} onChange={(event) =>
                                        handleInputChange(index, event)
                                    } size="small" variant="outlined" sx={{ pr: 2, width: "100%" }} />
                                    <IconButton onClick={(e) => updateValidFileExtension(extensions)} aria-label="delete" color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => removeExtension(index, extensions.id)} aria-label="delete" color="primary">
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
                <ListItem sx={{ textDecoration: "underline" }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <ListItemText primary="" />
                        </Grid>
                        <Grid item xs={8}>
                            <Box sx={{ display: "flex" }}>
                                <TextField value={newFieldVal} onChange={(event) => handleNewInput(event)} size="small" variant="outlined" sx={{ pr: 2, width: "82.5%" }} />
                                <IconButton onClick={addNewExtension} aria-label="add" color="primary">
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ListItem>
                <Button variant="outlined" onClick={() => onCancel()}>Cancel</Button>
            </List>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Container>
    );

    function getValidFileExtensions() {
        apiGet({
            path: "ValidFileExtension/List",
            onSuccess: (data: ValidFileExtension[]) => setFileExtensions(data),
            onError: handleFetchError,
        });
    }

    function addValidFileExtension() {
        if (newFieldVal.length < 3 || newFieldVal.length > 16) {
            handleInputValidationError(`${intl.formatMessage({ id: `${section}.CharLengthValidationErrorMessage` })}`)
        } else if (fileExtensions.find(e => e.extension === newFieldVal)) {
            handleInputValidationError(`${intl.formatMessage({ id: `${section}.DuplicateInputErrorMessage` })}`)
        }
        else {
            apiPost({
                path: `ValidFileExtension/Add`,
                body: newFieldVal,
                onSuccess: () => {
                    onSaveSuccess("File extension is saved")
                    setNewFieldVal("")
                    getValidFileExtensions()
                },
                onError: (error) => handleFetchError(error),
            })
        }
    }

    function updateValidFileExtension(updateData: ValidFileExtension) {
        if (updateData.extension.length < 3 || updateData.extension.length > 16) {
            handleInputValidationError(`${intl.formatMessage({ id: `${section}.CharLengthValidationErrorMessage` })}`)
        } else {
            apiPost({
                path: `ValidFileExtension/Update?id=${updateData.id}`,
                body: updateData.extension,
                onSuccess: () => {
                    onSaveSuccess("File extension is updated")
                },
                onError: (error) => handleFetchError(error),
            })
        }
    }

    function deleteValidFileExtension(id: number) {
        fileExtensions.filter((extensions) => extensions.id !== id);
        setFileExtensions(fileExtensions);
        apiPost({
            path: `ValidFileExtension/Delete?id=${id}`,
            onSuccess: () => {
                onSaveSuccess("File extension is deleted")
                getValidFileExtensions()
            },
            onError: (error) => handleFetchError(error),
        });
    }

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function handleInputChange(index: number,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        fileExtensions[index].extension = event.target.value;
        setFileExtensions([...fileExtensions]);
    }

    function addNewExtension() {
        if (newFieldVal.length < 3 || newFieldVal.length > 16) {
            handleInputValidationError(`${intl.formatMessage({ id: `${section}.CharLengthValidationErrorMessage` })}`)
        } else {
            addValidFileExtension();
        }
    }

    function removeExtension(index: number, id: number | undefined) {
        if (id !== undefined) {
            deleteValidFileExtension(id)
        }
    }

    function onSaveSuccess(message: string) {
        setSnackbar({
            children: message,
            severity: "success",
        });
    }

    function onCancel() {
        navigate(-1);
    }

    function handleNewInput(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setNewFieldVal(event.target.value);
    }

    function handleInputValidationError(message: string) {
        setSnackbar({
            children: message,
            severity: "error",
        });
    }
}
