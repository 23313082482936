import userEvent from "@testing-library/user-event";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { User, UserManager, WebStorageStateStore } from "oidc-client";
import { DefaultRoles, Roles } from "types/Roles";
import { ApplicationName, ApplicationPaths } from "./ApiAuthorizationConstants";

export interface SignInProps {}

export interface SignOutProps {}

interface DecodedToken extends JwtPayload {
    amr: string[];
    auth_time: number;
    client_id: string;
    idp: string;
    role: string[];
    scope: string[];
    sid: string;
}

export interface SignInOutReturn {
    status: string;
    state?: any;
    message?: string;
}

interface Callback {
    callback: () => void;
    subscription: number;
}

export class AuthorizeService {
    _callbacks: Callback[] = [];
    _nextSubscriptionId = 0;
    _user: User | undefined = undefined;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;
    userManager: UserManager | undefined;

    async isAuthenticated() {
        if (this._user?.profile) {
            return true;
        }

        await this.ensureUserManagerInitialized();

        if (!this.userManager) {
            throw "user manager not found";
        }

        const user = await this.userManager.getUser();

        return user != null;
    }

    async isTokenExpired() {
        if (this._user?.profile) {
            return true;
        }

        await this.ensureUserManagerInitialized();

        if (!this.userManager) {
            throw "user manager not found";
        }

        const user = await this.userManager.getUser();

        if (user == null) {
            return false;
        }
        return user.expired;
    }

    async getUser() {
        if (this._user?.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();

        if (!this.userManager) {
            throw "user manager not found";
        }

        const user = await this.userManager.getUser();
        if (!user) {
            throw "user not returned";
        }

        return user.profile;
    }

    async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user && user.access_token;
    }

    async getUserRoles() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();

        if (user == null) return DefaultRoles;

        const decodedToken = jwtDecode<DecodedToken>(user.access_token);
        const rolesArray = decodedToken.role;

        if (rolesArray === undefined || rolesArray.length == 0)
            return DefaultRoles;

        const roles = {
            isCouncilUser: rolesArray.includes("CouncilUser"),
            isSysAdmin: rolesArray.includes("SysAdmin"),
            isIwiUser: rolesArray.includes("IwiUser"),
        };

        return roles as Roles;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: SignInProps) {
        await this.ensureUserManagerInitialized();
        try {
            const silentUser = await this.userManager?.signinSilent(
                this.createArguments()
            );
            this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                    );
                }

                const popUpUser = await this.userManager?.signinPopup(
                    this.createArguments()
                );
                this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError: any) {
                if (popUpError.message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.userManager?.signinRedirect(
                        this.createArguments(state)
                    );
                    return this.redirect();
                } catch (redirectError: any) {
                    console.log(
                        "Redirect authentication error: ",
                        redirectError
                    );
                    return this.error(redirectError);
                }
            }
        }
    }

    async completeSignIn(url: string) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager?.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state);
        } catch (error) {
            console.log("There was an error signing in: ", error);
            return this.error("There was an error signing in.");
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: SignOutProps) {
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                );
            }

            await this.userManager?.signoutPopup(this.createArguments());
            this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await this.userManager?.signoutRedirect(
                    this.createArguments(state)
                );
                return this.redirect();
            } catch (redirectSignOutError: any) {
                console.log("Redirect signout error: ", redirectSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url: string) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager?.signoutCallback(url);
            this.updateState(undefined);
            return this.success(response && response.state);
        } catch (error: any) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    updateState(user: User | undefined) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void) {
        this._callbacks.push({
            callback,
            subscription: this._nextSubscriptionId++,
        });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .filter((element) => element.subscription === subscriptionId)
            .map((element, index) => index);

        if (subscriptionIndex.length !== 1) {
            throw new Error(
                `Found an invalid number of subscriptions ${subscriptionIndex.length}`
            );
        }

        this._callbacks.splice(subscriptionIndex[0], 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state?: SignInProps | SignOutProps | undefined) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message: string) {
        return {
            status: AuthenticationResultStatus.Fail,
            message,
        } as SignInOutReturn;
    }

    success(state: SignInProps | SignOutProps | any) {
        return {
            status: AuthenticationResultStatus.Success,
            state,
        } as SignInOutReturn;
    }

    redirect() {
        return {
            status: AuthenticationResultStatus.Redirect,
        } as SignInOutReturn;
    }

    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }
        const host =
            window.location.hostname == "localhost"
                ? "https://localhost:7059"
                : window.location.origin;
        let response = await fetch(
            `${host}/${ApplicationPaths.ApiAuthorizationClientConfigurationUrl}`
        );
        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }

        let settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName,
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager?.removeUser();
            this.updateState(undefined);
        });
    }

    static get instance() {
        return authService;
    }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: "redirect",
    Success: "success",
    Fail: "fail",
};
