import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";

export interface AutoCompleteDataGridProps {
    cellParams: GridRenderEditCellParams
    options: any[]
    disabled: boolean
}

export function AutoCompleteDataGrid(props: AutoCompleteDataGridProps) {
    const { id, value, field } = props.cellParams;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: any, newValue: any[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <Autocomplete
        id="user-iwi"
        multiple
        options={props.options}
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={(event, value) => handleValueChange(event, value)}
        fullWidth
        disabled={props.disabled}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                placeholder=""
                fullWidth
            />
        )}
    />
}