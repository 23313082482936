import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DropDown } from "components/DropDown";
import { ChangeEvent } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { SiteAnalysisForm } from "types/SiteAnalysisForm";
import { SiteAnalysisOptions } from "../../types/Option";

const section: string = "SiteAnalysis";

type SiteAnalysisDisplayProps = {
    state: NominatedSiteState;
    setState: SetNominatedSiteState;
};

export function SiteAnalysis({ state, setState }: SiteAnalysisDisplayProps) {
    const intl: IntlShape = useIntl();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="site-analysis-content"
                id="site-analysis-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5">
                    <FormattedMessage id={`${section}.heading`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <DropDown
                        modelKey={"assetTypeId"}
                        translatePrefix={section}
                        onChange={onChange}
                        options={SiteAnalysisOptions.assetTypes}
                        values={state.assetTypeId}
                        titleKey={"assetType"}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.assetName`,
                        })}
                        disabled
                        value={state.assetName}
                    ></TextField>
                    <DropDown
                        modelKey={"keyAssetFeatures"}
                        translatePrefix={section}
                        multiple={true}
                        options={SiteAnalysisOptions.keyAssetFeatures}
                        values={state.keyAssetFeatures}
                        onChange={onChange}
                    />
                    {state.keyAssetFeatures.includes(SiteAnalysisOptions.keyAssetFeatureOther.id) && (
                        <TextField
                            fullWidth
                            label={intl.formatMessage({
                                id: `Key Asset Features list`,
                            })}
                            variant="outlined"
                            multiline
                            maxRows={4}
                            inputProps={{ maxLength: 1500 }}
                            value={state.keyAssetFeaturesOther}
                            onChange={(e) => onChange(e, "keyAssetFeaturesOther")}

                        />)}
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.siteDescription`,
                        })}
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                        value={state.keyAssetFeaturesText}
                        onChange={(e) => onChange(e, "keyAssetFeaturesText")}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.addressLocation`,
                        })}
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                        value={state.addressLocation}
                        onChange={(e) => onChange(e, "addressLocation")}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.legalDescription`,
                        })}
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                        value={state.legalDescription}
                        onChange={(e) => onChange(e, "legalDescription")}
                        placeholder={intl.formatMessage({
                            id: `${section}.legalDescription.Placeholder`,
                        })}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.nztmCentroidN`,
                        })}
                        variant="outlined"
                        type="number"
                        value={state.nztmCentroidN}
                        onChange={(e) => onChange(e, "nztmCentroidN")}
                        disabled
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.nztmCentroidE`,
                        })}
                        variant="outlined"
                        type="number"
                        value={state.nztmCentroidE}
                        onChange={(e) => onChange(e, "nztmCentroidE")}
                        disabled
                    />
                    <DropDown
                        modelKey={"landStatusId"}
                        translatePrefix={section}
                        options={SiteAnalysisOptions.landStatuses}
                        values={state.landStatusId}
                        onChange={onChange}
                        titleKey={"landStatus"}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.landowner`,
                        })}
                        variant="outlined"
                        inputProps={{ maxLength: 1500 }}
                        value={state.landowner}
                        onChange={(e) => onChange(e, "landowner")}
                    />
                    <DropDown
                        modelKey={"manaWhenuaRohes"}
                        translatePrefix={section}
                        options={SiteAnalysisOptions.manaWhenuaRohes}
                        values={state.manaWhenuaRohes}
                        onChange={onChange}
                        multiple={true}
                    />
                    <DropDown
                        modelKey={"localBoards"}
                        translatePrefix={section}
                        options={SiteAnalysisOptions.localBoards}
                        values={state.localBoards}
                        onChange={onChange}
                        multiple={true}
                    />
                    <DropDown
                        modelKey={"zonings"}
                        translatePrefix={section}
                        options={SiteAnalysisOptions.zonings}
                        values={state.zonings}
                        onChange={onChange}
                        multiple={true}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );

    function onChange(
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string[] | string | Event>,
        property: keyof SiteAnalysisForm
    ) {
        setState({ ...state, [property]: event.target.value });
    }
}
