import { AccountCircle } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { RefreshFromGisContextState } from "context/refreshFromGisContext";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { Iwi } from "types/Iwi";
import { Roles } from "types/Roles";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { NavButton } from "./NavButton";

const section: string = "Header";

export interface LogoutPath {
    pathname: string;
    state: {
        local: boolean;
    };
}

export interface NavMenuProps {
    roles?: Roles;
    iwiList: Iwi[];
    councilMapUrl?: string;
    alertLayerUrl?: string;
    refreshFromGisContext?: RefreshFromGisContextState;
    dataGridIwi?: Iwi;
}

export function NavMenu(props: NavMenuProps) {
    const { pathname } = useLocation();
    const logoutPath = {
        pathname: ApplicationPaths.LogOut,
        state: { local: true },
    } as LogoutPath;

    if (!props.roles) return null;

    const isSysAdmin = props.roles.isSysAdmin;
    const isCouncilUser = props.roles.isCouncilUser;
    const isIwiUser = props.roles.isIwiUser;

    return (
        <>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ sm: 1, md: 2 }}
                sx={{
                    flexWrap: "wrap",
                }}
            >
                <Stack
                    direction={{ sm: "column", md: "row" }}
                    spacing={{ sm: 1, md: 2 }}
                >
                    {(isCouncilUser || isSysAdmin) && (
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={refreshFromGis}
                            disabled={props.refreshFromGisContext?.isLoading}
                        >
                            <FormattedMessage
                                id={
                                    props.refreshFromGisContext?.isLoading
                                        ? `${section}.Refreshing`
                                        : `${section}.RefreshGis`
                                }
                            />
                        </Button>
                    )}
                    {(isCouncilUser || isSysAdmin || isIwiUser) && (
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                openMap(props.alertLayerUrl);
                            }}
                        >
                            <FormattedMessage id={`${section}.AlertLayer`} />
                        </Button>
                    )}
                    {(isCouncilUser || isSysAdmin) && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                openMap(props.councilMapUrl);
                            }}
                        >
                            <FormattedMessage id={"CouncilMap"} />
                        </Button>
                    )}
                </Stack>
                <Stack
                    direction={{ sm: "column", md: "row" }}
                    spacing={{ sm: 1, md: 2 }}
                >
                    <NavButton
                        menuItems={[
                            { title: "SharedCouncilDocuments", visible: true },
                            {
                                title: "CouncilDocuments",
                                visible:
                                    isCouncilUser || isSysAdmin,
                            },
                            {
                                title: "Reports",
                                visible:
                                    isCouncilUser || isSysAdmin,
                            },
                        ]}
                        renderButtonLabel={() => (
                            <FormattedMessage id={`${section}.General`} />
                        )}
                    />
                    {!isIwiUser && (
                        <NavButton
                            menuItems={[
                                {
                                    title: "Users",
                                    visible: isSysAdmin,
                                },
                                {
                                    title: "ValidFileExtensions",
                                    visible: isSysAdmin,
                                },
                                {
                                    title: "LookupManagement",
                                    visible:
                                        isCouncilUser ||
                                        isSysAdmin,
                                },
                            ]}
                            renderButtonLabel={() => (
                                <FormattedMessage id={`${section}.Admin`} />
                            )}
                        />
                    )}

                    {props.iwiList.length && (
                        <NavButton
                            menuItems={[
                                ...(isIwiUser
                                    ? []
                                    : [
                                          {
                                              visible: true,
                                              title: "Council",
                                              link: "/",
                                          },
                                      ]),
                                ...props.iwiList.map((iwi) => {
                                    return {
                                        visible: true,
                                        title: iwi.name,
                                        link: `/SiteAssessment/${iwi.id}`,
                                        doNotTranslate: true,
                                    };
                                }),
                            ]}
                            renderButtonLabel={() =>
                                pathname === "/" ||
                                props.dataGridIwi?.name === undefined ? (
                                    <FormattedMessage
                                        id={
                                            isIwiUser
                                                ? props.iwiList[0]?.name
                                                : "Header.Council"
                                        }
                                    ></FormattedMessage>
                                ) : (
                                    <>{props.dataGridIwi?.name}</>
                                )
                            }
                            noDropdown={props.iwiList.length === 1}
                        />
                    )}
                    <NavButton
                        menuItems={[
                            { title: "MyDetails", visible: true },
                            { title: "ChangePassword", visible: true },
                            {
                                title: "LogOff",
                                visible: true,
                                link: logoutPath,
                                isLinkLocal: logoutPath.state.local,
                            },
                        ]}
                        renderButtonLabel={() => <AccountCircle />}
                    />
                </Stack>
            </Stack>
        </>
    );

    function openMap(url?: string) {
        if (!url) return;

        window.open(url, "_blank");
    }

    function refreshFromGis() {
        props.refreshFromGisContext?.fetch &&
            props.refreshFromGisContext.fetch();
    }
}
