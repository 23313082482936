import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from "@mui/material";
import {
    FileManagerState,
    FileManagerWrapper,
} from "components/FileManager/FileManagerWrapper";
import ProgressInput from "components/ProgressInput";
import { RoleContext } from "context/roleContext";
import { useContext, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";

interface ResponseIdentificationProps {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task5.ResponseIdentification";

export function ResponseIdentification(props: ResponseIdentificationProps) {
    const intl: IntlShape = useIntl();
    const [fileState, setFileState] = useState<FileManagerState>({
        files: [],
        numberOfFiles: 0,
        uploading: false
    });

    const roleContext = useContext(RoleContext);
    const isCouncilOrAdmin: boolean = roleContext.isCouncilOrAdmin || false;

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="response-identification-content"
                id="response-identification-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5">
                    <FormattedMessage id={`${section}.SectionTitle`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.ReferToGuide`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage id={`${section}.PurposeTitle`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.PurposeDescription`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage
                                id={`${section}.OverviewOfProcessTitle`}
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`1. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription1`,
                                })}`}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`2. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription2`,
                                })}`}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`3. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription3`,
                                })}`}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`4. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription4`,
                                })}`}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`5. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription5`,
                                })}`}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1" marginLeft={8}>
                                {`6. ${intl.formatMessage({
                                    id: `${section}.OverviewOfProcessDescription6`,
                                })}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage
                                id={`${section}.PlanningRecommendationTitle`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.PlanningRecommendationDescription`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <FileManagerWrapper
                            documentType="RecommendationForInclusion"
                            state={fileState}
                            setState={setFileState}
                            enableAddFile={isCouncilOrAdmin}
                            disableDeleteFile={!isCouncilOrAdmin}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="managementConfirmed"
                            field={DateCompletedField.ManagementConfirmed}
                            dateCompleted={props.dateCompleted}
                            progressOnClick={props.progressOnClick}
                            editable={isCouncilOrAdmin}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
