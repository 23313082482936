import { LicenseInfo } from "@mui/x-data-grid-pro";
import App from "App";
import "index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
