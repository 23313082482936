import axios from "axios";
import { useCallback } from "react";
import { useApiFetch } from "./useApiFetch";

export type FileCreatedDto = {
    fileName: string,
    sasToken: string,
    guid: string
};

export type FileNameDict = { [x: string]: File }

type UseDocumentUploaderProps = {
    config: UploadConfig
    onSuccess: (files: FileCreatedDto[]) => void;
    onError: (error: string) => void
}

type UploadConfig = {
    parentFolder?: string;
    siteId?: string;
    documentTypeName?: string;
}

export default function useDocumentUploader({ config, onSuccess, onError }: UseDocumentUploaderProps) {

    const { apiPost } = useApiFetch();

    const isGeneralDocument = config.parentFolder != null;
    const uploadPath = isGeneralDocument ? `GeneralDocuments/Upload?parentFolder=${config.parentFolder}` : `NotifiedSite/${config.siteId}/Document/Upload/${config.documentTypeName}`;
    const uploadCompletePath = isGeneralDocument ? `GeneralDocuments/UploadComplete` : `NotifiedSite/${config.siteId}/Document/UploadComplete/${config.documentTypeName}`;

    const onBlobUploadCompleted = useCallback((fileDto: FileCreatedDto) => {
        return new Promise<void>((resolve, reject) => {
            apiPost({
                path: uploadCompletePath,
                body: { fileName: fileDto.fileName, guid: fileDto.guid },
                onError: (error) => { onError(error.message); reject() },
                onSuccess: () => resolve()
            });
        })
    }, [apiPost, onError]);

    const onBlobUploadFailed = useCallback((fileDto: FileCreatedDto) => {
        return new Promise<void>((resolve, reject) => {
            apiPost({
                path: `GeneralDocuments/UploadFailed`,
                body: { fileName: fileDto.fileName, guid: fileDto.guid },
                onError: (error) => { onError(error.message); reject() },
                onSuccess: () => { onError(`Failed to upload ${fileDto.fileName}`); reject() }
            });
        })
    }, [apiPost, onError]);


    const uploadFilesToBlob = useCallback(async ({ fileDtos, filenameDict }: { fileDtos: FileCreatedDto[], filenameDict: FileNameDict }) => {
        const uploadPromises = fileDtos.map(fileDto => {
            const file = filenameDict[fileDto.fileName];
            return axios.request({
                method: "PUT",
                url: fileDto.sasToken,
                data: file,
                headers: {
                    "Content-type": file.type,
                    "x-ms-blob-type": "BlockBlob"
                }
            })
                .then(() => onBlobUploadCompleted(fileDto))
                .catch((error) => isGeneralDocument ? onBlobUploadFailed(fileDto) : onError(error.message));
        });

        try {
            await Promise.all(uploadPromises);
            onSuccess(fileDtos);
        } catch (error) {
            onError((error as { message: string }).message);
        }
    }, [onBlobUploadCompleted, onBlobUploadFailed, onSuccess, onError]);

    const uploadDocuments = useCallback((files: File[]) => {
        if (!files.length) {
            return;
        }

        const filenameDict = files.reduce<FileNameDict>((dict, file) => {
            dict[file.name] = file;
            return dict;
        }, {});

        apiPost({
            path: uploadPath,
            body: { fileNames: Object.keys(filenameDict) },
            onSuccess: async (fileDtos) => await uploadFilesToBlob({ fileDtos, filenameDict }),
            onError: (error) => onError(error.message),
        });
    }, [apiPost, uploadFilesToBlob, onError]);


    return { uploadDocuments };
}