import authService from "components/api-authorization/AuthorizeService";
import { createContext, ReactNode, useEffect, useState } from "react";
import { BaseContextState } from "types/BaseContextState";

interface AuthenticatedContextState extends BaseContextState {
    isAuthenticated: boolean;
}

const contextDefaultValues: AuthenticatedContextState = {
    isAuthenticated: false,
    error: undefined,
    isLoading: true,
};

export const AuthenticatedContext =
    createContext<AuthenticatedContextState>(contextDefaultValues);

export function AuthenticatedProvider({ children }: { children: ReactNode }) {
    const [authenticatedState, setAuthenticatedState] =
        useState<AuthenticatedContextState>(contextDefaultValues);

    useEffect(() => {
        (async () => {
            const isAuthenticated = await authService.isAuthenticated();
            setAuthenticatedState({
                isAuthenticated: isAuthenticated,
                isLoading: false,
                error: undefined,
            });
        })();
    }, []);

    return (
        <AuthenticatedContext.Provider value={authenticatedState}>
            {children}
        </AuthenticatedContext.Provider>
    );
}
