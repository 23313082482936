import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledDataGridBox = styled(Box)<BoxProps>(({ theme }) => ({
    flexGrow: 1,
    '& .datagrid-row-tranche-0': {
        backgroundColor: theme.tranche[0],
    },
    '& .datagrid-row-tranche-1': {
        backgroundColor: theme.tranche[1],
    },
    '& .datagrid-row-tranche-2': {
        backgroundColor: theme.tranche[2],
    },
    '& .datagrid-row-tranche-3': {
        backgroundColor: theme.tranche[3],
    },
    '& .datagrid-row-tranche-4': {
        backgroundColor: theme.tranche[4],
    },
    '& .datagrid-row-tranche-5': {
        backgroundColor: theme.tranche[5],
    },
    '& .datagrid-row-tranche-6': {
        backgroundColor: theme.tranche[6],
    }
}));
