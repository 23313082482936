import SaveIcon from "@mui/icons-material/Save";
import { Alert, AlertProps, Fab, Grid, Snackbar } from "@mui/material";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { CollationOfKnowledge } from "pages/forms/CollationOfKnowledge";
import { ReviewOfTask1 } from "pages/forms/ReviewOfTask1";
import { TechnicalSiteAssessmentForm } from "pages/forms/TechnicalSiteAssessment";
import { UndeleteFilesCard } from "pages/UndeleteFiles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompletedDates } from "types/CompletedDates";
import { EditDateCompleted } from "types/EditDateCompleted";
import {
    DefaultTechnicalSiteAssessment,
    TechnicalSiteAssessment,
    TechnicalSiteAssessmentEntry,
} from "types/TechnicalSiteAssessment";

interface MataurangiMaoriProps {
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

export function MataurangiMaori(props: MataurangiMaoriProps) {
    const [state, setState] = useState<TechnicalSiteAssessment>(
        DefaultTechnicalSiteAssessment
    );
    const [loadData, setLoadData] = useState(true);
    const { apiGet, apiPost } = useApiFetch();
    const { id } = useParams();
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            getTechnicalAssessmentData({
                onSuccess: onGetSuccess,
                onError: handleFetchError,
            });
        }
    }, [loadData]);

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item md={6}>
                    <ReviewOfTask1
                        dateCompleted={
                            props.completedDates?.assessmentProcessStarted
                        }
                        progressOnClick={props.progressOnClick}
                    />
                    <CollationOfKnowledge
                        dateCompleted={
                            props.completedDates?.traditionalKnowledgeComplete
                        }
                        progressOnClick={props.progressOnClick}
                    />
                    <Grid item md={12}>
                        <UndeleteFilesCard />
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <TechnicalSiteAssessmentForm
                        dateCompleted={
                            props.completedDates
                                ?.technicalSiteAssessmentComplete
                        }
                        progressOnClick={props.progressOnClick}
                        state={state}
                        setState={(value: TechnicalSiteAssessment) =>
                            setState(value)
                        }
                    />
                </Grid>
                <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="save"
                    sx={{ position: "fixed", bottom: "16px" }}
                    onClick={saveTechnicalAssessment}
                >
                    <SaveIcon sx={{ mr: 1 }} /> Save Task 2
                </Fab>
            </Grid>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function getTechnicalAssessmentData({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetTechnicalSiteAssessment?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function onGetSuccess(data: any) {
        if (data) {
            setState(parseApiData(data));
        }
    }

    function parseApiData(data: TechnicalSiteAssessment) {
        data.historical = parseAssessmentEntry(data.historical);
        data.mauri = parseAssessmentEntry(data.mauri);
        data.wahiTapu = parseAssessmentEntry(data.wahiTapu);
        data.customaryResources = parseAssessmentEntry(data.customaryResources);
        data.customaryNeeds = parseAssessmentEntry(data.customaryNeeds);
        data.contemporaryEsteem = parseAssessmentEntry(data.contemporaryEsteem);
        data.horopaki = parseAssessmentEntry(data.horopaki);
        return data;
    }

    function parseAssessmentEntry(data: TechnicalSiteAssessmentEntry) {
        if (data.lastUpdate) {
            data.lastUpdate = new Date(data.lastUpdate);
        }

        if (data.commentsLastUpdate) {
            data.commentsLastUpdate = new Date(data.commentsLastUpdate);
        }
        return data;
    }

    function onSaveSuccess(data?: any) {
        setSnackbar({
            children: "Technical Site Assessment successfully saved",
            severity: "success",
        });
        setLoadData(true);
    }

    function saveTechnicalAssessment() {
        apiPost({
            path: `NominatedSite/UpdateTechnicalSiteAssessment?nominatedSiteId=${id}`,
            body: state,
            onSuccess: (data) => onSaveSuccess(),
            onError: (error) => handleFetchError(error),
        });
    }
}
