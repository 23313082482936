export type ThreatAssessment = {
    earthworks?: number | null;
    placementOfUtilities?: number | null;
    stormwaterManagement?: number | null;
    onSiteWastewater?: number | null;
    otherDischarges?: number | null;
    groundwaterTake?: number | null;
    dammingAndDiversionOfWater?: number | null;
    roadRunoff?: number | null;
    industrialDischarges?: number | null;
    litter?: number | null;
    wastewaterOverflows?: number | null;
    landfills?: number | null;
    changesToLakesRiversAndWetland?: number | null;
    changesToCostalArea?: number | null;
    vegetationRemoval?: number | null;
    subdivision?: number | null;
    newBuilding?: number | null;
    alterationsToExistingBuildings?: number | null;
    constructionOfResidentialUnits?: number | null;
    schoolsHomesRetirementVillages?: number | null;
    healthcareServices?: number | null;
    campingGrounds?: number | null;
    communityFacilities?: number | null;
    cafesRestaurants?: number | null;
    horticulture?: number | null;
    serviceStations?: number | null;
    customRatings: CustomRatings;
};

export type CustomRating = {
    description: string;
    rating: number | null;
    id?: number | null;
};

export type CustomRatings = CustomRating[];

export const DefaultThreatAssessment: ThreatAssessment = {
    earthworks: null,
    placementOfUtilities: null,
    stormwaterManagement: null,
    onSiteWastewater: null,
    otherDischarges: null,
    groundwaterTake: null,
    dammingAndDiversionOfWater: null,
    roadRunoff: null,
    industrialDischarges: null,
    litter: null,
    wastewaterOverflows: null,
    landfills: null,
    changesToLakesRiversAndWetland: null,
    changesToCostalArea: null,
    vegetationRemoval: null,
    subdivision: null,
    newBuilding: null,
    alterationsToExistingBuildings: null,
    constructionOfResidentialUnits: null,
    schoolsHomesRetirementVillages: null,
    healthcareServices: null,
    campingGrounds: null,
    communityFacilities: null,
    cafesRestaurants: null,
    horticulture: null,
    serviceStations: null,
    customRatings: [],
};
