import {
    Alert,
    AlertProps,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Paper,
    Snackbar,
    Typography,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { RoleContext } from "context/roleContext";

type FileItem = {
    id: string;
    fileName: string;
    length: number;
    dateCreated: Date;
    dateDeleted: Date;
    documentType: number;
    categoryId?: number;
    sensitivity?: number;
};

export default function UndeleteFiles() {
    const paperStyle = { padding: "20px", width: "80vw", margin: "20px auto" };
    const [files, setFiles] = useState<FileItem[]>([]);
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const { apiGet, apiPost } = useApiFetch();
    const { id } = useParams();

    useEffect(() => {
        getDeletedFiles();
    }, []);

    return (
        <Box>
            <Grid
                container
                spacing={2}
                component={Paper}
                style={paperStyle}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item md={12} key="title">
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4"> Undelete files </Typography>
                    </Box>
                    <Divider />
                </Grid>

                <HeaderRow />

                {files &&
                    files.map((file) => UndeleteFileRow(file, undeleteFile))}

                <Grid item md={12} key="spacer" />

                <Grid item md={12} key="backButton">
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        component={Link}
                        to={`/NominatedSite/${id}`}
                    >
                        Back
                    </Button>
                </Grid>
            </Grid>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function getDeletedFiles() {
        apiGet({
            path: `NotifiedSite/${id}/Document/Deleted`,
            onSuccess: onGetSuccess,
            onError: handleFetchError,
        });
    }

    function onGetSuccess(data: FileItem[]) {
        data.forEach((file) => {
            file.dateCreated = new Date(file.dateCreated);
            file.dateDeleted = new Date(file.dateDeleted);
        });
        setFiles(data);
    }

    function undeleteFile(file: FileItem) {
        apiPost({
            path: `NotifiedSite/${id}/Document/Undelete?fileName=${file.fileName}`,
            onSuccess: () => onUndeleteSuccess(file),
            onError: handleFetchError,
        });
    }

    function onUndeleteSuccess(file: FileItem) {
        setSnackbar({
            children: `${file.fileName} restored`,
            severity: "success",
        });
        getDeletedFiles();
    }
}

function HeaderRow() {
    return (
        <>
            <Grid item md={3} key="docType">
                <Typography fontWeight="bold">Document Type</Typography>
            </Grid>
            <Grid item md={3} key="fileName">
                <Typography fontWeight="bold">File Name</Typography>
            </Grid>
            <Grid item md={2} key="dateCreated">
                <Typography fontWeight="bold">Date Created</Typography>
            </Grid>
            <Grid item md={2} key="dateDeleted">
                <Typography fontWeight="bold">Date Deleted</Typography>
            </Grid>
            <Grid item md={2} key="action" />
        </>
    );
}

type UndeleteOnClick = (file: FileItem) => void;
function UndeleteFileRow(file: FileItem, onClick: UndeleteOnClick) {
    return (
        <>
            <Grid item md={3} key={`docType-${file.id}`}>
                {docTypeToString(file.documentType)}
            </Grid>
            <Grid item md={3} key={`fileName-${file.id}`}>
                {file.fileName}
            </Grid>
            <Grid item md={2} key={`dateCreated-${file.id}`}>
                {file.dateCreated.toLocaleString()}
            </Grid>
            <Grid item md={2} key={`dateDeleted-${file.id}`}>
                {file.dateDeleted.toLocaleString()}
            </Grid>
            <Grid item md={2} key={`action-${file.id}`}>
                <Button
                    variant="contained"
                    startIcon={<RestoreIcon />}
                    fullWidth
                    onClick={() => onClick(file)}
                >
                    Undelete
                </Button>
            </Grid>
        </>
    );
}

export function UndeleteFilesCard() {
    const { id } = useParams();
    const roleContext = useContext(RoleContext);

    const visible = useMemo(
        () => roleContext.roles?.isSysAdmin,
        [roleContext, roleContext.roles]
    );

    return visible ? (
        <Card>
            <CardContent>
                <Typography variant="h5" color="GrayText">
                    Accidentally deleted a file?
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    component={Link}
                    to={`/NominatedSite/${id}/Undelete`}
                    startIcon={<RestoreIcon />}
                >
                    Undelete Files For This Site
                </Button>
            </CardActions>
        </Card>
    ) : (
        <></>
    );
}

function docTypeToString(docType: number): string {
    switch (docType) {
        case 0: {
            return "Baseline Assessment";
        }
        case 1: {
            return "Public Information";
        }
        case 2: {
            return "Technical Site Assessment";
        }
        case 3: {
            return "Traditional Knowledge";
        }
        case 4: {
            return "Risk Assessment";
        }
        case 5: {
            return "Landowner Engagement";
        }
        case 6: {
            return "Landowner Communication";
        }
        case 7: {
            return "Recommendation For Inclusion";
        }
        case 8: {
            return "General Document";
        }
        case 9: {
            return "Management Response Summary";
        }
        case 10: {
            return "Managment Response Document";
        }
        default: {
            return "Unknown";
        }
    }
}
