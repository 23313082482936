import {
    Alert,
    AlertProps, Grid,
    Snackbar,
    TextField,
    useTheme
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ProgressInput from "components/ProgressInput";
import { useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";
import { FileManagerState, FileManagerWrapper } from "../../components/FileManager/FileManagerWrapper";

export type ConfirmLandownersState = {
    landownerFeedback: string;
    existingRelationship: string;
    propertyAccess: string;
};

export const defaultConfirmLandownersState: ConfirmLandownersState = {
    landownerFeedback: "",
    existingRelationship: "",
    propertyAccess: "",
};

interface ConfirmLandownersProps {
    state: ConfirmLandownersState;
    setState: (value: ConfirmLandownersState) => void;
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task3.ConfirmLandowners";

export function ConfirmLandowners({
    state,
    setState,
    completedDates,
    progressOnClick,
}: ConfirmLandownersProps) {
    const theme = useTheme();
    const intl: IntlShape = useIntl();
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [fileState, setFileState] = useState<FileManagerState>({
        files: [],
        numberOfFiles: 0,
        uploading: false
    });

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="task3-governance-communication"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>

                    <Typography variant="h5" align="right">
                        {fileState.numberOfFiles}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.Description`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        {fileState.files && (
                            <FileManagerWrapper
                                documentType="LandOwnerEngagement"
                                state={fileState}
                                setState={setFileState}
                                enableAddFile
                                disableDeleteFile={false}
                            />
                        )}
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            multiline
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.LandownerFeedback`,
                            })}
                            value={state.landownerFeedback ?? ""}
                            onChange={(event) =>
                                onChangeEntry(
                                    "landownerFeedback",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            multiline
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.ExistingRelationship`,
                            })}
                            value={state.existingRelationship ?? ""}
                            onChange={(event) =>
                                onChangeEntry(
                                    "existingRelationship",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            multiline
                            fullWidth
                            label={intl.formatMessage({
                                id: `${section}.PropertyAccess`,
                            })}
                            value={state.propertyAccess ?? ""}
                            onChange={(event) =>
                                onChangeEntry(
                                    "propertyAccess",
                                    event.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="propertiesIdentified"
                            field={DateCompletedField.PropertiesIdentified}
                            dateCompleted={completedDates?.propertiesIdentified}
                            progressOnClick={progressOnClick}
                            editable
                        />
                        <ProgressInput
                            title="landownerAccessSought"
                            field={DateCompletedField.LandownerAccessSought}
                            dateCompleted={
                                completedDates?.landownerAccessSought
                            }
                            progressOnClick={progressOnClick}
                            editable
                        />
                        <ProgressInput
                            title="landownerApprovalReceived"
                            field={DateCompletedField.LandownerApprovalReceived}
                            dateCompleted={
                                completedDates?.landownerApprovalReceived
                            }
                            progressOnClick={progressOnClick}
                            editable
                        />
                        <ProgressInput
                            title="feedbackToLandownerGovernance"
                            field={
                                DateCompletedField.FeedbackToLandownerGovernance
                            }
                            dateCompleted={
                                completedDates?.feedbackToLandownerGovernance
                            }
                            progressOnClick={progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </AccordionDetails>
        </Accordion>
    );

    function onChangeEntry(
        property: keyof ConfirmLandownersState,
        value: string | boolean | undefined
    ) {
        setState({ ...state, [property]: value });
    }

}
