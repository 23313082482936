import {
    AssetStatusUpdateForm,
    DefaultAssetStatusUpdateForm,
} from "./AssetStatusUpdateForm";
import {
    CurrentManagementOfTheMaoriHeritageAssetForm,
    DefaultCurrentManagementOfTheMaoriHeritageAssetForm,
} from "./CurrentManagementOfTheMaoriHeritageAssetForm";
import { NominatedSiteProgress } from "./NominatedSiteProgress";
import { DefaultSiteAnalysisForm, SiteAnalysisForm } from "./SiteAnalysisForm";

export type NominatedSiteState = SiteAnalysisForm &
    CurrentManagementOfTheMaoriHeritageAssetForm &
    AssetStatusUpdateForm &
    NominatedSiteProgress & {
        assetName?: string;
        mhid?: string;
        showManagementResponse?: boolean;
    };

export const DefaultNominatedSiteState: NominatedSiteState = Object.assign(
    DefaultSiteAnalysisForm,
    DefaultCurrentManagementOfTheMaoriHeritageAssetForm,
    DefaultAssetStatusUpdateForm,
    { assetName: "", mhid: "", showManagementResponse: false }
);
