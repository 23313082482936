import SaveIcon from "@mui/icons-material/Save";
import {
    Alert,
    AlertProps,
    Button,
    Fab,
    Grid,
    Snackbar,
    Typography,
} from "@mui/material";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import { ExistingImpactsContext } from "context/existingImpactsContext";
import { FutureThreatsContext } from "context/futureThreatsContext";
import { useApiFetch } from "hooks/useApiFetch";
import { ExistingImpacts } from "pages/forms/ExistingImpacts";
import { FutureThreats } from "pages/forms/FutureThreats";
import { useContext, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { CompletedDates } from "types/CompletedDates";
import { EditDateCompleted } from "types/EditDateCompleted";
interface ThreatAssessmentProps {
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task4";

export function ThreatAssessment(props: ThreatAssessmentProps) {
    const { apiGet, apiPost } = useApiFetch();
    const { id } = useParams();
    const intl: IntlShape = useIntl();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const futureThreatsContext = useContext(FutureThreatsContext);
    const existingImpactsContext = useContext(ExistingImpactsContext);

    return (
        <>
            <Grid container spacing={3} justifyContent="center">
                <Grid item md={12}>
                    <Button onClick={onClick} variant="outlined">
                        Generate Site Analysis Report
                    </Button>
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h5">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage id={`${section}.ReferToSection`} />
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    {existingImpactsContext.existingImpacts &&
                        existingImpactsContext.setExistingImpacts && (
                            <ExistingImpacts />
                        )}
                </Grid>
                <Grid item md={6}>
                    {futureThreatsContext.futureThreats &&
                        futureThreatsContext.setFutureThreats && (
                            <FutureThreats
                                dateCompleted={
                                    props.completedDates?.threatIssuesComplete
                                }
                                progressOnClick={props.progressOnClick}
                            />
                        )}
                </Grid>
                <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="save"
                    sx={{ position: "fixed", bottom: "16px" }}
                    onClick={saveForm}
                >
                    <SaveIcon sx={{ mr: 1 }} /> Save Task 4
                </Fab>
            </Grid>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function handleNotFoundError() {
        const warningFormat = intl.formatMessage({
            id: "Task4.SiteAnalysisReport.NoReportWarning",
        });

        const now = new Date()
        const nextMonthStart = new Date(now.getFullYear(), now.getMonth() + 1, 1).toLocaleDateString(intl.locale)

        setSnackbar({
            children: warningFormat.replace("{0}", nextMonthStart), severity: "warning"
        });
    }

    function onSaveSuccess(message: string) {
        setSnackbar({
            children: message,
            severity: "success",
        });
    }

    function saveForm() {
        saveExistingImpacts();
        saveFutureThreats();
    }

    function saveExistingImpacts() {
        apiPost({
            path: `NominatedSite/UpdateExistingImpacts?nominatedSiteId=${id}`,
            body: existingImpactsContext.existingImpacts,
            onSuccess: (data) =>
                onSaveSuccess("Existing impacts successfully saved"),
            onError: (error) => handleFetchError(error),
        });
    }

    function saveFutureThreats() {
        apiPost({
            path: `NominatedSite/UpdateFutureThreats?nominatedSiteId=${id}`,
            body: futureThreatsContext.futureThreats,
            onSuccess: (data) =>
                onSaveSuccess("Future threats successfully saved"),
            onError: (error) => handleFetchError(error),
        });
    }
    function onClick() {
        apiGet({
            path: `NominatedSite/GetSiteAnalysisReport?nominatedSiteId=${id}`,
            onSuccess: (data) => onDownloadSuccess(data.filename, data.blob),
            onError: (error) => {
                console.log(error.status)
                error.status === 404 ? handleNotFoundError() : handleFetchError(error)
            },
        });
    }

    function onDownloadSuccess(filename: string, blob: Blob) {
        var blobAsFile = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = blobAsFile;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(blobAsFile);
        document.body.removeChild(a);
    }
}
