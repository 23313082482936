import { AddUser } from "pages/AddUser";
import { ChangePassword } from "pages/ChangePassword";
import { CouncilDocuments } from "pages/CouncilDocuments";
import EditUserPage from "pages/EditUser";
import { LookupManagement } from "pages/LookupManagement";
import { MyDetails } from "pages/MyDetails";
import { Reports } from "pages/Reports";
import { SharedCouncilDocuments } from "pages/SharedCouncilDocuments";
import { SiteAssessment } from "pages/SiteAssessment";
import UndeleteFiles from "pages/UndeleteFiles";
import UsersPage from "pages/Users";
import { ValidFileExtensions } from "pages/ValidFileExtensions";
import { useRoutes } from "react-router";
import { RouteObject } from "react-router-dom";
import { NominatedSite } from "../pages/NominatedSite";
import { apiAuthorizationRoutes } from "./api-authorization/ApiAuthorizationRoutes";
import Authorize from "./api-authorization/AuthorizeComponent";
import { Header } from "./header";
import Page from "./Page";

var TitleSuffix = () => {
    if (window.location.hostname == "localhost") {
        return "MCHP Local";
    } else if (window.location.hostname.includes("test")) {
        return "MCHP Test";
    } else {
        return "Māori Cultural Heritage Project";
    }
};

export default function Layout() {
    const routes = useRoutes([...layoutRoutes, apiAuthorizationRoutes]);
    return <>{routes}</>;
}

const layoutRoutes: RouteObject[] = [
    {
        path: "/",
        element: (
            <Authorize>
                <Header />
            </Authorize>
        ),
        children: [
            {
                path: "",
                element: (
                    <Page title="Site Assessment" suffix={TitleSuffix()}>
                        <SiteAssessment />
                    </Page>
                ),
            },
            {
                path: "CouncilDocuments",
                element: (
                    <Page title="Council Documents" suffix={TitleSuffix()}>
                        <CouncilDocuments />
                    </Page>
                ),
            },
            {
                path: "SharedCouncilDocuments",
                element: (
                    <Page
                        title="Shared Council Documents"
                        suffix={TitleSuffix()}
                    >
                        <SharedCouncilDocuments />
                    </Page>
                ),
            },
            {
                path: "Reports",
                element: (
                    <Page title="Reports" suffix={TitleSuffix()}>
                        <Reports />
                    </Page>
                ),
            },
            {
                path: "ValidFileExtensions",
                element: (
                    <Page title="Valid File Extensions" suffix={TitleSuffix()}>
                        <ValidFileExtensions />
                    </Page>
                ),
            },
            {
                path: "LookupManagement",
                element: (
                    <Page title="Lookup Management" suffix={TitleSuffix()}>
                        <LookupManagement />
                    </Page>
                ),
            },
            {
                path: "MyDetails",
                element: (
                    <Page title="My Details" suffix={TitleSuffix()}>
                        <MyDetails />
                    </Page>
                ),
            },
            {
                path: "ChangePassword",
                element: (
                    <Page title="Change Password" suffix={TitleSuffix()}>
                        <ChangePassword />
                    </Page>
                ),
            },
            {
                path: "NominatedSite/:id/Undelete",
                element: (
                    <Page title="Undelete Files" suffix={TitleSuffix()}>
                        <UndeleteFiles />
                    </Page>
                ),
            },
            {
                path: "NominatedSite/:id",
                element: (
                    <Page title="Nominated Site" suffix={TitleSuffix()}>
                        <NominatedSite />
                    </Page>
                ),
            },
            {
                path: "SiteAssessment/:iwiId",
                element: (
                    <Page title="Site Assessment" suffix={TitleSuffix()}>
                        <SiteAssessment />
                    </Page>
                ),
            },
            {
                path: "users",
                element: (
                    <Page title="Users" suffix={TitleSuffix()}>
                        <UsersPage />
                    </Page>
                ),
            },
            {
                path: "Users/AddUser",
                element: (
                    <Page title="Users" suffix={TitleSuffix()}>
                        <AddUser />
                    </Page>
                ),
            },
            {
                path: "edituser/:id",
                element: (
                    <Page title="Edit User" suffix={TitleSuffix()}>
                        <EditUserPage />
                    </Page>
                ),
            },
        ],
    },
];
