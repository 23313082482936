import { createTheme, ThemeProvider } from "@mui/material";
import Layout from "components/Layout";
import { AlertLayerUrlProvider } from "context/alertLayerUrlContext";
import { AuthenticatedProvider } from "context/authenticatedContext";
import { CouncilMapUrlProvider } from "context/councilMapUrlContext";
import { CouncilTokenProvider } from "context/councilTokenContext";
import { CurrentUserProvider } from "context/currentUserContext";
import { IwiProvider } from "context/iwiContext";
import { IwiListProvider } from "context/iwiListContext";
import { IwiTokenProvider } from "context/iwiTokenContext";
import { RefreshFromGisProvider } from "context/refreshFromGisContext";
import { RoleProvider } from "context/roleContext";
import { IntlContainer } from "hooks/IntlWrapper";
import { BrowserRouter } from "react-router-dom";

const defaultTheme = createTheme({
    typography: {
        button: {
            textTransform: "none",
        },
    },
    palette: {
        primary: {
            main: "#2b6497",
        },
        secondary: {
            main: "#94d454",
            contrastText: "white",
        },
    },
    tranche: {
        0: "white",
        1: "lightgreen",
        2: "lightblue",
        3: "lightyellow",
        4: "#ffcc80",
        5: "#bcaaa4",
        6: "#b0bec5",
    },
    accordion: {
        bgcolor: "#d9edf7",
        color: "#31708f",
        boxShadow: 1,
    },
    card: {
        widthWithText: "16.6%",
        heightWithText: 90,
        widthWithoutText: "20px",
        heightWithoutText: "20px",
        borderRadius: 0,
        border: 1,
        borderColor: "grey.300",
        completedBackgroundColor: "#D9EDD1",
        inProgressBackgroundColor: "white",
        completedTextColor: "grey.800",
        inProgressTextColor: "grey.500",
        typography: {
            fontSize: "small",
            textAlign: "center",
        },
        cardContent: {
            height: "100%",
        },
        box: {
            height: "90%",
            width: "100%",
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    height: "auto",
                }
            }
        }
    }
});

export default function App() {
    return (
        <IntlContainer>
            <BrowserRouter>
                <ThemeProvider theme={defaultTheme}>
                    <AuthenticatedProvider>
                        <CurrentUserProvider>
                            <RoleProvider>
                                <IwiListProvider>
                                    <IwiProvider>
                                        <CouncilMapUrlProvider>
                                            <CouncilTokenProvider>
                                                <IwiTokenProvider>
                                                    <AlertLayerUrlProvider>
                                                        <RefreshFromGisProvider>
                                                            <Layout />
                                                        </RefreshFromGisProvider>
                                                    </AlertLayerUrlProvider>
                                                </IwiTokenProvider>
                                            </CouncilTokenProvider>
                                        </CouncilMapUrlProvider>
                                    </IwiProvider>
                                </IwiListProvider>
                            </RoleProvider>
                        </CurrentUserProvider>
                    </AuthenticatedProvider>
                </ThemeProvider>
            </BrowserRouter>
        </IntlContainer>
    );
}
