import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type TaskMapsType = {
    mapUrl: string;
};

export const MapUrlContext = createContext<TaskMapsType>({
    mapUrl: "",
});

export function MapUrlProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();
    const params = useParams();

    const [state, setState] = useState<TaskMapsType>({
        mapUrl: "",
    });

    useEffect(() => {
        apiGet({
            path: `MapForIwi/GetMapUrl?siteId=${params.id}`,
            onSuccess: (data) =>
                setState({
                    ...state,
                    mapUrl: `${data.url}?id=${data.esriFolder}&token=${data.esriToken.token}&query=Sites%20and%20Landscapes,MHID,${data.mhidNumber}`,
                }),
        });
    }, []);

    return (
        <MapUrlContext.Provider value={state}>
            {children}
        </MapUrlContext.Provider>
    );
}
