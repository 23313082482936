import SquareIcon from '@mui/icons-material/Square';
import { Box, Card, CardContent, List, ListItem, Stack, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

export function TrancheLegend() {
    const theme = useTheme();
    const data = [
        {
            id: 0,
            item: "Tranche 0",
            color: theme.tranche[0]
        },
        {
            id: 1,
            item: "Tranche 1",
            color: theme.tranche[1]
        },
        {
            id: 2,
            item: "Tranche 2",
            color: theme.tranche[2]
        },
        {
            id: 3,
            item: "Tranche 3",
            color: theme.tranche[3]
        },
        {
            id: 4,
            item: "Tranche 4",
            color: theme.tranche[4]
        },
        {
            id: 5,
            item: "Tranche 5",
            color: theme.tranche[5]
        }, {
            id: 6,
            item: "Tranche 6",
            color: theme.tranche[6]
        }];

    return (
        <Card sx={{ position: "sticky", top: 0, zIndex: 10 }} >
            <CardContentNoPadding>
                <List
                    component={Stack}
                    direction="row"
                >
                    {data.map(elem => (
                        <ListItem sx={{ display: "flex" }} key={elem.id}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Stack direction="row" spacing={1} width="100%" alignItems="center">
                                    <SquareIcon fontSize="small" sx={{ color: elem.color, bgcolor: elem.color, border: '0.1px solid lightgray' }} />
                                    <Typography variant="body2" sx={{ flexGrow: "1", justifyContent: "center" }}>{elem.item}</Typography>
                                </Stack>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </CardContentNoPadding>
        </Card>
    )
}
