import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { ExistingImpactsState } from "types/ExistingImpacts";
import { FutureThreatsState } from "types/FutureThreats";
import { CustomRating, CustomRatings } from "types/ThreatAssessment";
import { RadioGroupInput } from "./RadioGroupInput";

interface ThreatAssessmentAddCustomProps {
    isFutureThreat: boolean; // true if future threat, false if existing impact
    state: FutureThreatsState & ExistingImpactsState;
    setState: (value: FutureThreatsState & ExistingImpactsState) => void;
}

export function ThreatAssessmentAddCustom(
    props: ThreatAssessmentAddCustomProps
) {
    const [additionalActivityState, setAdditionalActivityState] = useState(
        props.state
    );

    const section: string = props.isFutureThreat
        ? "Task4.FutureThreats"
        : "Task4.ExistingImpacts";

    const intl: IntlShape = useIntl();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    <FormattedMessage id={`${section}.ListOther`} />
                </Typography>
                <Divider />
            </Grid>
            {additionalActivityState.customRatings.map((input, index) => {
                return (
                    <>
                        <Grid item xs={4}>
                            <Paper
                                component="form"
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <TextField
                                    fullWidth
                                    multiline
                                    label={intl.formatMessage({
                                        id: `${section}.AdditionalActivityDescription`,
                                    })}
                                    value={input.description}
                                    onChange={(event) =>
                                        handleChange(index, event)
                                    }
                                    onBlur={(event) => handleBlur(index, event)}
                                    name="description"
                                />
                                <IconButton onClick={() => removeFields(index)}>
                                    <ClearIcon color="error" />
                                </IconButton>
                            </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <RadioGroupInput
                                name="customRatings"
                                onChange={onChange}
                                value={input.rating}
                                index={index}
                            />
                        </Grid>
                    </>
                );
            })}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={addFields}
                >
                    <Typography variant="body1">
                        <FormattedMessage
                            id={`${section}.AdditionalActivity`}
                        />
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );

    function addFields() {
        const newfield: CustomRating = { description: "", rating: null };
        setAdditionalActivityState({
            ...additionalActivityState,
            customRatings: [...additionalActivityState.customRatings, newfield],
        });
    }

    function removeFields(index: number) {
        const customRatings: CustomRatings = [
            ...additionalActivityState.customRatings,
        ];
        customRatings.splice(index, 1);
        setAdditionalActivityState({
            ...additionalActivityState,
            customRatings,
        });
        props.setState({ ...props.state, customRatings });
    }

    // Runs whenever the additional field text field is changed
    function handleChange(
        index: number,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const customRatings: CustomRatings = [
            ...additionalActivityState.customRatings,
        ];
        customRatings[index].description = event.target.value;
        setAdditionalActivityState({
            ...additionalActivityState,
            customRatings,
        });
    }

    // Runs whenever the additional field text field onBlur change event is triggered
    function handleBlur(
        index: number,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const customRatings: CustomRatings = [
            ...additionalActivityState.customRatings,
        ];
        customRatings[index].description = event.target.value;
        props.setState({ ...props.state, customRatings: customRatings });
    }

    // Runs whenever the additional field radio group is changed
    function onChange(
        property: keyof FutureThreatsState | keyof ExistingImpactsState,
        value: string,
        index?: number
    ) {
        if (index != undefined) {
            const customRatings: CustomRatings = [
                ...additionalActivityState.customRatings,
            ];
            customRatings[index].rating = parseInt(value);
            props.setState({ ...props.state, customRatings });
        }
    }
}
