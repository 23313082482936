import { Grid, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { TaskMaps } from "components/TaskMaps";
import { FormattedMessage } from "react-intl";

type OnTheGroundReviewForm = {
    mapUrl: string;
};

const section: string = "Task3.OnTheGroundReview";

export function OnTheGroundReview() {
    const theme = useTheme();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="on-the-ground-review-content"
                id="on-the-ground-review-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <TaskMaps />
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.ReferToGuide`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage id={`${section}.PurposeHeader`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.PurposeDescription`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage id={`${section}.ProcessHeader`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.ProcessDescription.1`}
                            />
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.ProcessDescription.2`}
                            />
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.ProcessDescription.3`}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
