export type Roles = {
    isCouncilUser: boolean;
    isSysAdmin: boolean;
    isIwiUser: boolean;
};

export const DefaultRoles: Roles = {
    isCouncilUser: false,
    isSysAdmin: false,
    isIwiUser: false,
};
