export type SiteAnalysisForm = {
    assetTypeId: string;
    assetName: string;
    keyAssetFeatures: string[];
    keyAssetFeaturesText: string;
    keyAssetFeaturesOther: string;
    addressLocation: string;
    legalDescription: string;
    nztmCentroidN: string;
    nztmCentroidE: string;
    landStatusId: string;
    landowner: string;
    manaWhenuaRohes: string[];
    localBoards: string[];
    zonings: string[];
};

export const DefaultSiteAnalysisForm = {
    assetTypeId: "",
    assetName: "",
    keyAssetFeatures: [],
    keyAssetFeaturesText: "",
    keyAssetFeaturesOther: "",
    addressLocation: "",
    legalDescription: "",
    nztmCentroidN: "",
    nztmCentroidE: "",
    landStatusId: "",
    landowner: "",
    manaWhenuaRohes: [],
    localBoards: [],
    zonings: [],
};
