import authService from "components/api-authorization/AuthorizeService";
import { createContext, ReactNode, useEffect, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { Roles } from "types/Roles";

interface RolesContextState extends BaseContextState {
    roles?: Roles;
    isCouncilOrAdmin: boolean;
}

const contextDefaultValues: RolesContextState = {
    roles: undefined,
    isCouncilOrAdmin: false,
    error: undefined,
    isLoading: true,
};

export const RoleContext =
    createContext<RolesContextState>(contextDefaultValues);

export function RoleProvider({ children }: { children: ReactNode }) {
    const [rolesState, setRolesState] =
        useState<RolesContextState>(contextDefaultValues);

    useEffect(() => {
        (async () => {
            const rolesToSave = await authService.getUserRoles();
            setRolesState({
                roles: rolesToSave,
                isCouncilOrAdmin:
                    rolesToSave.isCouncilUser ||
                    rolesToSave.isSysAdmin,
                error: undefined,
                isLoading: false,
            });
        })();
    }, []);

    return (
        <RoleContext.Provider value={rolesState}>
            {children}
        </RoleContext.Provider>
    );
}
