export type CurrentManagementOfTheMaoriHeritageAssetForm = {
    siteExtentConfirmed: boolean;
    managementStatement: boolean;
    managementStatementNotes: string;
    iwiManagementPlan: string;
    treatySettlementCulturalCommercialRedress: string;
    statutoryAcknowledgementArea: string;
    haurakiGulfMarineParkAct: boolean;
    waitakereRangesHeritageAreaAct: boolean;
    leasesId: string[] | string;
    leasesNotes: string;
    chiId: string;
    nzaaId: string;
    heritageNewZealandPouhereTaongaListIdD: string;
    precinctId: string;
    paupSitesOfValueToManaWhenuaId: string;
    schedule12SiteOfSignificantToManaWhenua: string;
    schedule12SiteRuleException: boolean;
    schedule14HistoricHeritageProtection: string;
    schedule6OutstandingNaturalFeature: string;
    schedule7OutstandingNaturalLandscape: string;
    schedule9VolcanicViewshaft: string;
    schedule11LocalPublicViewshaft: string;
    hgipAppendix1fMaoriHeritage: string;
    notableTreeId: string;
    overlaysThatApplys: string[] | string;
    landUses: string[] | string;
    landUseNotes: string;
    landCover: string;
    covenant: boolean;
    covenantNotes: string;
    reserve: boolean;
    reserveNotes: string;
    transferOfPowers: boolean;
    transferOfPowersNotes: string;
    coManagementGovernanceAgreements: boolean;
    coManagementGovernanceAgreementsNotes: string;
    culturalValuesAssignedB652s: string[] | string;
    environmentalAndCulturalRisks: string[] | string;
    environmentalAndCulturalRisksNotes: string;
    prohibitedActivitiesAndBehaviour: string;
};

export const DefaultCurrentManagementOfTheMaoriHeritageAssetForm = {
    siteExtentConfirmed: false,
    managementStatement: false,
    managementStatementNotes: "",
    iwiManagementPlan: "",
    treatySettlementCulturalCommercialRedress: "",
    statutoryAcknowledgementArea: "",
    haurakiGulfMarineParkAct: false,
    waitakereRangesHeritageAreaAct: false,
    leasesId: [],
    leasesNotes: "",
    chiId: "",
    nzaaId: "",
    heritageNewZealandPouhereTaongaListIdD: "",
    precinctId: "",
    paupSitesOfValueToManaWhenuaId: "",
    schedule12SiteOfSignificantToManaWhenua: "",
    schedule12SiteRuleException: false,
    schedule14HistoricHeritageProtection: "",
    schedule6OutstandingNaturalFeature: "",
    schedule7OutstandingNaturalLandscape: "",
    schedule9VolcanicViewshaft: "",
    schedule11LocalPublicViewshaft: "",
    hgipAppendix1fMaoriHeritage: "",
    notableTreeId: "",
    overlaysThatApplys: [],
    landUses: [],
    landUseNotes: "",
    landCover: "",
    covenant: false,
    covenantNotes: "",
    reserve: false,
    reserveNotes: "",
    transferOfPowers: false,
    transferOfPowersNotes: "",
    coManagementGovernanceAgreements: false,
    coManagementGovernanceAgreementsNotes: "",
    culturalValuesAssignedB652s: [],
    environmentalAndCulturalRisks: [],
    environmentalAndCulturalRisksNotes: "",
    prohibitedActivitiesAndBehaviour: "",
};
