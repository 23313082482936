export type SiteInformationSummary = {
    previousProtectionStatus: PreviousProtectionStatus;
};

export type PreviousProtectionStatus = {
    legacyPlan: string;
    legacyPlanItemNumber: string;
    legacyPlanMapReference: string;
    existingProtectionReview: string;
};

export const DefaultPreviousProtectionStatus: PreviousProtectionStatus = {
    legacyPlan: "",
    legacyPlanItemNumber: "",
    legacyPlanMapReference: "",
    existingProtectionReview: "",
};

export type SiteVisitRecord = {
    siteVisitDate: Date | null;
    isExistingScheduleInformationConsistent?: boolean;
    oralEvidence: string;
    siteCondition?: string;
    natureOfDamage: string;
    currentManagementAppropriateness: string;
    inappropriateActivities: string;
    recommendedManagementTechniques: string;
    isLandmark?: boolean;
    isInCoastalMarineArea?: boolean;
    isInMeanHighWaterSpring?: boolean;
    audioVideoRecord: string;
    wasGpsRecorded?: boolean;
    extentInMeanHighWaterSpring: string;
    reasonAndOutcomes: string;
    contributors: SiteVisitContributors;
};

export type SiteVisitContributors = {
    manaWhenua: string;
    planner: string;
    researcher: string;
    archaeologist: string;
    historian: string;
    parks: string;
    property: string;
    consultants: string;
    landowners: string;
};

export const DefaultSiteVisitContributors: SiteVisitContributors = {
    manaWhenua: "",
    planner: "",
    researcher: "",
    archaeologist: "",
    historian: "",
    parks: "",
    property: "",
    consultants: "",
    landowners: "",
};

export const DefaultSiteVisitRecord: SiteVisitRecord = {
    siteVisitDate: null,
    isExistingScheduleInformationConsistent: false,
    oralEvidence: "",
    siteCondition: undefined,
    natureOfDamage: "",
    currentManagementAppropriateness: "",
    inappropriateActivities: "",
    recommendedManagementTechniques: "",
    isLandmark: false,
    isInCoastalMarineArea: false,
    isInMeanHighWaterSpring: false,
    audioVideoRecord: "",
    wasGpsRecorded: false,
    extentInMeanHighWaterSpring: "",
    reasonAndOutcomes: "",
    contributors: DefaultSiteVisitContributors,
};
