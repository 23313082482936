import {
    Alert,
    AlertProps, Grid,
    Snackbar,
    useTheme
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ProgressInput from "components/ProgressInput";
import { RoleContext } from "context/roleContext";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";
import { FileManagerState, FileManagerWrapper } from "../../components/FileManager/FileManagerWrapper";

interface CollationOfKnowledgeProps {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task2.CollationOfKnowledge";

export function CollationOfKnowledge({
    dateCompleted,
    progressOnClick,
}: CollationOfKnowledgeProps) {
    const [fileState, setFileState] = useState<FileManagerState>({
        files: [],
        numberOfFiles: 0,
        uploading: false
    });

    const roleContext = useContext(RoleContext);
    const isSysAdmin = roleContext.roles?.isSysAdmin;
    const isIwiUser = roleContext.roles?.isIwiUser;
    const canAddOrDelete = isSysAdmin === true || isIwiUser === true;
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const theme = useTheme();

    return (
        <Accordion defaultExpanded={true}>

            <AccordionSummary
                aria-controls="task1-review-content"
                id="task1-review-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>

                    <Typography component="h1" variant="h5" align="right">
                        {fileState.numberOfFiles}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.ReferToGuide`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id={`${section}.AddFileDescription`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography fontWeight="bold" variant="body2">
                            <FormattedMessage id={`${section}.Public`} />
                        </Typography>
                        <Typography variant="body2">
                            <FormattedMessage
                                id={`${section}.PublicDescription`}
                            />
                        </Typography>
                        <Typography fontWeight="bold" variant="body2">
                            <FormattedMessage id={`${section}.Restricted`} />
                        </Typography>
                        <Typography variant="body2">
                            <FormattedMessage
                                id={`${section}.RestrictedDescription`}
                            />
                        </Typography>
                        <Typography fontWeight="bold" variant="body2">
                            <FormattedMessage
                                id={`${section}.ManaWhenuaOnly`}
                            />
                        </Typography>
                        <Typography variant="body2">
                            <FormattedMessage
                                id={`${section}.ManaWhenuaOnlyDescription`}
                            />
                        </Typography>
                    </Grid>

                    <Grid item md={12}>
                        {fileState.files && (
                            <FileManagerWrapper
                                documentType="TraditionalKnowledge"
                                state={fileState}
                                setState={setFileState}
                                enableAddFile={canAddOrDelete}
                                disableDeleteFile={!canAddOrDelete}
                                sensitivityEditable={isSysAdmin || isIwiUser}
                            />
                        )}
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="traditionalKnowledgeComplete"
                            field={
                                DateCompletedField.TraditionalKnowledgeComplete
                            }
                            dateCompleted={dateCompleted}
                            progressOnClick={progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </AccordionDetails>
        </Accordion>
    );

}
