export const enNz: Record<string, string> = {
    title: "Māori Cultural Heritage Project",
    CouncilMap: "Open Council Map",

    "Header.RefreshGis": "REFRESH FROM GIS",
    "Header.Refreshing": "REFRESHING...",
    "Header.AlertLayer": "MĀORI HERITAGE ALERT LAYER",
    "Header.General": "General",
    "Header.SharedCouncilDocuments": "Shared Council Documents",
    "Header.CouncilDocuments": "Council Documents",
    "Header.Reports": "Reports",
    "Header.Admin": "Admin",
    "Header.Users": "Users",
    "Header.ValidFileExtensions": "Valid File Extensions",
    "Header.LookupManagement": "Lookup Management",
    "Header.User": "User",
    "Header.MyDetails": "My Details",
    "Header.ChangePassword": "Change Password",
    "Header.LogOff": "Log Off",
    "Header.SiteAssessment": "Site Assessment",
    "Header.Council": "Council",
    "Reports.SummaryReport": "Summary Report",
    "Reports.GeneratingSummaryReport": "Generating Summary Report...",

    "LookupManagement.SectionTitle": "Lookup Management",
    "LookupManagement.LookupDropdownLabel": "Lookup",
    "LookupManagement.EntriesTitle": "Entries",
    "LookupManagement.CouncilResearchCategory": " Council Research Category",
    "LookupManagement.NameUnchangedErrorMessage":
        "Please type in a new option name",
    "LookupManagement.EmptyOptionToAddErrorMessage":
        "Please type an option to add",
    "LookupManagement.Added": "Added",
    "LookupManagement.Deleted": "Deleted",
    "LookupManagement.Updated": "Updated",

    "ValidFileExtensions.SectionTitle": "Admin - File Extensions",
    "ValidFileExtensions.Description": "Manage the list of valid file extensions for file uploads on this site.",
    "ValidFileExtensions.ColumnTitle": "File Extension",
    "ValidFileExtensions.CharLengthValidationErrorMessage": "Minimum 3 characters and 16 characters allowed!",
    "ValidFileExtensions.DuplicateInputErrorMessage": "Extension name already exists!",

    "MyDetails.Heading": "My Details",
    "MyDetails.Name": "Name",
    "MyDetails.Email": "Email",
    "MyDetails.Iwi": "Iwi",
    "MyDetails.Council": "Council",
    "Generic.AddFile": "Add File",
    "Generic.UploadingFile": "Uploading File...",
    "TaskMaps.OpenMap": "Open Map",
    "NominatedSiteTitles.InformationGathering":
        "TASK 1 | Information Gathering",
    "NominatedSiteTitles.LandownerEngagement":
        "TASK 3 | Landowner engagement / site visit",
    "NominatedSiteTitles.ManagementResponse": "TASK 5 | Management Response",
    "NominatedSiteTitles.MataurangaMaori": "TASK 2 | Mātauranga Māori",
    "NominatedSiteTitles.ThreatAssessment": "TASK 4 | Threat / risk assessment",
    "SiteAnalysis.heading": "Site Information Summary",
    "SiteAnalysis.assetType": "Asset Type",
    "SiteAnalysis.assetName": "Asset Name",
    "SiteAnalysis.keyAssetFeatures": "Key Asset Features",
    "SiteAnalysis.siteDescription": "Site Description",
    "SiteAnalysis.addressLocation": "Address / Location",
    "SiteAnalysis.legalDescription": "Legal Description",
    "SiteAnalysis.legalDescription.Placeholder": "Legal site description details - may be list of titles here",
    "SiteAnalysis.nztmCentroidN": "NZTM Centroid N",
    "SiteAnalysis.nztmCentroidE": "NZTM Centroid E",
    "SiteAnalysis.landStatus": "Land Status",
    "SiteAnalysis.landowner": "Landowner",
    "SiteAnalysis.manaWhenuaRohes": "Mana Whenua Rohe",
    "SiteAnalysis.localBoards": "Local Board",
    "SiteAnalysis.zonings": "Zoning",
    "CurrentManagementOfTheMaoriHeritageAsset.heading":
        "Current Management of the Māori Heritage Asset",
    "CurrentManagementOfTheMaoriHeritageAsset.siteExtentConfirmed":
        "Site Extent Confirmed",
    "CurrentManagementOfTheMaoriHeritageAsset.KEY": "Site Extent Confirmed",
    "CurrentManagementOfTheMaoriHeritageAsset.managementStatement":
        "Management Statement",
    "CurrentManagementOfTheMaoriHeritageAsset.managementStatementNotes":
        "Management Statement Notes",
    "CurrentManagementOfTheMaoriHeritageAsset.iwiManagementPlan":
        "Iwi Management Plan",
    "CurrentManagementOfTheMaoriHeritageAsset.treatySettlementCulturalCommercialRedress":
        "Treaty Settlement (Cultural/Commercial Redress)",
    "CurrentManagementOfTheMaoriHeritageAsset.statutoryAcknowledgementArea":
        "Statutory Acknowledgement Area",
    "CurrentManagementOfTheMaoriHeritageAsset.haurakiGulfMarineParkAct":
        "Hauraki Gulf Marine Park Act",
    "CurrentManagementOfTheMaoriHeritageAsset.waitakereRangesHeritageAreaAct":
        "Waitakere Ranges Heritage Area Act",
    "CurrentManagementOfTheMaoriHeritageAsset.leases": "Leases",
    "CurrentManagementOfTheMaoriHeritageAsset.leasesNotes":
        "Leases Notes for Other",
    "CurrentManagementOfTheMaoriHeritageAsset.chiId": "CHI ID",
    "CurrentManagementOfTheMaoriHeritageAsset.nzaaId": "NZAA ID",
    "CurrentManagementOfTheMaoriHeritageAsset.heritageNewZealandPouhereTaongaListIdD":
        "Heritage New Zealand Pouhere Taonga List ID",
    "CurrentManagementOfTheMaoriHeritageAsset.precinctId": "Precinct ID",
    "CurrentManagementOfTheMaoriHeritageAsset.paupSitesOfValueToManaWhenuaId":
        "PAUP Sites of Value to Mana Whenua ID",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule12SiteOfSignificantToManaWhenua":
        "Schedule 12: Site of Significance to Mana Whenua",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule12SiteRuleException":
        "Site Exception Rule",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule14HistoricHeritageProtection":
        "Schedule 14: Historic Heritage Protection",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule6OutstandingNaturalFeature":
        "Schedule 6: Outstanding Natural Feature",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule7OutstandingNaturalLandscape":
        "Schedule 7: Outstanding Natural Landscape",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule9VolcanicViewshaft":
        "Schedule 9: Volcanic Viewshaft",
    "CurrentManagementOfTheMaoriHeritageAsset.schedule11LocalPublicViewshaft":
        "Schedule 11: Local Public Viewshaft",
    "CurrentManagementOfTheMaoriHeritageAsset.hgipAppendix1fMaoriHeritage":
        "HGIP Appendix 1f: Māori heritage",
    "CurrentManagementOfTheMaoriHeritageAsset.notableTreeId": "Notable Tree ID",
    "CurrentManagementOfTheMaoriHeritageAsset.overlaysThatApplys":
        "Overlays That Apply",
    "CurrentManagementOfTheMaoriHeritageAsset.landUses": "Land Use",
    "CurrentManagementOfTheMaoriHeritageAsset.landUseNotes":
        "Land Use Notes for Other",
    "CurrentManagementOfTheMaoriHeritageAsset.landCover": "Land Cover",
    "CurrentManagementOfTheMaoriHeritageAsset.covenant": "Covenant",
    "CurrentManagementOfTheMaoriHeritageAsset.covenantNotes":
        "Covenant Details",
    "CurrentManagementOfTheMaoriHeritageAsset.reserve": "Reserve",
    "CurrentManagementOfTheMaoriHeritageAsset.reserveNotes": "Reserve Details",
    "CurrentManagementOfTheMaoriHeritageAsset.transferOfPowers":
        "Transfer of Powers",
    "CurrentManagementOfTheMaoriHeritageAsset.transferOfPowersNotes":
        "Transfer of Powers Details",
    "CurrentManagementOfTheMaoriHeritageAsset.coManagementGovernanceAgreements":
        "Co-Management / Governance Agreements",
    "CurrentManagementOfTheMaoriHeritageAsset.coManagementGovernanceAgreementsNotes":
        "Co-Management / Governance Agreements Details",
    "CurrentManagementOfTheMaoriHeritageAsset.culturalValuesAssignedB652s":
        "Cultural Values Assigned (B6.5.2)",
    "CurrentManagementOfTheMaoriHeritageAsset.environmentalAndCulturalRisks":
        "Environmental and Cultural Risks",
    "CurrentManagementOfTheMaoriHeritageAsset.environmentalAndCulturalRisksNotes":
        "Environmental and Cultural Risks Notes for Other",
    "CurrentManagementOfTheMaoriHeritageAsset.prohibitedActivitiesAndBehaviour":
        "Prohibited Activities + Behaviour",
    "AssetStatusUpdate.heading": "Asset Status Update",
    "AssetStatusUpdate.assetStatus": "Asset Status",
    "AssetStatusUpdate.threatStatus":
        "Threat Status (Overview assessment of combined threats)",
    "AssetStatusUpdate.scheduleAssessmentTask1SiteAnalysisAndGeomapsSpatialDataPercentage":
        "Schedule Assessment Task 1 (Site analysis & Geomaps spatial data) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask1HistoricalResearchPercentage":
        "Schedule Assessment Task 1 (Historical research) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask1ArchaeologicalResearchPercentage":
        "Schedule Assessment Task 1 (Archaeological research) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask2MataurangaPercentage":
        "Schedule Assessment Task 2 (Matauranga) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask3SiteVisitPercentage":
        "Schedule Assessment Task 3 (site visit) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask4ThreatRiskPercentage":
        "Schedule Assessment Task 4 (threat / risk) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask5WanangaPercentage":
        "Schedule Assessment Task 5 (wananga) % complete",
    "AssetStatusUpdate.scheduleAssessmentTask5ManagementResponsePercentage":
        "Schedule Assessment Task 5 (management response) % complete",
    "AssetStatusUpdate.planningStatus":
        "Planning Status (This is the perceived ease or difficulty in proceeding to a stated management outcome i.e. political or legal barriers)",
    "AssetStatusUpdate.managementRecommendations": "Management Recommendations",
    "AssetStatusUpdate.comments": "Comments",
    "AssetStatusUpdate.lastMWCommsRecord":
        "Last MW Comms Record (date, method, person)",
    "AssetStatusUpdate.lastSiteVisitDate": "Last Site Visit (Date)",
    "AssetStatusUpdate.actionsRequired": "Actions Required",
    "SiteAssessment.Title": "Site Assessment",
    "SiteAssessment.Purpose":
        "The purpose of this web site is for assessing and recording Māori Heritage values and determining the mechanisms for the management and protection of these values.",
    "SiteAssessment.ReferToGuide":
        "It is important to refer to the Māori Cultural Heritage Site Assessment Guide",
    "SiteAssessment.ClickOnNominatedSites":
        "Click on your nominated site/sites below to access:",
    "SiteAssessment.Task1Info":
        "Task 1 - Information gathering - Auckland Council Task",
    "SiteAssessment.Task2Info": "Task 2 - Mātauranga Māori - Mana Whenua Task",
    "SiteAssessment.Task3Info":
        "Task 3 - Landowner Engagement / Site visit – Joint Mana Whenua /Auckland Council Task",
    "SiteAssessment.Task4Info":
        "Task 4 - Threat / Risk Assessment – Mana Whenua Task",
    "SiteAssessment.Task5Info":
        "Task 5 - Planning / Management response – identification - Auckland Council Task",
    "Task1.ResearchByCouncil.SectionTitle": "Research By Council",
    "Task1.ResearchByCouncil.Description.1":
        "Base information for Mana Whenua values assessment. This is the publicly available information collated by the council for your consideration.",
    "Task1.ResearchByCouncil.Description.2":
        "Refer to Task 1 in the Māori Cultural Heritage Site Assessment Guide",

    "Task2.ReviewOfTask1.SectionTitle": "Review of Information in Task 1",
    "Task2.ReviewOfTask1.ReferToGuide":
        "Refer to Task 2.1 in the Māori Cultural Heritage Site Assessment Guide",
    "Task2.ReviewOfTask1.ReviewKnownInformationTitle":
        "Review Known Public / External Information",
    "Task2.ReviewOfTask1.AddFileDescription":
        "Use the 'Add File' button to upload additional known / public information about the site / area that has not been collected by the Council. It is anticipated that this information will remain acceptable for 'Public Access' or 'Council Access' files (as it is largely existing public information).",
    "Task2.ReviewOfTask1.AddFileButton": "Add File",
    "Task2.ReviewOfTask1.StartAssessmentProcessButton":
        "Start Assessment Process",

    "Task2.CollationOfKnowledge.SectionTitle": "Collation of Knowledge",
    "Task2.CollationOfKnowledge.ReferToGuide":
        "Refer to Task 2.2 in the Māori Cultural Heritage Site Assessment Guide",
    "Task2.CollationOfKnowledge.AddFileDescription":
        "Use the  button below to add files on Mana Whenua knowledge associated with the site, area, or feature. It is recognised that this stage of the process is most likely dealing with information that is sensitive and that it may be appropriate for it to be held in 'Council Managed' or 'Silent' files (information where Council manages or restricts public access), and even where such information will be held by Mana Whenua ('Mana Whenua Restricted' files). As such please check the sensitivity button for each file uploaded.",
    "Task2.CollationOfKnowledge.AddFileButton": "Add File",
    "Task2.CollationOfKnowledge.TraditionalKnowledgeComplete":
        "Traditional Knowledge Complete",
    "Task2.CollationOfKnowledge.Public": "Public",
    "Task2.CollationOfKnowledge.PublicDescription":
        "Accessible by the public i.e.. visible on the Unitary Plan Viewer",
    "Task2.CollationOfKnowledge.Restricted": "Restricted",
    "Task2.CollationOfKnowledge.RestrictedDescription":
        "Shared between Mana Whenua and Council",
    "Task2.CollationOfKnowledge.ManaWhenuaOnly": "Mana Whenua Only",
    "Task2.CollationOfKnowledge.ManaWhenuaOnlyDescription":
        "Accessible by Mana Whenua only",

    "Task2.TechnicalSiteAssessment.SectionTitle": "Technical Site Assessment",
    "Task2.TechnicalSiteAssessment.ReferToGuide":
        "Refer to Task 2.3 in the Māori Cultural Heritage Site Assessment Guide",

    "Task2.TechnicalSiteAssessment.Subtitle":
        "Values assessment summary (recorded by Mana Whenua / Researcher)",
    "Task2.TechnicalSiteAssessment.PaupCriteria": "PAUP Criteria (B5.4.3)",

    "Task2.TechnicalSiteAssessment.DescriptionStatementOfValues":
        "Description statement of values associated with site or place",
    "Task2.TechnicalSiteAssessment.DescriptionStatementOfValues.Horopaki.Placeholder":
        `Provide a brief statement of how the Iwi is connected to the site and mana whenua values associated with the broader landscape
Highlight where the site sits within the wider cultural landscape – key connections and linkages`,
    "Task2.TechnicalSiteAssessment.SourceInformationReference":
        "Source Information Reference",
    "Task2.TechnicalSiteAssessment.SourceInformationReference.Placeholder":
        `It is useful to include references to your Source Information where relevant in your description. It is not necessary to include the full reference, just the Author and Date of publication or who/when the material was provided. Use the following format: 
        [description xxx]…(Source Information Title, Date: Page # if applicable).`,
    "Task2.TechnicalSiteAssessment.SensitiveInformationHeld":
        "Sensitive Information Held",
    "Task2.TechnicalSiteAssessment.LastUpdated": "Last Updated",
    "Task2.TechnicalSiteAssessment.CommentsAndFeedback":
        "Comments and Feedback",
    "Task2.TechnicalSiteAssessment.CommentsAndFeedback.Placeholder":
        "Council Comments",
    "Task2.TechnicalSiteAssessment.KōreroTūturu": "Kōrero Tūturu",
    "Task2.TechnicalSiteAssessment.WāhiTapu": "Wāhi Tapu",
    "Task2.TechnicalSiteAssessment.Mauri": "Mauri",
    "Task2.TechnicalSiteAssessment.CustomaryResources":
        "Rawa Tūturu (Customary Resources)",
    "Task2.TechnicalSiteAssessment.CustomaryNeeds":
        "Hiahiatanga Tūturu (Customary Needs)",
    "Task2.TechnicalSiteAssessment.ContemporaryEsteem":
        "Whakaaronui ō te Wā (Contemporary Esteem)",
    "Task2.TechnicalSiteAssessment.SurroundingLandscape":
        "Horopaki (Surrounding Landscape)",
    "Task2.TechnicalSiteAssessment.Yes": "Yes",
    "Task2.TechnicalSiteAssessment.No": "No",

    "Task3.OnTheGroundReview.SectionTitle":
        "On-the-ground review of a site to define the boundaries, collect information and map features and values",
    "Task3.OnTheGroundReview.ReferToGuide":
        "Refer to task 3.2 in the Māori Cultural Heritage Site Assessment Guide - this includes instructions on using the webmap and tablet",
    "Task3.GovernanceCommunication.AddFileButton": "Add File",
    "Task3.OnTheGroundReview.PurposeHeader": "Purpose",
    "Task3.OnTheGroundReview.PurposeDescription":
        "To define the geographic extent of Māori cultural values and associations and the boundaries appropriate for mapping (e.g. in the Unitary Plan) It is expected that most site assessments will require a site visit. This might be part of the research process to confirm the values of a site and how they translate into boundaries or extents.",
    "Task3.OnTheGroundReview.ProcessHeader":
        "Process for preparation and completion of the site visit",
    "Task3.OnTheGroundReview.ProcessDescription.1":
        "Desktop evaluation to define the geographic extent of the site and associations/context using the 'web map'.",
    "Task3.OnTheGroundReview.ProcessDescription.2":
        "Site visit - ground truth the geographic extent of the site, identify and mark specific points within the site on the map and collect video/photos/audio data using the tablets provided (see instructions on task 3 within the guidebook - the webmap also has a drawing help tab for reference).",
    "Task3.OnTheGroundReview.ProcessDescription.3":
        "Finalise and review the site visit edits using the ‘web map’ on your laptop or desktop computer.",

    "Task3.GovernanceCommunication.SectionTitle":
        "Landowner/Iwi/Council Governance Communication",

    "Task3.LandownerEngagementBlurb.SectionTitle": "Landowner Engagement",
    "Task3.LandownerEngagementBlurb.ReferToGuide":
        "Refer to Task 3.1 in the Māori Cultural Heritage Site Assessment Guide",
    "Task3.LandownerEngagementBlurb.PurposeHeader": "Purpose",
    "Task3.LandownerEngagementBlurb.PurposeDescription":
        "The purpose of Task 3 is to assist the Council to appropriately contact and liaise with private landowners to arrange a site visit in support of the assessment of Māori cultural heritage and the management response required.",
    "Task3.LandownerEngagementBlurb.ListHeading":
        "The following provides a guide to the process the Council will undertake:",
    "Task3.LandownerEngagementBlurb.List1":
        "Confirm land owners and occupiers for a site",
    "Task3.LandownerEngagementBlurb.List2":
        "Provide guidance on a site visit approach including through initial liaison with Mana Whenua",
    "Task3.LandownerEngagementBlurb.List3":
        "Identify any specific requirements for Landowners / details identified by landowners - to view this document please click on the link below",
    "Task3.LandownerEngagementBlurb.List4":
        "Draft letter (including information on)",
    "Task3.LandownerEngagementBlurb.List4a": "Attendees",
    "Task3.LandownerEngagementBlurb.List4b": "Date and location",
    "Task3.LandownerEngagementBlurb.List4c":
        "Information on potential values associated with the site / area",
    "Task3.LandownerEngagementBlurb.List4d":
        "Explanation of the process and purpose of the Site Visit and potential outcomes of the assessment",
    "Task3.LandownerEngagementBlurb.List4e":
        "Confirmation of how they will be involved in the process",
    "Task3.LandownerEngagementBlurb.List5":
        "Follow up communication / contact to confirm site visit arrangements",
    "Task3.LandownerEngagementBlurb.List6":
        "Communication with Mana Whenua and landowners following the site visit",

    "Task3.ConfirmLandowners.SectionTitle": "Confirm Landowners",
    "Task3.ConfirmLandowners.Description":
        "Open the 'Confirm Properties' map below and provide feedback on whether the correct properties are identified for undertaking a site visit. You can also upload a 'marked up' version of the map using the Add File button.",
    "Task3.ConfirmLandowners.LandownerFeedback": "Landowner Feedback",
    "Task3.ConfirmLandowners.ExistingRelationship": "Existing Relationship",
    "Task3.ConfirmLandowners.PropertyAccess": "Property Access",

    "Task3.SiteVisitRecord.SectionTitle": "Site Visit Record",
    "Task3.SiteVisitRecord.GenerateTaskSummaryReport":
        "Generate Task Summary Report",
    "Task3.SiteVisitRecord.oralEvidence": "Oral Evidence",
    "Task3.SiteVisitRecord.natureOfDamage":
        "What is the nature of any damage to the site",
    "Task3.SiteVisitRecord.natureOfDamage.Placeholder":
        "N/A, Integrity - intact, Altered, Reversible",
    "Task3.SiteVisitRecord.currentManagementAppropriateness":
        "Are the current management techniques appropriate",
    "Task3.SiteVisitRecord.inappropriateActivities":
        "What activities are inappropriate for the area",
    "Task3.SiteVisitRecord.recommendedManagementTechniques":
        "Management techniques recommended by Iwi representatives",
    "Task3.SiteVisitRecord.audioVideoRecord":
        "Photographic / Video / Audio Record",
    "Task3.SiteVisitRecord.audioVideoRecord.Placeholder":
        "Describe locations where photos/videos/audio are taken. Attach photos/video/audio in the website.",
    "Task3.SiteVisitRecord.siteVisitDate": "Site Visit Date",
    "Task3.SiteVisitRecord.isExistingScheduleInformationConsistent":
        "Name and location of the site / area consistent with existing schedule information",
    "Task3.SiteVisitRecord.isLandmark": "Views or vistas to the item / area",
    "Task3.SiteVisitRecord.isInCoastalMarineArea":
        "Item / area within the Coastal Marine Area (CMA)",
    "Task3.SiteVisitRecord.isInMeanHighWaterSpring":
        "Item surrounds extend into the Mean High Water Spring (MHWS)",
    "Task3.SiteVisitRecord.wasGpsRecorded": "GPS Record",
    "Task3.SiteVisitRecord.siteCondition": "What is the condition of the site",
    "Task3.SiteVisitRecord.Contributors": "Contributors",
    "Task3.SiteVisitRecord.contributors.manaWhenua": "Mana Whenua",
    "Task3.SiteVisitRecord.contributors.planner": "Planner",
    "Task3.SiteVisitRecord.contributors.researcher": "Researcher",
    "Task3.SiteVisitRecord.contributors.archaeologist": "Archaeologist",
    "Task3.SiteVisitRecord.contributors.historian": "Historian",
    "Task3.SiteVisitRecord.contributors.parks": "Parks",
    "Task3.SiteVisitRecord.contributors.property": "Property",
    "Task3.SiteVisitRecord.contributors.consultants": "Consultants",
    "Task3.SiteVisitRecord.contributors.landowners": "Landowners",
    "Task3.SiteVisitRecord.SiteInfo.List1":
        "Important site information (Refer to Task 1 to complete this section)",
    "Task3.SiteVisitRecord.SiteInfo.List2":
        "Use the checklist to guide the process for your site visit",
    "Task3.SiteVisitRecord.SiteInfo.List3":
        "The Auckland Council Māori Heritage Team Planners will assist you to complete",
    "Task4.SectionTitle": "Threat / Risk Assessment",
    "Task4.ReferToSection":
        "Refer to Task 4 in the Māori Cultural Heritage Site Assessment Guide",
    "Task4.harbourHealthActivities":
        "Activities that could potentially effect water / stream and harbour health",
    "Task4.landuseActivities":
        "Land use activities (including but not limited to)",

    "Task4.ExistingImpacts.SectionTitle": "Existing Impacts",
    "Task4.ExistingImpacts.Description":
        "Please identify any existing activities that are or may be adversely impacting on cultural values relevant to the site area.",
    "Task4.ExistingImpacts.ScaleDescription":
        "Scale of existing Impact: 1 (low impact) - 5 (High Impact)",
    "Task4.ExistingImpacts.earthworks":
        "Earthworks - potentially movement of soil from one location to another",
    "Task4.ExistingImpacts.placementOfUtilities": "Placement of utilities",
    "Task4.ExistingImpacts.stormwaterManagement":
        "Stormwater management - quality and flow",
    "Task4.ExistingImpacts.onSiteWastewater":
        "On-site wastewater and existing wastewater networks",
    "Task4.ExistingImpacts.otherDischarges": "Other discharges",
    "Task4.ExistingImpacts.groundwaterTake": "Allocation of groundwater take",
    "Task4.ExistingImpacts.dammingAndDiversionOfWater":
        "Taking, using, damming and diversion of water and drilling",
    "Task4.ExistingImpacts.roadRunoff": "Road Runoff (soils and fluids)",
    "Task4.ExistingImpacts.industrialDischarges":
        "Industrial discharges and spills",
    "Task4.ExistingImpacts.litter": "Litter",
    "Task4.ExistingImpacts.wastewaterOverflows": "Wastewater overflows",
    "Task4.ExistingImpacts.landfills":
        "Cleanfills, managed fills and landfills",
    "Task4.ExistingImpacts.changesToLakesRiversAndWetland":
        "Changes to lakes, rivers, streams and wetland management (potentially reduced access and loss of riparian margin / reduction in riparian health)",
    "Task4.ExistingImpacts.changesToCostalArea":
        "Changes to the general coastal marine area",
    "Task4.ExistingImpacts.vegetationRemoval":
        "Vegetation removal and disturbance",
    "Task4.ExistingImpacts.subdivision": "Subdivision",
    "Task4.ExistingImpacts.newBuilding": "Any new building",
    "Task4.ExistingImpacts.alterationsToExistingBuildings":
        "Alterations to existing buildings",
    "Task4.ExistingImpacts.constructionOfResidentialUnits":
        "Construction of residential units",
    "Task4.ExistingImpacts.schoolsHomesRetirementVillages":
        "Care centres, schools, rest homes / retirement villages",
    "Task4.ExistingImpacts.healthcareServices": "Healthcare services",
    "Task4.ExistingImpacts.campingGrounds": "Camping grounds",
    "Task4.ExistingImpacts.communityFacilities": "Community facilities",
    "Task4.ExistingImpacts.cafesRestaurants": "Cafes / restaurants",
    "Task4.ExistingImpacts.horticulture": "Horticulture",
    "Task4.ExistingImpacts.serviceStations": "Service stations",
    "Task4.ExistingImpacts.AdditionalActivity": "Additional Activity",
    "Task4.ExistingImpacts.AdditionalActivityDescription": "Description",
    "Task4.ExistingImpacts.ListOther": "List any other impacts that apply",

    "Task4.FutureThreats.SectionTitle": "Future Threats",
    "Task4.FutureThreats.Description":
        "Please identify possible future threats that could adversely impact on Mana Whenua values relevant to the site. For example if there was a change in land uses or similar.",
    "Task4.FutureThreats.ScaleDescription":
        "Scale of existing Impact: 1 (low impact) - 5 (High Impact)",
    "Task4.FutureThreats.earthworks":
        "Earthworks - potentially movement of soil from one location to another",
    "Task4.FutureThreats.placementOfUtilities": "Placement of utilities",
    "Task4.FutureThreats.stormwaterManagement":
        "Placement of new utilities or expansion of existing utilities",
    "Task4.FutureThreats.onSiteWastewater": "On-site wastewater ",
    "Task4.FutureThreats.otherDischarges": "Other discharges",
    "Task4.FutureThreats.groundwaterTake": "Allocation of groundwater take",
    "Task4.FutureThreats.dammingAndDiversionOfWater":
        "Taking, using, damming and diversion of water and drilling",
    "Task4.FutureThreats.roadRunoff": "Road Runoff (soils and fluids)",
    "Task4.FutureThreats.industrialDischarges":
        "Industrial discharges and spills",
    "Task4.FutureThreats.litter": "Litter",
    "Task4.FutureThreats.wastewaterOverflows": "Wastewater overflows",
    "Task4.FutureThreats.landfills": "Cleanfills, managed fills and landfills",
    "Task4.FutureThreats.changesToLakesRiversAndWetland":
        "Changes to lakes, rivers, streams and wetland management (potentially reduced access and loss of riparian margin / reduction in riparian health)",
    "Task4.FutureThreats.changesToCostalArea":
        "Changes to the general coastal marine area",
    "Task4.FutureThreats.vegetationRemoval":
        "Vegetation removal and disturbance",
    "Task4.FutureThreats.subdivision": "Subdivision",
    "Task4.FutureThreats.newBuilding": "Any new building",
    "Task4.FutureThreats.alterationsToExistingBuildings":
        "Alterations to existing buildings",
    "Task4.FutureThreats.constructionOfResidentialUnits":
        "Construction of residential units",
    "Task4.FutureThreats.schoolsHomesRetirementVillages":
        "Care centres, schools, rest homes / retirement villages",
    "Task4.FutureThreats.healthcareServices": "Healthcare services",
    "Task4.FutureThreats.campingGrounds": "Camping grounds",
    "Task4.FutureThreats.communityFacilities": "Community facilities",
    "Task4.FutureThreats.cafesRestaurants": "Cafes / restaurants",
    "Task4.FutureThreats.horticulture": "Horticulture",
    "Task4.FutureThreats.serviceStations": "Service stations",
    "Task4.FutureThreats.wastewaterNetworks": "Wastewater networks",
    "Task4.FutureThreats.waterQualityStandards": "Water quality standards",
    "Task4.FutureThreats.intensificationOfHousing":
        "Intensification of existing housing",
    "Task4.FutureThreats.AdditionalActivity": "Additional Activity",
    "Task4.FutureThreats.AdditionalActivityDescription": "Description",
    "Task4.FutureThreats.ListOther":
        "List any other potential impacts that apply",
    "Task4.SiteAnalysisReport.NoReportWarning": "A report for the site has not been generated, the next report will be generated on {0}",

    "Task5.ManagementResponse.SectionTitle": "Management Response",
    "Task5.ManagementResponse.scheduling": "Scheduling",
    "Task5.ManagementResponse.summary": "Other",
    "Task5.ManagementResponse.naming": "Naming (Interpretation & Signage)",
    "Task5.ManagementResponse.activeProtectionLayerAlertLayer":
        "Māori Heritage Alert Layer",
    "Task5.ManagementResponse.iwiManagementPlanSchedule":
        "Iwi Management Plan Schedule",
    "Task5.ManagementResponse.hnzptListing":
        "Heritage New Zealand Pouhere Taonga List",
    "Task5.ManagementResponse.landAcquisition": "Land Acquisition",
    "Task5.ManagementResponse.transferOfPowers": "Transfer of Powers",
    "Task5.ManagementResponse.heritageOrders": "Heritage Orders",
    "Task5.ManagementResponse.jointManagementAgreement":
        "Joint Management Agreement",
    "Task5.ManagementResponse.coManagementGovernance":
        "Co-management/Co-governance",
    "Task5.ManagementResponse.covenant": "Covenant",
    "Task5.ManagementResponse.reserve": "Reserve",
    "Task5.ManagementResponse.managementPlan":
        "Conservation/Reserve Management Plan",
    "Task5.ManagementResponse.precinct": "Precinct",
    "Task5.ManagementResponse.schedule6":
        "Schedule 6 (Outstanding Natural Feature: criteria k)",
    "Task5.ManagementResponse.schedule7":
        "Schedule 7 (Outstanding Natural Landscape: criteria g)",
    "Task5.ManagementResponse.schedule9":
        "Schedule 9 (Volcanic Viewshafts: criteria d)",
    "Task5.ManagementResponse.schedule11":
        "Schedule 11 (Local Viewshafts: criteria d)",
    "Task5.ManagementResponse.schedule12":
        "Schedule 12 (Sites and Places of Significance to Mana Whenua)",
    "Task5.ManagementResponse.schedule14":
        "Schedule 14 (Historic Heritage: criteria c)",

    "Task5.ManagementOutcomes.SectionTitle": "Management Outcomes / Extents",
    "Task5.ManagementOutcomes.ManagementStatement": "Management Statement",
    "Task5.ManagementOutcomes.SupportingDocuments":
        "Supporting Documents / Agreements",
    "Task5.ManagementOutcomes.SupportingDocumentsExample":
        "E.g. Covenant agreement",
    "Task5.ManagementOutcomes.AddFileButton": "Add File",

    "Task5.ResponseIdentification.SectionTitle":
        "Planning / Management Response - Identification - Auckland Council",
    "Task5.ResponseIdentification.AddFileButton": "Add File",
    "Task5.ResponseIdentification.ReferToGuide":
        "Refer to Task 5 in the Māori Cultural Heritage Site Assessment Guide",
    "Task5.ResponseIdentification.PurposeTitle": "Purpose",
    "Task5.ResponseIdentification.PurposeDescription":
        "Assessment of the information to complete the 'section 32' of information to recommend whether the site, feature or area should be included a Unitary Plan Schedule (or whether other management and/or protection options are more appropriate).",
    "Task5.ResponseIdentification.OverviewOfProcessTitle":
        "Overview of this process",
    "Task5.ResponseIdentification.OverviewOfProcessDescription1":
        "Planner review",
    "Task5.ResponseIdentification.OverviewOfProcessDescription2":
        "Draft recommendations report",
    "Task5.ResponseIdentification.OverviewOfProcessDescription3":
        "Mana Whenua review of information (this will be all Mana Whenua who have provided information on that site)",
    "Task5.ResponseIdentification.OverviewOfProcessDescription4":
        "Landowner engagement on options",
    "Task5.ResponseIdentification.OverviewOfProcessDescription5":
        "Finalisation of recommendations (Planning and Mana Whenua)",
    "Task5.ResponseIdentification.OverviewOfProcessDescription6":
        "Preparation of supporting 'section 32' documentation",
    "Task5.ResponseIdentification.PlanningRecommendationTitle":
        "Planning Recommendation",
    "Task5.ResponseIdentification.PlanningRecommendationDescription":
        "The planning recommendation will be uploaded here once completed",

    "ProgressInput.governanceNotified": "Governance Notified",
    "ProgressInput.propertiesIdentified": "Propertied Identified",
    "ProgressInput.landownerAccessSought": "Landowner Access Sought",
    "ProgressInput.landownerApprovalReceived": "Landowner Approval Received",
    "ProgressInput.feedbackToLandownerGovernance":
        "Feedback to Landowner Governance",
    "ProgressInput.siteVisitComplete": "Site Visit Complete",
    "ProgressInput.managementConfirmed": "Management Response Confirmed",
    "ProgressInput.assessmentProcessStarted": "Start Assessment Process",
    "ProgressInput.traditionalKnowledgeComplete":
        "Traditional Knowledge Complete",
    "ProgressInput.technicalSiteAssessmentComplete":
        "Technical Site Assessment Complete",
    "ProgressInput.threatIssuesComplete": "Threat Issues Complete",

    "ProgressOutput.Completed": "Completed",
    "ProgressOutput.Task1": "Task 1",
    "ProgressOutput.Task2Part2x2": "Task 2 (Part 2.2)",
    "ProgressOutput.Task2Part2x3": "Task 2 (Part 2.3)",
    "ProgressOutput.Task3": "Task 3",
    "ProgressOutput.Task4": "Task 4",
    "ProgressOutput.Task5": "Task 5",
    "ProgressOutput.GovernanceNotifiedIwiCouncil":
        "Governance Notified (Iwi/Council)",
    "ProgressOutput.PropertiesIdentified": "Properties Identified",
    "ProgressOutput.LandownerAccessSought": "Landowner Access Sought",
    "ProgressOutput.LandownerApprovalReceived": "Landowner Approval Received",
    "ProgressOutput.SiteVisitCompleted": "Site Visit Completed",
    "ProgressOutput.FeedbacktoLandownerGovernance":
        "Feedback to Landowner Governance",

    "Users.AddUser": "Add User",

    "NominatedSite.TabInformation":
        "Click on the tabs below to access information and add files on your nominated site.",

    "Generic.Save": "Save",
    "Generic.Cancel": "Cancel",
    "Generic.FileSizeLimitWarning": "File size must be less than 30MB.",
    "Generic.FileRestrictionWarning": "File size should be less than 200MB",
    "EditUser.ResendInvitation": "Resend Invitation",
    "EditUser.Header": "Edit User",
    "EditUser.EmailAddress": "Email Address",
    "EditUser.Name": "Name",
    "EditUser.Iwi": "Iwi",
    "EditUser.Roles": "Roles",
    "EditUser.Disabled": "Disabled",
    "EditUser.EmailConfirmed": "Email Confirmed",
    "EditUser.InvitationSent": "Invitation Sent",
    "EditUser.InviteFailed": "Invite Failed",
    "EditUser.UndefinedUser": "User is undefined, cannot submit the request",
    "EditUser.ChangesSaved": "Your changes have been saved.",
};
