export function ChangePassword() {
    window.location.replace(
        `${process.env.REACT_APP_API_HOST}/Identity/Account/Manage/ChangePassword/`
    );

    return (
        <>
            <h2>Change Password</h2>
        </>
    );
}
