import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { NominatedSiteTabs } from "components/nominatedSiteTabs";
import { NominatedSiteProgress } from "components/progressCard/NominatedSiteProgress";
import { CompletedDatesProvider } from "context/completedDatesContext";
import { MapUrlProvider } from "context/mapUrlContext";
import { useApiFetch } from "hooks/useApiFetch";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import {
    DefaultNominatedSiteState,
    NominatedSiteState,
} from "types/NominatedSiteState";

const section: string = "NominatedSite";

export function NominatedSite() {
    const { apiGet } = useApiFetch();
    const params = useParams();

    const [state, setState] = useState<NominatedSiteState>(
        DefaultNominatedSiteState
    );

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        apiGet({
            path: `NominatedSite/Get?id=${params.id}`,
            onSuccess: (data) => {
                setState(data);
                setLoading(false);
            },
            onError: () => setLoading(false),
        });
    }, []);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "inherit",
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <Stack spacing={3} margin={1} height="100%">
                <Typography variant="h5">{`${state.mhid} - ${state.assetName}`}</Typography>
                <Typography variant="body1">
                    <FormattedMessage id={`${section}.TabInformation`} />
                </Typography>
                <CompletedDatesProvider>
                    <MapUrlProvider>
                        <NominatedSiteProgress showText={true} />

                        <NominatedSiteTabs
                            nominatedSiteState={state}
                            setNominatedSiteState={setState}
                        />
                    </MapUrlProvider>
                </CompletedDatesProvider>
            </Stack>
        );
    }
}
