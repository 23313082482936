import { Alert, AlertProps, Snackbar } from "@mui/material";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    DefaultExistingImpacts,
    ExistingImpactsState,
} from "types/ExistingImpacts";

interface ExistingImpactsContextState {
    existingImpacts?: ExistingImpactsState;
    setExistingImpacts?: (value: ExistingImpactsState) => void;
}

const contextDefaultValues: ExistingImpactsContextState = {
    existingImpacts: DefaultExistingImpacts,
};

export const ExistingImpactsContext =
    createContext<ExistingImpactsContextState>(contextDefaultValues);

export function ExistingImpactsProvider({ children }: { children: ReactNode }) {
    const [existingImpacts, setExistingImpacts] =
        useState<ExistingImpactsState>();

    const { apiGet } = useApiFetch();
    const { id } = useParams();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        apiGet({
            path: `NominatedSite/GetExistingImpacts?nominatedSiteId=${id}`,
            onSuccess: (data) => {
                setExistingImpacts({
                    ...DefaultExistingImpacts,
                    ...data,
                    customRatings: data.customRatings || [],
                });
            },
            onError: (error: FetchError) => {
                setSnackbar({ children: error.message, severity: "error" });
            },
        });
    }, []);

    return (
        <ExistingImpactsContext.Provider
            value={{ existingImpacts, setExistingImpacts }}
        >
            {children}
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </ExistingImpactsContext.Provider>
    );
}
