import { CouncilMapUrlContext } from "context/councilMapUrlContext";
import { CouncilTokenContext } from "context/councilTokenContext";
import { CurrentUserContext } from "context/currentUserContext";
import { RoleContext } from "context/roleContext";
import { useContext, useEffect, useState } from "react";

export function useCouncilMapUrl() {
    const [councilMapUrl, setCouncilMapUrl] = useState<string>("");

    const councilMapUrlContext = useContext(CouncilMapUrlContext);
    const roleContext = useContext(RoleContext);
    const currentUserContext = useContext(CurrentUserContext);
    const councilTokenContext = useContext(CouncilTokenContext);

    useEffect(() => {
        if (!roleContext.roles || !currentUserContext.currentUser) return;

        if (
            roleContext.roles.isSysAdmin ||
            roleContext.roles.isCouncilUser
        ) {
            councilMapUrlContext?.fetch && councilMapUrlContext.fetch();
        }
    }, [roleContext.roles, currentUserContext.currentUser?.iwiId]);

    useEffect(() => {
        setCouncilMapUrl(
            councilMapUrlContext.councilMapDetails
                ? councilMapUrlContext.councilMapDetails?.url
                : ""
        );
    }, [councilMapUrlContext.councilMapDetails]);

    return councilMapUrl;
}
