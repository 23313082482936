import SaveIcon from "@mui/icons-material/Save";
import { Alert, AlertProps, Fab, Grid, Snackbar } from "@mui/material";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { RoleContext } from "context/roleContext";
import { useApiFetch } from "hooks/useApiFetch";
import { ManagementOutcomes } from "pages/forms/ManagementOutcomes";
import { ManagementResponseForm } from "pages/forms/ManagementResponse";
import { ResponseIdentification } from "pages/forms/ResponseIdentification";
import { UndeleteFilesCard } from "pages/UndeleteFiles";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompletedDates } from "types/CompletedDates";
import { EditDateCompleted } from "types/EditDateCompleted";
import {
    DefaultManagementResponseType,
    ManagementResponseType,
} from "types/ManagementResponse";

interface ManagementResponseProps {
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

export function ManagementResponse(props: ManagementResponseProps) {
    const [managementResponseState, setManagementResponseState] =
        useState<ManagementResponseType>(DefaultManagementResponseType);

    const [loadData, setLoadData] = useState(true);
    const { apiGet, apiPost } = useApiFetch();
    const { id } = useParams();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const roleContext = useContext(RoleContext);
    const isCouncilOrAdmin: boolean = roleContext.isCouncilOrAdmin;

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            getSiteVisitRecord({
                onSuccess: (data: ManagementResponseProps) => {
                    setManagementResponseState({
                        ...DefaultManagementResponseType,
                        ...data,
                    });
                },
                onError: handleFetchError,
            });
        }
    }, [loadData]);

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item md={6}>
                    <ManagementResponseForm
                        state={managementResponseState}
                        setState={setManagementResponseState}
                    />
                </Grid>
                <Grid item md={6}>
                    <ManagementOutcomes />
                    <Grid item md={12}>
                        <UndeleteFilesCard />
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <ResponseIdentification
                        dateCompleted={
                            props.completedDates?.managementConfirmed
                        }
                        progressOnClick={props.progressOnClick}
                    />
                </Grid>
                {isCouncilOrAdmin && (
                    <Fab
                        variant="extended"
                        color="secondary"
                        aria-label="save"
                        sx={{ position: "fixed", bottom: "16px" }}
                        onClick={saveForm}
                    >
                        <SaveIcon sx={{ mr: 1 }} /> Save Task 5
                    </Fab>
                )}
            </Grid>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function getSiteVisitRecord({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `NominatedSite/GetManagementResponse?nominatedSiteId=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function handleFetchError(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function onSaveSuccess(message: string) {
        setSnackbar({
            children: message,
            severity: "success",
        });
        setLoadData(true);
    }

    function saveForm() {
        apiPost({
            path: `NominatedSite/UpdateManagementResponse?nominatedSiteId=${id}`,
            body: managementResponseState,
            onSuccess: (data) =>
                onSaveSuccess("Management Response form successfully saved"),
            onError: (error) => handleFetchError(error),
        });
    }
}
