import { DefaultThreatAssessment, ThreatAssessment } from "./ThreatAssessment";

export type FutureThreatsState = ThreatAssessment & {
    wastewaterNetworks?: number | null;
    waterQualityStandards?: number | null;
    intensificationOfHousing?: number | null;
};

export const DefaultFutureThreats: FutureThreatsState = Object.assign(
    DefaultThreatAssessment,
    {
        wastewaterNetworks: null,
        waterQualityStandards: null,
        intensificationOfHousing: null,
    }
);
