import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Option } from '../types/Option';

export type DropDownProps = {
    modelKey: string,
    translatePrefix?: string
    options: Option[],
    values?: string[] | string | number,
    multiple?: boolean,
    titleKey?: string,
    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string[]>, property: string): void
}

export function DropDown(props: DropDownProps) {
    const titleKey = props.titleKey || props.modelKey;
    const selectedValues = getSelectedValues(props.values);

    return (
        <FormControl fullWidth>
            <InputLabel><FormattedMessage id={`${props.translatePrefix}.${titleKey}`} /></InputLabel>
            <Select
                multiple={props.multiple}
                value={selectedValues}
                renderValue={renderSelectedValues}
                onChange={(e) => props.onChange(e, props.modelKey)}
                label={<FormattedMessage id={`${props.translatePrefix}.${titleKey}`} />}>
                {props.options.map((option: Option) =>
                    <MenuItem key={option.id} value={option.id.toString()}>
                        {props.multiple && <Checkbox checked={selectedValues.indexOf(option.id) > -1} />}

                        {option.name}

                    </MenuItem>)
                }
            </Select>
        </FormControl>
    );

    function renderSelectedValues(selected: string | string[]) {
        if (typeof selected == "string")
            return props.options.find((option) => option.id === (selected))?.name;
        return selected
            .map((select) => props.options.find((option) => option.id == select)?.name)
            .join(',');
    }

    function getSelectedValues(rawValues?: string[] | string | number) {
        switch (typeof rawValues) {
            case 'string':
                return rawValues.split(',');
            case 'number':
                return [`${rawValues}`]
            default:
                return rawValues ?? [];
        }
    }
}