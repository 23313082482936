import { CurrentUserContext } from "context/currentUserContext";
import { IwiContext } from "context/iwiContext";
import { IwiListContext } from "context/iwiListContext";
import { RoleContext } from "context/roleContext";
import { useContext, useEffect, useState } from "react";
import { Iwi } from "types/Iwi";

export function useIwiList() {
    const [iwiList, setIwiList] = useState<Iwi[]>([]);

    const roleContext = useContext(RoleContext);
    const iwiListContext = useContext(IwiListContext);
    const iwiContext = useContext(IwiContext);
    const currentUserContext = useContext(CurrentUserContext);

    useEffect(() => {
        if (!roleContext.roles || !currentUserContext.currentUser) {
            setIwiList([]);
        } else if (
            roleContext.roles.isSysAdmin ||
            roleContext.roles.isCouncilUser
        ) {
            iwiListContext?.fetch && iwiListContext.fetch();
        } else if (roleContext.roles.isIwiUser) {
            iwiContext?.fetch &&
                iwiContext.fetch(currentUserContext.currentUser?.iwiId);
        }
    }, [roleContext.roles, currentUserContext.currentUser?.iwiId]);

    useEffect(() => {
        if (!roleContext.roles) {
            setIwiList([]);
        } else if (
            roleContext.roles.isSysAdmin ||
            roleContext.roles.isCouncilUser
        ) {
            iwiListContext.iwiList && setIwiList(iwiListContext.iwiList);
        } else if (roleContext.roles.isIwiUser) {
            iwiContext?.iwi &&
                setIwiList([
                    {
                        id: iwiContext.iwi.id,
                        name: iwiContext.iwi.name,
                    },
                ]);
        }
    }, [iwiContext.iwi, iwiListContext.iwiList]);

    return iwiList;
}
