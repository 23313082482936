export type SiteInformationSummary = {
    sitesOfSignificance: string;
    physicalDescription: string,
    screeningValueMauri: boolean;
    screeningValueWahiTapu: boolean;
    screeningValueHistorical: boolean;
    screeningValueCustomaryResources: boolean;
    screeningValueCustomaryNeeds: boolean;
    screeningValueContemporaryEsteem: boolean;
    chiFileReferences: string;
    otherPhysicalFileReferences: string;
    physicalOwner: string;
    leasees: string;
    currentLandUse: string;
    previousProtectionStatus: PreviousProtectionStatus;
};

export type PreviousProtectionStatus = {
    legacyPlan: string;
    legacyPlanItemNumber: string;
    legacyPlanMapReference: string;
    existingProtectionReview: string;
};

export const DefaultPreviousProtectionStatus: PreviousProtectionStatus = {
    legacyPlan: "",
    legacyPlanItemNumber: "",
    legacyPlanMapReference: "",
    existingProtectionReview: "",
};

export const DefaultSiteInformationSummary: SiteInformationSummary = {
    sitesOfSignificance: "",
    physicalDescription: "",
    screeningValueMauri: false,
    screeningValueWahiTapu: false,
    screeningValueHistorical: false,
    screeningValueCustomaryResources: false,
    screeningValueCustomaryNeeds: false,
    screeningValueContemporaryEsteem: false,
    chiFileReferences: "",
    otherPhysicalFileReferences: "",
    physicalOwner: "",
    leasees: "",
    currentLandUse: "",
    previousProtectionStatus: DefaultPreviousProtectionStatus
};


