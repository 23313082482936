import {
    Alert,
    AlertProps,
    ButtonProps,
    Link as MaterialLink,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Snackbar,
    Stack,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import {
    DataGridPremium,
    GridColumnHeaderParams,
    GridColumns,
    GridExportMenuItemProps,
    GridPinnedColumns,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    useGridApiContext,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
    AutoCompleteDataGrid,
    AutoCompleteDataGridProps,
} from "components/DataGrid/AutoCompleteDataGrid";
import { DataGridHeaderCell } from "components/DataGrid/DataGridHeaderCell";
import { StyledDataGridBox } from "components/DataGrid/StyledDataGridBox";
import { TrancheLegend } from "components/DataGrid/TrancheLegend";
import { NominatedSiteProgress } from "components/progressCard/NominatedSiteProgress";
import { RefreshFromGisContext } from "context/refreshFromGisContext";
import { RoleContext } from "context/roleContext";
import { Worksheet } from "exceljs";
import { useIwiList } from "hooks/useIwiList";

import {
    MutableRefObject,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import {
    FetchError,
    FetchParams,
} from "../components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "../hooks/useApiFetch";
import { DuplicatedSiteListItem, KeyAssetFeatureListItem, NominatedSite } from "../types/NominatedSite";

type TableDataState = {
    isLoading: boolean;
    sites?: NominatedSite[];
};

export function SiteAssessment() {
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const iwiList = useIwiList();
    const roleContext = useContext(RoleContext);
    const refreshFromGisContext = useContext(RefreshFromGisContext);
    const { apiGet, apiPost } = useApiFetch();
    const { iwiId } = useParams();
    const intl: IntlShape = useIntl();
    const pinnedColumns: GridPinnedColumns = {
        left: ["mhidNumber", "link", "iwiName"],
    };
    const titlePrefix = useMemo(() => {
        const iwi = iwiList?.find((x) => x.id == Number(iwiId));
        return iwi ? `${iwi?.name} - ` : "";
    }, [iwiId, iwiList]);

    useEffect(() => {
        if (!refreshFromGisContext.isLoading) {
            getNominatedSites({ onSuccess: onGetSuccess, onError: onGetError });
        }
    }, [refreshFromGisContext.isLoading, iwiId]);

    const [tableData, setTableData] = useState<TableDataState>({
        isLoading: true,
        sites: undefined,
    });

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    return (
        <>
            <Stack spacing={1} margin={1}>
                <Typography variant="h4">
                    {titlePrefix}{" "}
                    <FormattedMessage id={"SiteAssessment.Title"} />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage id={"SiteAssessment.Purpose"} />
                </Typography>
                <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <FormattedMessage id={"SiteAssessment.ReferToGuide"} />
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    <FormattedMessage
                        id={"SiteAssessment.ClickOnNominatedSites"}
                    />
                </Typography>
                <Box>
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({
                                    id: `SiteAssessment.Task1Info`,
                                })}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({
                                    id: `SiteAssessment.Task2Info`,
                                })}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({
                                    id: `SiteAssessment.Task3Info`,
                                })}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({
                                    id: `SiteAssessment.Task4Info`,
                                })}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({
                                    id: `SiteAssessment.Task5Info`,
                                })}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Stack>
            <Stack spacing={1} margin={1} height={"100%"}>
                <TrancheLegend />

                {tableData.sites && (
                    <StyledDataGridBox theme={theme}>
                        <DataGridPremium
                            experimentalFeatures={{ newEditingApi: true }}
                            rows={tableData.sites}
                            columns={columns()}
                            disableSelectionOnClick
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleErrorRowUpdate}
                            apiRef={apiRef}
                            pinnedColumns={pinnedColumns}
                            getRowClassName={(params) =>
                                `datagrid-row-tranche-${params.row.tranche}`
                            }
                            density="standard"
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar: {
                                    setSnackbarError: (error: string) =>
                                        onExportError(error),
                                },
                            }}
                            headerHeight={100}
                            rowHeight={100}
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitleContainerContent, .MuiDataGrid-cell--withRenderer>a, .MuiDataGrid-cell>div': {
                                    lineHeight: "20px",
                                    maxHeight: "none",
                                    overflow: "hidden",
                                    whiteSpace: "normal"
                                }
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "mhidNumber", sort: "asc" }]
                                }
                            }}
                        />
                    </StyledDataGridBox>
                )}
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </Stack>
        </>
    );

    function onExportError(error: string) {
        setSnackbar({
            children: error,
            severity: "error",
        });
    }

    function getNominatedSites({ onSuccess, onError }: FetchParams) {
        let path = `NominatedSite/List`;
        if (iwiId) {
            path = `${path}?iwiId=${iwiId}`;
        }
        apiGet({
            path,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function onGetSuccess(data: NominatedSite[]) {
        setTableData({ isLoading: false, sites: data });
    }

    function onGetError(error: FetchError) {
        setSnackbar({
            children: `Could not fetch sites. ${error.message}`,
            severity: "error",
        });
    }

    function handleSuccessfulRowUpdate() {
        setSnackbar({
            children: "Row successfully saved",
            severity: "success",
        });
    }

    function handleErrorRowUpdate(error: FetchError) {
        setSnackbar({ children: error.message, severity: "error" });
    }

    function updateRowApiCall(
        newRow: NominatedSite,
        { onSuccess, onError }: FetchParams
    ) {
        apiPost({
            path: `NominatedSite/UpdateListItem`,
            body: newRow,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function processRowUpdate(
        newRow: NominatedSite,
        oldRow: NominatedSite
    ): NominatedSite | Promise<NominatedSite> {
        const response = updateRowApiCall(newRow, {
            onSuccess: handleSuccessfulRowUpdate,
            onError: handleErrorRowUpdate,
        });
        return newRow;
    }

    function duplicateSitesRender(
        params: GridRenderEditCellParams,
        disabled: boolean
    ) {
        const options = iwiList.map((iwi) => {
            return {
                id: iwi.id,
                nominatedSiteId: params.row.id,
                name: iwi.name,
                isDuplicatedSite: false,
            } as DuplicatedSiteListItem;
        });

        const props = {
            cellParams: params,
            options: options,
            disabled: disabled,
        } as AutoCompleteDataGridProps;

        return <AutoCompleteDataGrid {...props} />;
    }

    function hideCouncilColumn(): boolean {
        const roles = roleContext.roles;
        if (!roles) {
            return false;
        }

        return !(roles.isSysAdmin || roles.isCouncilUser);
    }

    function userCanEdit(): boolean {
        const roles = roleContext.roles;
        if (!roles) {
            return false;
        }

        return roles.isSysAdmin || roles.isCouncilUser;
    }

    function columns() {
        return [
            {
                field: "mhidNumber",
                headerName: "MHID",
                type: "number",
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "id",
                hide: true,
            },
            {
                field: "name",
                hide: true,
            },
            {
                field: "link",
                headerName: "Name",
                flex: 2,
                valueGetter: (params) => params.row.name,
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
                renderCell: (params: GridRenderCellParams) => (
                    <MaterialLink
                        component={Link}
                        to={`/nominatedSite/${params.row.id}`}
                        underline="hover"
                    >
                        {params.row.name}
                    </MaterialLink>
                ),
                sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
            },
            {
                field: "iwiName",
                headerName: "Iwi Name",
                flex: 2,
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "siteOfSignificanceType",
                headerName: "Type",
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "completedDates",
                headerName: "Progress",
                flex: 2,
                groupable: false,
                filterable: false,
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <NominatedSiteProgress completedDates={params.value} />
                    );
                },
            },
            {
                field: "totalScore",
                headerName: "Total Score",
                type: "number",
                flex: 2,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "totalOpportunityScore",
                headerName: "Total Opportunity Score",
                type: "number",
                flex: 2,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "developmentRiskScore",
                headerName: "Development Risk Score",
                type: "number",
                flex: 2,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "naturalHazardRiskScore",
                headerName: "Natural Hazard Risk Score",
                type: "number",
                flex: 2,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "tranche",
                headerName: "Tranche",
                type: "number",
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "siteStatus",
                headerName: "Site Status",
                editable: userCanEdit(),
                type: "singleSelect",
                valueOptions: [
                    "Nominated",
                    "Management Statement Complete",
                    "Alert Layer",
                    "Scheduled",
                    "Withdrawn",
                ],
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "isVisible",
                headerName: "Show Site",
                editable: true,
                type: "boolean",
                flex: 1,
                hide: hideCouncilColumn(),
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "showManagementResponse",
                headerName: "Show Management Reponse",
                editable: true,
                type: "boolean",
                flex: 2,
                hide: hideCouncilColumn(),
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
            },
            {
                field: "duplicateSites",
                headerName: "Iwi",
                editable: true,
                flex: 3,
                sortable: false,
                groupable: false,
                headerAlign: "center",
                hide: hideCouncilColumn(),
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
                renderCell: (params: GridRenderCellParams) =>
                    duplicateSitesRender(params, true),
                renderEditCell: (params: GridRenderEditCellParams) =>
                    duplicateSitesRender(params, false)
            },
            {
                field: "keyAssetFeatures",
                headerName: "Key Asset Features",
                flex: 3,
                sortable: false,
                headerAlign: "center",
                groupable: false,
                hide: hideCouncilColumn(),
                renderHeader: (params: GridColumnHeaderParams) =>
                    DataGridHeaderCell(params),
                valueGetter: (params) => {
                    if (params.row.keyAssetFeatures && Array.isArray(params.row.keyAssetFeatures)) {
                        const filteredArray = params.row.keyAssetFeatures.filter((feature: KeyAssetFeatureListItem) => !(params.row.keyAssetFeatures.length > 1 && feature.name === "other"));
                        return filteredArray.map((feature: KeyAssetFeatureListItem) => feature.name).join(', ');
                    }
                    return '';
                }
            }
        ] as GridColumns;
    }
}

type setSnackbarProp = (error: string) => void;

type CustomExportButtonProps = ButtonProps & {
    setSnackbarError: setSnackbarProp;
};
const CustomExportButton = (props: CustomExportButtonProps) => (
    <GridToolbarExportContainer {...props}>
        <CustomExcelExportMenuItem setSnackbarError={props.setSnackbarError} />
    </GridToolbarExportContainer>
);

type CustomToolbarProps = GridToolbarContainerProps & {
    setSnackbarError: setSnackbarProp;
};
function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer {...props}>
            <CustomExportButton setSnackbarError={props.setSnackbarError} />
        </GridToolbarContainer>
    );
}

type CustomExcelExportMenuItemProps = GridExportMenuItemProps<{}> & {
    setSnackbarError: setSnackbarProp;
};
const CustomExcelExportMenuItem = (props: CustomExcelExportMenuItemProps) => {
    const apiRef = useGridApiContext();

    const { hideMenu, setSnackbarError } = props;

    return (
        <>
            <MenuItem
                onClick={async () => {
                    try {
                        var workbook = await apiRef.current.getDataAsExcel();

                        if (!workbook) {
                            throw "Workbook could not be generated by the datagrid";
                        }
                        const worksheet = workbook.worksheets[0];
                        removeColumns(worksheet);
                        addNewCols(worksheet, apiRef);
                        formatHeaderRow(worksheet);
                        freezeRowsAndCols(worksheet);
                        addFilterButtons(worksheet);

                        autoWidthColumns(worksheet);

                        workbook?.xlsx.writeBuffer().then(function (data) {
                            var blob = new Blob([data], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            exportBlob(
                                blob,
                                "Nominated Sites Grid Export.xlsx"
                            );
                        });
                    } catch (e) {
                        setSnackbarError(
                            "There was an error processing the excel file, contact an admin if this persists"
                        );
                    }
                    // Hide the export menu after the export
                    hideMenu?.();
                }}
            >
                Export Excel
            </MenuItem>
        </>
    );
};

const exportBlob = (blob: Blob, filename: string) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

function removeColumns(worksheet: Worksheet) {
    const duplicateSites = worksheet.getColumnKey("duplicateSites");
    duplicateSites && removeColumn(duplicateSites.number, worksheet);

    const showManagementResponse = worksheet.getColumnKey(
        "showManagementResponse"
    );
    showManagementResponse &&
        removeColumn(showManagementResponse.number, worksheet);

    const isVisible = worksheet.getColumnKey("isVisible");
    isVisible && removeColumn(isVisible.number, worksheet);

    const completedDates = worksheet.getColumnKey("completedDates");
    completedDates && removeColumn(completedDates.number, worksheet);
}

function removeColumn(index: number, worksheet: Worksheet) {
    if (index == -1) return;
    worksheet.spliceColumns(index, 1);
    return;
}

function addNewCols(
    worksheet: Worksheet,
    apiRef: MutableRefObject<GridApiPremium>
) {
    var rows = apiRef.current.getVisibleRowModels();

    var liveStatusCol = ["Live Status"] as string[];
    var engagementCol = ["Engagement Ready?"] as string[];
    var areaCol = ["Area (sqm)"] as string[];

    rows.forEach((row) => {
        liveStatusCol.push(row.liveStatus);
        engagementCol.push(row.engagementReady);
        areaCol.push(row.shapeArea);
    });

    var liveStatusIndex =
        worksheet.getColumnKey("siteOfSignificanceType").number + 1;
    var engagementIndex =
        worksheet.getColumnKey("siteOfSignificanceType").number + 2;
    worksheet.spliceColumns(liveStatusIndex, 0, liveStatusCol);
    worksheet.spliceColumns(engagementIndex, 0, engagementCol);

    var areaIndex = worksheet.getColumnKey("siteStatus").number + 1;
    worksheet.spliceColumns(areaIndex, 0, areaCol);
}

function formatHeaderRow(worksheet: Worksheet) {
    var row = worksheet.getRow(1);
    row.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "464646" },
    };
    row.font = {
        color: { argb: "FFFFFF" },
    };
}

function freezeRowsAndCols(worksheet: Worksheet) {
    worksheet.views = [
        {
            state: "frozen",
            xSplit: 3,
            ySplit: 1,
        },
    ];
}

function addFilterButtons(worksheet: Worksheet) {
    worksheet.autoFilter = "A1:M1";
}

function autoWidthColumns(worksheet: Worksheet) {
    worksheet.columns.forEach(function (column, i) {
        if (!column || !column.eachCell) return;
        var maxLength = 0;
        column.eachCell({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
    });
}
