export type Option = {
    id: string;
    name: string;
};

const keyAssetFeatureOther = {
    id: "30",
    name: "other",
}
export const SiteAnalysisOptions = {
    assetTypes: [
        {
            id: "1",
            name: "Landscape",
        },
        {
            id: "2",
            name: "Site",
        },
        {
            id: "3",
            name: "Structure",
        },
    ],
    keyAssetFeatureOther,
    keyAssetFeatures: [
        {
            id: "1",
            name: "Maunga",
        },
        {
            id: "2",
            name: "Puke (Hill)",
        },
        {
            id: "3",
            name: "Hiwi (Ridge)",
        },
        {
            id: "4",
            name: "Awa",
        },
        {
            id: "5",
            name: "Roto (lake)",
        },
        {
            id: "6",
            name: "Whanga",
        },
        {
            id: "7",
            name: "Puna",
        },
        {
            id: "8",
            name: "Wai Māori",
        },
        {
            id: "9",
            name: "Moana",
        },
        {
            id: "10",
            name: "Ara",
        },
        {
            id: "11",
            name: "Repo",
        },
        {
            id: "12",
            name: "Ngahere",
        },
        {
            id: "13",
            name: "Rawa taiao (natural resource)",
        },
        {
            id: "14",
            name: "Mahinga kai",
        },
        {
            id: "15",
            name: "Māra kai",
        },
        {
            id: "16",
            name: "Marae",
        },
        {
            id: "17",
            name: "Whare",
        },
        {
            id: "18",
            name: "Hanganga (structure)",
        },
        {
            id: "19",
            name: "Pā",
        },
        {
            id: "20",
            name: "Kāinga",
        },
        {
            id: "21",
            name: "Wāhi Tapu",
        },
        {
            id: "22",
            name: "Wāhi Tohu",
        },
        {
            id: "23",
            name: "Wāhi Pakanga",
        },
        {
            id: "24",
            name: "Wāhi Tupuna",
        },
        {
            id: "25",
            name: "Wāhi Taonga",
        },
        {
            id: "26",
            name: "Tōanga Waka (waka portage)",
        },
        {
            id: "27",
            name: "Tauranga Waka (Waka landing)",
        },
        {
            id: "28",
            name: "Rohenga/Māka (Boundary)",
        },
        {
            id: "29",
            name: "Toi Mahi",
        },
        {
            id: "31",
            name: "Pae Maunga (Mountain range)",
        },
        {
            id: "32",
            name: "Manawa (Mangroves)",
        },
        {
            id: "33",
            name: "Tūahu",
        },
        {
            id: "34",
            name: "Urupa",
        },
        {
            id: "35",
            name: "Ana (caves)",
        },
        {
            id: "36",
            name: "Grove of rakau (Grove of trees)",
        },
        {
            id: "37",
            name: "Rua Taniwha (Taniwha den)",
        },
        {
            id: "38",
            name: "Pā Tūwatawata (Fort defended by stockade)",
        },
        {
            id: "39",
            name: "Pou Whenua (Cultural Landmark)",
        },
        {
            id: "40",
            name: "Pataka Kai (Food Storehouse)",
        },
        {
            id: "41",
            name: "Rongoa Māori",
        },
        {
            id: "42",
            name: "Pā harakeke",
        },
        {
            id: "43",
            name: "Toka (Rock)"
        },
        keyAssetFeatureOther,
    ],
    landStatuses: [
        {
            id: "1",
            name: "Public",
        },
        {
            id: "2",
            name: "Private",
        },
        {
            id: "3",
            name: "Mixed Ownership",
        },
    ],
    manaWhenuaRohes: [
        {
            id: "1",
            name: "Te Runanga o Ngāti Whatua",
        },
        {
            id: "2",
            name: "Te Uri o Hau",
        },
        {
            id: "3",
            name: "Ngāti Whātua o Kaipara",
        },
        {
            id: "4",
            name: "Ngāti Whātua Ōrākei",
        },
        {
            id: "5",
            name: "Ngātiwai",
        },
        {
            id: "6",
            name: "Ngāti Manuhuri",
        },
        {
            id: "7",
            name: "Ngāti Rehua",
        },
        {
            id: "8",
            name: "Te Kawerau a Maki",
        },
        {
            id: "9",
            name: "Te Ahiwaru",
        },
        {
            id: "10",
            name: "Te Ākitai Waiohua",
        },
        {
            id: "11",
            name: "Ngāti Tamaoho",
        },
        {
            id: "12",
            name: "Ngāti Te Ata",
        },
        {
            id: "13",
            name: "Ngai Tai ki Tamaki",
        },
        {
            id: "14",
            name: "Ngāti Paoa",
        },
        {
            id: "15",
            name: "Ngāti Whanaunga",
        },
        {
            id: "16",
            name: "Te Patukirikiri",
        },
        {
            id: "17",
            name: "Ngāti Tamaterā",
        },
        {
            id: "18",
            name: "Ngāti Maru",
        },
        {
            id: "19",
            name: "Waikato-Tainui",
        },
    ],
    localBoards: [
        {
            id: "1",
            name: "Albert-Eden",
        },
        {
            id: "2",
            name: "Aotea",
        },
        {
            id: "3",
            name: "Devonport-Takapuna",
        },
        {
            id: "4",
            name: "Franklin",
        },
        {
            id: "5",
            name: "Henderson-Massey",
        },
        {
            id: "6",
            name: "Hibiscus and Bays",
        },
        {
            id: "7",
            name: "Howick",
        },
        {
            id: "8",
            name: "Kaipātiki Local Board",
        },
        {
            id: "9",
            name: "Māngere-Ōtāhuhu",
        },
        {
            id: "10",
            name: "Manurewa",
        },
        {
            id: "11",
            name: "Maungakiekie-Tāmaki",
        },
        {
            id: "12",
            name: "Ōrākei",
        },
        {
            id: "13",
            name: "Ōtara-Papatoetoe",
        },
        {
            id: "14",
            name: "Papakura",
        },
        {
            id: "15",
            name: "Puketāpapa",
        },
        {
            id: "16",
            name: "Rodney",
        },
        {
            id: "17",
            name: "Upper Harbour",
        },
        {
            id: "18",
            name: "Waiheke",
        },
        {
            id: "19",
            name: "Waitākere",
        },
        {
            id: "20",
            name: "Waitematā",
        },
        {
            id: "21",
            name: "Whau",
        },
    ],
    zonings: [
        {
            id: "1",
            name: "residential - large lot",
        },
        {
            id: "2",
            name: "residential - rural and coastal settlement",
        },
        {
            id: "3",
            name: "residential - single house",
        },
        {
            id: "4",
            name: "residential - mixed housing suburban",
        },
        {
            id: "5",
            name: "residential - mixed house urban",
        },
        {
            id: "6",
            name: "residential - terrace housing and apartment buildings",
        },
        {
            id: "7",
            name: "open space - conservation",
        },
        {
            id: "8",
            name: "open space - informal recreation",
        },
        {
            id: "9",
            name: "open space - sports and active recreation",
        },
        {
            id: "10",
            name: "open space - civic spaces",
        },
        {
            id: "11",
            name: "open space - community",
        },
        {
            id: "12",
            name: "coastal - general coastal marine",
        },
        {
            id: "13",
            name: "coastal - marina",
        },
        {
            id: "14",
            name: "coastal - mooring",
        },
        {
            id: "15",
            name: "coastal - minor port",
        },
        {
            id: "16",
            name: "coastal - ferry terminal",
        },
        {
            id: "17",
            name: "coastal - defence",
        },
        {
            id: "18",
            name: "coastal - coastal transition",
        },
        {
            id: "19",
            name: "coastal - vehicles on beaches",
        },
        {
            id: "20",
            name: "business - city centre",
        },
        {
            id: "21",
            name: "business - metropolitan centre",
        },
        {
            id: "22",
            name: "business - town centre",
        },
        {
            id: "23",
            name: "business - local centre",
        },
        {
            id: "24",
            name: "business - neighbourhood centre",
        },
        {
            id: "25",
            name: "business - mixed use",
        },
        {
            id: "26",
            name: "business - general business",
        },
        {
            id: "27",
            name: "business - heavy industry",
        },
        {
            id: "28",
            name: "business - light industry",
        },
        {
            id: "47",
            name: "business - business park zone",
        },
        {
            id: "29",
            name: "future urban",
        },
        {
            id: "30",
            name: "rural - rural production",
        },
        {
            id: "31",
            name: "rural - mixed rural",
        },
        {
            id: "32",
            name: "rural - rural coastal",
        },
        {
            id: "33",
            name: "rural - rural conservation",
        },
        {
            id: "34",
            name: "rural - countryside living",
        },
        {
            id: "35",
            name: "rural - Waitākere foothills",
        },
        {
            id: "36",
            name: "rural - Waitākere ranges",
        },
        {
            id: "37",
            name: "strategic transport corridor",
        },
        {
            id: "38",
            name: "special purpose - airports and airfields",
        },
        {
            id: "39",
            name: "special purpose - cemetery",
        },
        {
            id: "40",
            name: "special purpose - healthcare facility and hospital",
        },
        {
            id: "41",
            name: "special purpose - major recreation facility",
        },
        {
            id: "42",
            name: "special purpose - Māori purpose",
        },
        {
            id: "43",
            name: "special purpose - quarry",
        },
        {
            id: "44",
            name: "special purpose - school",
        },
        {
            id: "45",
            name: "special purpose - tertiary education",
        },
        {
            id: "46",
            name: "district plan - Hauraki Gulf Islands",
        },
    ],
};

export const CurrentManagementOfTheMaoriHeritageAssetOptions = {
    siteExtentConfirmeds: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    managementStatements: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    haurakiGulfMarineParkActs: [
        {
            id: "0",
            name: "No - This site is not within the HGIMPA ",
        },
        {
            id: "1",
            name: "Yes - This site is within the HGIMPA",
        },
    ],
    waitakereRangesHeritageAreaActs: [
        {
            id: "0",
            name: "No - This site is not within the WRHAA ",
        },
        {
            id: "1",
            name: "Yes - This site is within the WRHAA",
        },
    ],
    leases: [
        {
            id: "1",
            name: "Yes",
        },
        {
            id: "2",
            name: "No",
        },
        {
            id: "3",
            name: "Other",
        },
    ],
    siteExceptionRules: [
        {
            id: "0",
            name: "N",
        },
        {
            id: "1",
            name: "Y",
        },
    ],
    overlaysThatApplys: [
        {
            id: "1",
            name: "D1 (high-use aquifer)",
        },
        {
            id: "2",
            name: "D2 (quality-sensitive aquifer)",
        },
        {
            id: "3",
            name: "D3 (high-use stream)**",
        },
        {
            id: "4",
            name: "D4 (natural stream)**",
        },
        {
            id: "5",
            name: "D5 (natural lake)**",
        },
        {
            id: "6",
            name: "D6 (urban lake)",
        },
        {
            id: "7",
            name: "D7 (water supply)",
        },
        {
            id: "8",
            name: "D8 (wetland)**",
        },
        {
            id: "9",
            name: "D9 (SEA)**",
        },
        {
            id: "10",
            name: "D10 (ONF and ONL)*",
        },
        {
            id: "11",
            name: "D11 (ONC and HNC)**",
        },
        {
            id: "12",
            name: "D12 (WRHA)**",
        },
        {
            id: "13",
            name: "D13 (trees)",
        },
        {
            id: "14",
            name: "D14 (vol viewshafts)*",
        },
        {
            id: "15",
            name: "D15 (ridgeline)",
        },
        {
            id: "16",
            name: "D16 (loc viewshafts)*",
        },
        {
            id: "17",
            name: "D17 (HHP)*",
        },
        {
            id: "18",
            name: "D18 (special character - residential and business)",
        },
        {
            id: "19",
            name: "D19 (Auck Museum)",
        },
        {
            id: "20",
            name: "D20A (Stockade Hill view)",
        },
        {
            id: "21",
            name: "D21 (SSMW)*",
        },
        {
            id: "22",
            name: "D22 (growth corridor)",
        },
        {
            id: "23",
            name: "D23 (airport approach)",
        },
        {
            id: "24",
            name: "D24 (aircraft noise)",
        },
        {
            id: "25",
            name: "D25 (port)",
        },
        {
            id: "26",
            name: "D26 (national grid)",
        },
        {
            id: "27",
            name: "D27 (quarry buffer)",
        },
    ],
    landUses: [
        {
            id: "1",
            name: "urban",
        },
        {
            id: "2",
            name: "rural residential",
        },
        {
            id: "3",
            name: "industrial",
        },
        {
            id: "4",
            name: "infrastructure",
        },
        {
            id: "5",
            name: "pasture/agriculture",
        },
        {
            id: "6",
            name: "forestry",
        },
        {
            id: "7",
            name: "horticulture",
        },
        {
            id: "8",
            name: "coastal",
        },
        {
            id: "9",
            name: "open space",
        },
        {
            id: "10",
            name: "conservation/natural habitat",
        },
        {
            id: "11",
            name: "cemetery",
        },
        {
            id: "12",
            name: "other",
        },
    ],
    covenants: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    reserves: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    transferOfPowers: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    coManagementGovernanceAgreements: [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ],
    culturalValuesAssignedB652s: [
        {
            id: "1",
            name: "a – mauri",
        },
        {
            id: "2",
            name: "b - wahi tapu",
        },
        {
            id: "3",
            name: "c- korero tutur",
        },
        {
            id: "4",
            name: "d - rawa tuturu",
        },
        {
            id: "5",
            name: "e - hiahiatanga tuturu",
        },
        {
            id: "6",
            name: "f - whakaaronui o te wa",
        },
        {
            id: "7",
            name: "g - horopaki",
        },
    ],
    environmentalAndCulturalRisks: [
        {
            id: "1",
            name: "development",
        },
        {
            id: "2",
            name: "neglect or vandalism",
        },
        {
            id: "3",
            name: "pollution/contamination",
        },
        {
            id: "4",
            name: "earthworks",
        },
        {
            id: "5",
            name: "erosion",
        },
        {
            id: "6",
            name: "climate change",
        },
        {
            id: "7",
            name: "infrastructure/utilities",
        },
        {
            id: "8",
            name: "stormwater",
        },
        {
            id: "9",
            name: "wastewater",
        },
        {
            id: "10",
            name: "public access",
        },
        {
            id: "11",
            name: "air discharges",
        },
        {
            id: "12",
            name: "water take",
        },
        {
            id: "13",
            name: "modification of waterways",
        },
        {
            id: "14",
            name: "vegetation removal",
        },
        {
            id: "15",
            name: "pesticides",
        },
        {
            id: "16",
            name: "growing/consuming food",
        },
        {
            id: "17",
            name: "toilets",
        },
        {
            id: "18",
            name: "viewshaft alteration",
        },
        {
            id: "19",
            name: "topsoil degradation",
        },
        {
            id: "20",
            name: "reclamation",
        },
        {
            id: "21",
            name: "subdivision",
        },
        {
            id: "22",
            name: "farm stock",
        },
        {
            id: "23",
            name: "quarrying/mining/filling",
        },
        {
            id: "24",
            name: "forestry activities",
        },
        {
            id: "25",
            name: "farm activities",
        },
        {
            id: "26",
            name: "weeds and pests",
        },
        {
            id: "27",
            name: "other",
        },
    ],
};

export const AssetStatusUpdateOptions = {
    assetStatuses: [
        {
            id: "1",
            name: "active",
        },
        {
            id: "2",
            name: "pending MW confirmation",
        },
        {
            id: "3",
            name: "on hold",
        },
        {
            id: "4",
            name: "deleted",
        },
        {
            id: "5",
            name: "outside AC region",
        },
    ],
    threatStatuses: [
        {
            id: "1",
            name: "low/green",
        },
        {
            id: "2",
            name: "med/amber",
        },
        {
            id: "3",
            name: "high/red",
        },
    ],
    planningStatuses: [
        {
            id: "1",
            name: "low/green",
        },
        {
            id: "2",
            name: "uncertain/amber",
        },
        {
            id: "3",
            name: "difficult/red",
        },
    ],
    managementRecommendations: [
        {
            id: "1",
            name: "alert layer",
        },
        {
            id: "2",
            name: "IMP schedule",
        },
        {
            id: "3",
            name: "schedule 6(k)",
        },
        {
            id: "4",
            name: "schedule 7(g)",
        },
        {
            id: "5",
            name: "schedule 9(d)",
        },
        {
            id: "6",
            name: "schedule 11(d)",
        },
        {
            id: "7",
            name: "schedule 12",
        },
        {
            id: "8",
            name: "schedule 14(c)",
        },
        {
            id: "9",
            name: "covenant",
        },
        {
            id: "10",
            name: "reserve",
        },
        {
            id: "11",
            name: "land acquisition",
        },
        {
            id: "12",
            name: "transfer of powers",
        },
        {
            id: "13",
            name: "heritage orders",
        },
        {
            id: "14",
            name: "listing",
        },
        {
            id: "15",
            name: "precinct/landscape",
        },
        {
            id: "16",
            name: "management plan",
        },
        {
            id: "17",
            name: "co-management/governance",
        },
    ],
};

export const SiteVisitRecordOptions = {
    siteConditions: [
        {
            id: "1",
            name: "1 - Poor",
        },
        {
            id: "2",
            name: "2",
        },
        {
            id: "3",
            name: "3 - Fair",
        },
        {
            id: "4",
            name: "4",
        },
        {
            id: "5",
            name: "5 - Good",
        },
    ],
};
