import { Pending } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
    Alert,
    AlertProps,
    Button,
    Grid,
    Snackbar,
    Tooltip,
    useTheme
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import {
    DocumentCategory,
    FileItem,
    FileManager,
    Item
} from "components/FileManager/FileManager";
import { RoleContext } from "context/roleContext";
import { useApiFetch } from "hooks/useApiFetch";
import { encodeFileUrl } from "components/FileManager/FileManagerWrapper"
import {
    ChangeEvent,
    useContext,
    useEffect, useRef,
    useState
} from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ValidFileExtension } from '../../types/ValidFileExtension';
import useDocumentUploader, { FileCreatedDto } from "hooks/useDocumentUploader";
import axios from "axios";
import { blob } from "stream/consumers";

type ResearchByCouncilState = {
    files: Item[];
    numberOfFiles: number;
    loading: boolean;
    uploading: boolean;
};

const section: string = "Task1.ResearchByCouncil";

export function ResearchByCouncil() {
    const theme = useTheme();
    const intl: IntlShape = useIntl();
    const { apiGet, apiPost, apiDelete } = useApiFetch();
    const [state, setState] = useState<ResearchByCouncilState>({
        files: [],
        numberOfFiles: 0,
        loading: true,
        uploading: false
    });
    const [categories, setCategories] = useState<DocumentCategory[]>();
    const [categoryFilter, setCategoryFilter] = useState<number>(-1);
    const [extensions, setExtensions] = useState<string>();
    const inputFile = useRef<HTMLInputElement>(null);

    const { id } = useParams();

    const { uploadDocuments } = useDocumentUploader({
        config: { siteId: id, documentTypeName: "PublicInformation" },
        onSuccess: (files) => onUploadSuccess(files),
        onError: (error) => handleError(error)
    })

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const roleContext = useContext(RoleContext);
    const canAddOrDelete: boolean =
        roleContext.roles?.isSysAdmin ||
        roleContext.roles?.isCouncilUser ||
        false;

    useEffect(() => {
        getFiles();
        getCategories();
        getValidFileExtensions();
    }, []);

    useEffect(() => {
        setCategoryIds();
    }, [state, categories]);

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                aria-controls="task1-research-by-council"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>

                    <Typography component="h1" variant="h5" align="right">
                        {state.numberOfFiles}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <FormattedMessage id={`${section}.Description.1`} />
                    </Grid>
                    <Grid item md={12}>
                        <FormattedMessage id={`${section}.Description.2`} />
                    </Grid>
                    <Grid item md={12}>
                        {state.files && categories && (
                            <FileManager
                                files={state.files}
                                deleteable={canAddOrDelete}
                                downloadable
                                onDownload={onDownload}
                                onDelete={onDelete}
                                categoryEditable
                                categories={categories}
                                onCategoryEdit={onCategoryEdit}
                                categoryFilter={categoryFilter}
                                onCategoryFilterEdit={setCategoryFilter}
                            />
                        )}
                        <input
                            type="file"
                            id="file"
                            ref={inputFile}
                            accept={extensions}
                            style={{ display: "none" }}
                            onChange={(event) => onFileChange(event)}
                            multiple
                        />
                        <Tooltip title={intl.formatMessage({
                            id: "Generic.FileRestrictionWarning",
                        })} placement="bottom" arrow>
                            <Button
                                fullWidth
                                variant="contained"
                                startIcon={state.uploading ? <Pending /> : <AddIcon />}
                                onClick={onUpload}
                                disabled={!canAddOrDelete || state.uploading}
                            >
                                {state.uploading ? (
                                    <FormattedMessage id={`Generic.UploadingFile`} />
                                ) : (
                                    <FormattedMessage id={`Generic.AddFile`} />
                                )}

                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </AccordionDetails>
        </Accordion>
    );

    function getFiles() {
        apiGet({
            path: `NotifiedSite/${id}/Document/Type/PublicInformation`,
            onSuccess: (data) => onLoadSuccess(data),
            onError: (error) => handleFetchError(error),
        });
    }

    function getCategories() {
        apiGet({
            path: `NotifiedSite/${id}/Document/Category`,
            onSuccess: (data) => onCategoryGetSuccess(data),
            onError: (error) => handleFetchError(error),
        });
    }

    function getValidFileExtensions() {
        apiGet({
            path: "ValidFileExtension/List",
            onSuccess: (data) => onValidFileExtensionsGetSuccess(data),
            onError: handleFetchError,
        });
    }

    function onValidFileExtensionsGetSuccess(data: ValidFileExtension[]) {
        const extensions = data.map(e => `.${e.extension.trim()}`).join(", ");
        setExtensions(extensions);
    }

    function onCategoryGetSuccess(data: DocumentCategory[]) {
        setCategories(data);
    }

    function onFileChange(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) {
            return;
        }
        const files = Array.from(event.target.files);
        uploadDocuments(files);

        setState({
            ...state,
            uploading: true
        });
    }

    function onUpload() {
        inputFile.current?.click();
    }

    function onUploadSuccess(files: FileCreatedDto[]) {
        setSnackbar({
            children: `${files.length > 1 ? `${files.length} Files` : files[0].fileName} uploaded`,
            severity: "success",
        });
        setState({
            ...state,
            uploading: false
        });
        getFiles();
    }

    async function onDownload(fileId: string) {
        const file = state.files.find((x) => x.id === fileId) as FileItem;
        const filename = file.fileName;

        apiGet({
            path: encodeFileUrl(file, id!, "Document/Download"),
            onSuccess: (url) => axios.get(url, { responseType: "blob" })
                .then((data) => onDownloadSuccess(filename, data.data))
                .catch((error) => handleError(error.message)),
            onError: (error) => handleError(error.message)
        });
    }

    function onDownloadSuccess(filename: string, data: Blob) {
        const blobAsFile = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = blobAsFile;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(blobAsFile);
        document.body.removeChild(a);
    }

    function onLoadSuccess(data: any) {
        const dataArray = data as Item[];
        setState({
            files: dataArray,
            numberOfFiles: dataArray.length,
            loading: true,
            uploading: state.uploading
        });
    }

    function setCategoryIds() {
        if (categories && state && state.numberOfFiles > 0 && state.loading) {
            const files = state.files.map((file: Item) => {
                const fileItem = file as FileItem;
                if (fileItem) {
                    const isValidCategory = categories.map(category => category.id).includes(fileItem?.categoryId?.toString() ?? "");
                    fileItem.categoryId = isValidCategory ? fileItem.categoryId : -1
                }
                return fileItem ?? file;
            });
            setState({
                files,
                numberOfFiles: state.numberOfFiles,
                loading: false,
                uploading: state.uploading
            });
        };
    }

    function handleFetchError(error: FetchError) {
        handleError(error.message);
    }

    function handleError(error: string) {
        setSnackbar({
            children: error.includes("Request body too large") ? intl.formatMessage({
                id: "Generic.FileSizeLimitWarning",
            }) : error, severity: "error"
        });
        setState({
            ...state,
            uploading: false
        });
    }

    function onDelete(fileId: string) {
        const file = state.files.find((x) => x.id == fileId) as FileItem;

        apiDelete({
            path: encodeFileUrl(file, id!, "Document"),
            onSuccess: () => onDeleteSuccess(file.fileName),
            onError: handleFetchError,
        });
    }

    function onDeleteSuccess(filename: string) {
        setSnackbar({
            children: `${filename} deleted`,
            severity: "success",
        });
        getFiles();
    }

    function onCategoryEdit(fileId: string, categoryId: number) {
        if (!canAddOrDelete) {
            return;
        }
        const file = state.files.find((x) => x.id == fileId) as FileItem;

        if (categories === undefined) {
            return;
        }

        const categoryDto = categories.find((x) => x.id === categoryId.toString());

        apiPost({
            path: `${encodeFileUrl(file, id!, "Document/Category")}${categoryDto?.id ? `&documentCategoryId=${categoryDto?.id}` : ""}`,
            onSuccess: () => getFiles(),
            onError: handleFetchError,
        });
    }

}
