import SaveIcon from "@mui/icons-material/Save";
import { Alert, Fab, Grid, Snackbar, SnackbarOrigin } from "@mui/material";
import { AssetStatusUpdate } from "pages/forms/AssetStatusUpdate";
import { CurrentManagementOfTheMaoriHeritageAsset } from "pages/forms/CurrentManagementOfTheMaoriHeritageAsset";
import { SiteAnalysis } from "pages/forms/SiteAnalysis";
import { UndeleteFilesCard } from "pages/UndeleteFiles";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { useApiFetch } from "../../hooks/useApiFetch";
import { ResearchByCouncil } from "../../pages/forms/ResearchByCouncil";
import { FetchError } from "../@beca-common-react-legacy/useFetchHook";

interface InformationGatheringProps {
    nominatedSiteState: NominatedSiteState;
    setNominatedSiteState: SetNominatedSiteState;
}

export function InformationGathering(props: InformationGatheringProps) {
    const { apiPost } = useApiFetch();
    const { id } = useParams();
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [error, setError] = useState<FetchError>();

    const onSave = (e: any) => {
        e.preventDefault();

        apiPost({
            path: `NominatedSite/Update?id=${id}`,
            body: props.nominatedSiteState,
            onSuccess: onSuccess,
            onError: (error) => setError(error),
        });
    };

    const snackbarOrigin: SnackbarOrigin = {
        vertical: "bottom",
        horizontal: "center",
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item md={6}>
                    <SiteAnalysis
                        state={props.nominatedSiteState}
                        setState={props.setNominatedSiteState}
                    />
                    <CurrentManagementOfTheMaoriHeritageAsset
                        state={props.nominatedSiteState}
                        setState={props.setNominatedSiteState}
                    />
                </Grid>
                <Grid item md={6}>
                    <AssetStatusUpdate
                        state={props.nominatedSiteState}
                        setState={props.setNominatedSiteState}
                    />
                    <ResearchByCouncil />
                    <Grid item md={12}>
                        <UndeleteFilesCard />
                    </Grid>
                </Grid>
                <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="save"
                    sx={{ position: "fixed", bottom: "16px" }}
                    onClick={onSave}
                >
                    <SaveIcon sx={{ mr: 1 }} /> Save Task 1
                </Fab>
            </Grid>
            <Snackbar
                open={submitSuccess}
                onClose={handleSuccessAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleSuccessAlertClose}
                    variant="filled"
                    severity="success"
                >
                    Your changes have been saved.
                </Alert>
            </Snackbar>
            <Snackbar
                open={error !== undefined}
                onClose={handleErrorAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleErrorAlertClose}
                    variant="filled"
                    severity="error"
                >
                    {error?.message}
                </Alert>
            </Snackbar>
        </>
    );

    function onSuccess(response: any) {
        setSubmitSuccess(true);
    }

    function handleSuccessAlertClose() {
        setSubmitSuccess(false);
    }

    function handleErrorAlertClose() {
        setError(undefined);
    }
}
