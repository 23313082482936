import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { DropDown } from "components/DropDown";
import { InputSlider } from "components/InputSlider";
import { ChangeEvent } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { AssetStatusUpdateForm } from "types/AssetStatusUpdateForm";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { AssetStatusUpdateOptions } from "../../types/Option";

const section: string = "AssetStatusUpdate";

type AssetStatusUpdateDisplayProps = {
    state: NominatedSiteState;
    setState: SetNominatedSiteState;
};

export function AssetStatusUpdate({
    state,
    setState,
}: AssetStatusUpdateDisplayProps) {
    const intl: IntlShape = useIntl();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="asset-status-update-content"
                id="asset-status-update-header"
                sx={{
                    bgcolor: "#d9edf7",
                    color: "#31708f",
                    boxShadow: 1,
                }}
            >
                <Typography component="h1" variant="h5">
                    <FormattedMessage id={`${section}.heading`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <DropDown
                        modelKey={"assetStatus"}
                        translatePrefix={section}
                        values={state.assetStatusId}
                        options={AssetStatusUpdateOptions.assetStatuses}
                        onChange={(e) => onChange(e, "assetStatusId")}
                        titleKey={"assetStatus"}
                    />
                    <DropDown
                        modelKey={"threatStatus"}
                        translatePrefix={section}
                        values={state.threatStatusId}
                        options={AssetStatusUpdateOptions.threatStatuses}
                        onChange={(e) => onChange(e, "threatStatusId")}
                    />
                    <InputSlider
                        modelKey={
                            "scheduleAssessmentTask1SiteAnalysisAndGeomapsSpatialDataPercentage"
                        }
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask1SiteAnalysisAndGeomapsSpatialDataPercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={
                            "scheduleAssessmentTask1HistoricalResearchPercentage"
                        }
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask1HistoricalResearchPercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={
                            "scheduleAssessmentTask1ArchaeologicalResearchPercentage"
                        }
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask1ArchaeologicalResearchPercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={"scheduleAssessmentTask2MataurangaPercentage"}
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask2MataurangaPercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={"scheduleAssessmentTask3SiteVisitPercentage"}
                        translatePrefix={section}
                        aria-label="Default"
                        value={state.scheduleAssessmentTask3SiteVisitPercentage}
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={"scheduleAssessmentTask4ThreatRiskPercentage"}
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask4ThreatRiskPercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={"scheduleAssessmentTask5WanangaPercentage"}
                        translatePrefix={section}
                        aria-label="Default"
                        value={state.scheduleAssessmentTask5WanangaPercentage}
                        onChange={OnChangeNumber}
                    />
                    <InputSlider
                        modelKey={
                            "scheduleAssessmentTask5ManagementResponsePercentage"
                        }
                        translatePrefix={section}
                        aria-label="Default"
                        value={
                            state.scheduleAssessmentTask5ManagementResponsePercentage
                        }
                        onChange={OnChangeNumber}
                    />
                    <DropDown
                        modelKey={"planningStatus"}
                        translatePrefix={section}
                        values={state.planningStatusId}
                        options={AssetStatusUpdateOptions.planningStatuses}
                        onChange={(e) => onChange(e, "planningStatusId")}
                        titleKey={"planningStatus"}
                    />
                    <DropDown
                        modelKey={"managementRecommendations"}
                        translatePrefix={section}
                        values={state.managementRecommendations}
                        options={
                            AssetStatusUpdateOptions.managementRecommendations
                        }
                        onChange={(e) =>
                            onChange(e, "managementRecommendations")
                        }
                        multiple
                        titleKey={"managementRecommendations"}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.comments`,
                        })}
                        variant="outlined"
                        value={state.comments}
                        onChange={(e) => onChange(e, "comments")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.lastMWCommsRecord`,
                        })}
                        variant="outlined"
                        value={state.lastMwCommsRecord}
                        onChange={(e) => onChange(e, "lastMwCommsRecord")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                    <DatePicker
                        label="dd/mm/yyyy"
                        value={state.lastSiteVisit}
                        onChange={(newValue: Date | null | undefined) =>
                            newValue && onChangeDate(newValue, "lastSiteVisit")
                        }
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <TextField
                        fullWidth
                        label={intl.formatMessage({
                            id: `${section}.actionsRequired`,
                        })}
                        variant="outlined"
                        value={state.actionsRequired}
                        onChange={(e) => onChange(e, "actionsRequired")}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1500 }}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );

    function onChange(
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string[] | string>,
        property: keyof AssetStatusUpdateForm
    ) {
        setState({ ...state, [property]: event.target.value });
    }

    function onChangeDate(
        value: Date | null,
        property: keyof AssetStatusUpdateForm
    ) {
        setState({ ...state, [property]: value });
    }

    function OnChangeNumber(
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string[] | string | Event>,
        property: keyof AssetStatusUpdateForm
    ) {
        setState({ ...state, [property]: Number(event.target.value) });
    }
}
