import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { Iwi } from "types/Iwi";

interface IwiContextState extends BaseContextState {
    iwiList: Iwi[];
    fetch?: () => void;
}

const contextDefaultValues: IwiContextState = {
    iwiList: [],
    error: undefined,
    isLoading: true,
    fetch: undefined,
};

export const IwiListContext =
    createContext<IwiContextState>(contextDefaultValues);

export function IwiListProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();

    const [iwiListState, setIwiListState] = useState<IwiContextState>({
        ...contextDefaultValues,
        fetch: getIwi,
    });

    function getIwi() {
        apiGet({
            path: `IwiAdmin/GetAllIwi`,
            onSuccess: (data) =>
                setIwiListState({
                    ...contextDefaultValues,
                    iwiList: data,
                    isLoading: false,
                    fetch: getIwi,
                }),
            onError: (error) =>
                setIwiListState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                    fetch: getIwi,
                }),
        });
    }

    return (
        <IwiListContext.Provider value={iwiListState}>
            {children}
        </IwiListContext.Provider>
    );
}
