import { Grid, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ProgressInput from "components/ProgressInput";
import { ThreatAssessmentAddCustom } from "components/ThreatAssessment/ThreatAssessmentAddCustom";
import { ThreatAssessmentList } from "components/ThreatAssessment/ThreatAssessmentList";
import { FutureThreatsContext } from "context/futureThreatsContext";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";

const section: string = "Task4.FutureThreats";

interface FutureThreatsProps {
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

export function FutureThreats(props: FutureThreatsProps) {
    const theme = useTheme();

    const futureThreatsContext = useContext(FutureThreatsContext);

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="future-threats-content"
                id="future-threats-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.Description`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.ScaleDescription`}
                            />
                        </Typography>
                    </Grid>
                    {futureThreatsContext.futureThreats &&
                        futureThreatsContext.setFutureThreats && (
                            <>
                                <Grid item md={12}>
                                    <ThreatAssessmentList
                                        isFutureThreat={true}
                                        state={
                                            futureThreatsContext.futureThreats
                                        }
                                        setState={
                                            futureThreatsContext.setFutureThreats
                                        }
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <ThreatAssessmentAddCustom
                                        isFutureThreat={true}
                                        state={
                                            futureThreatsContext.futureThreats
                                        }
                                        setState={
                                            futureThreatsContext.setFutureThreats
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                    <Grid item md={12}>
                        <ProgressInput
                            title="threatIssuesComplete"
                            field={DateCompletedField.ThreatIssuesComplete}
                            dateCompleted={props.dateCompleted}
                            progressOnClick={props.progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
