import Container from "@mui/material/Container";
import { CouncilDocumentsFileManager } from "../components/FileManager/CouncilDocumentsFileManager";

export function CouncilDocuments() {
    return (
        <Container maxWidth="md">
            <h2>Council Documents</h2>

            <CouncilDocumentsFileManager />
        </Container>
    );
}
