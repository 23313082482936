import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CompletedDatesContext } from "context/completedDatesContext";
import { ExistingImpactsProvider } from "context/existingImpactsContext";
import { FutureThreatsProvider } from "context/futureThreatsContext";
import { RoleContext } from "context/roleContext";
import { useApiFetch } from "hooks/useApiFetch";
import { useContext, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { CompletedDates } from "types/CompletedDates";
import { EditDateCompleted } from "types/EditDateCompleted";
import { NominatedSiteState } from "types/NominatedSiteState";
import { SetNominatedSiteState } from "types/SetNominatedSiteState";
import { InformationGathering } from "./InformationGathering";
import { LandownerEngagement } from "./LandownerEngagement";
import { ManagementResponse } from "./ManagementResponse";
import { MataurangiMaori } from "./MataurangaMaori";
import { ThreatAssessment } from "./ThreatAssessment";

interface NominatedSiteTabsProps {
    nominatedSiteState: NominatedSiteState;
    setNominatedSiteState: SetNominatedSiteState;
}

export function NominatedSiteTabs(props: NominatedSiteTabsProps) {
    const [value, setValue] = useState(0);
    const params = useParams();
    const { apiPost } = useApiFetch();
    const completedDatesContext = useContext(CompletedDatesContext);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const intl: IntlShape = useIntl();
    const section: string = "NominatedSiteTitles";

    const roleContext = useContext(RoleContext);
    const isCouncilOrAdmin: boolean = roleContext.isCouncilOrAdmin;

    return (
        <Box sx={{ bgcolor: "background.paper" }}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab
                        label={intl.formatMessage({
                            id: `${section}.InformationGathering`,
                        })}
                    />
                    <Tab
                        label={intl.formatMessage({
                            id: `${section}.MataurangaMaori`,
                        })}
                    />
                    <Tab
                        label={intl.formatMessage({
                            id: `${section}.LandownerEngagement`,
                        })}
                    />
                    <Tab
                        label={intl.formatMessage({
                            id: `${section}.ThreatAssessment`,
                        })}
                    />
                    {(props.nominatedSiteState.showManagementResponse ||
                        isCouncilOrAdmin) && (
                        <Tab
                            label={intl.formatMessage({
                                id: `${section}.ManagementResponse`,
                            })}
                        />
                    )}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <InformationGathering
                    nominatedSiteState={props.nominatedSiteState}
                    setNominatedSiteState={props.setNominatedSiteState}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MataurangiMaori
                    completedDates={completedDatesContext.completedDates}
                    progressOnClick={progressOnClick}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <LandownerEngagement
                    completedDates={completedDatesContext.completedDates}
                    progressOnClick={progressOnClick}
                    nominatedSiteState={props.nominatedSiteState}
                    setNominatedSiteState={props.setNominatedSiteState}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <FutureThreatsProvider>
                    <ExistingImpactsProvider>
                        <ThreatAssessment
                            completedDates={
                                completedDatesContext.completedDates
                            }
                            progressOnClick={progressOnClick}
                        />
                    </ExistingImpactsProvider>
                </FutureThreatsProvider>
            </TabPanel>
            {(props.nominatedSiteState.showManagementResponse ||
                isCouncilOrAdmin) && (
                <TabPanel value={value} index={4}>
                    <ManagementResponse
                        completedDates={completedDatesContext.completedDates}
                        progressOnClick={progressOnClick}
                    />
                </TabPanel>
            )}
        </Box>
    );

    function progressOnClick(
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) {
        const apiCall = record ? "RecordDateCompleted" : "EditDateCompleted";

        apiPost({
            path: `NominatedSite/${apiCall}?siteId=${params.id}`,
            body: data,
            onSuccess: (result) => {
                completedDatesContext.setCompletedDates &&
                    completedDatesContext.setCompletedDates({
                        ...completedDatesContext.completedDates,
                        [title]: data.date,
                    });
            },
        });
    }
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                        }}
                    >
                        {children}
                    </Grid>
                </Box>
            )}
        </div>
    );
}
