import { Close, Done, Email } from "@mui/icons-material/";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Snackbar,
    SnackbarOrigin,
    Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Iwi } from "types/Iwi";
import { User } from "types/User";

const section: string = "EditUser";

export default function EditUserPage() {
    const intl: IntlShape = useIntl();

    const paperStyle = { padding: "20px", width: "80vw", margin: "20px auto" };
    const [error, setError] = useState<FetchError>();
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState<User>();
    const [allRoles, setAllRoles] = useState<string[]>();
    const [allIwi, setAllIwi] = useState<Iwi[]>();
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [userIwi, setUserIwi] = useState<Iwi | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [sendInviteLoading, setSendInviteLoading] = useState(false);

    const { apiGet, apiPost } = useApiFetch();

    const snackbarOrigin: SnackbarOrigin = {
        vertical: "bottom",
        horizontal: "center",
    };

    useEffect(() => {
        if (id === undefined) {
            navigate("/users");
            return;
        }

        getUser({ onSuccess: setUser, onError: setError });
        getAllRoles({ onSuccess: setAllRoles, onError: setError });
        getAllIwi({ onSuccess: setAllIwi, onError: setError });
    }, []);

    useEffect(() => {
        if (user === undefined) return;

        setUserRoles(user?.roles);
        setDisabled(user?.disabled);
        setName(user?.name);
        setEmailConfirmed(user?.emailConfirmed);

        if (allIwi === undefined) return;

        const iwi = allIwi.find((iwi) => iwi.id === user?.iwiId);
        setUserIwi(iwi ?? null);
    }, [user, allIwi]);

    //Only turn off loading spinner if all initial queries are finished & successful
    const isLoading = useMemo(
        () =>
            user === undefined ||
            allIwi === undefined ||
            allRoles === undefined,
        [user, allIwi, allRoles]
    );

    return (
        <Grid component={Paper} style={paperStyle}>
            <Snackbar
                open={submitSuccess}
                onClose={handleSuccessAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleSuccessAlertClose}
                    variant="filled"
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                open={error !== undefined}
                onClose={handleErrorAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleErrorAlertClose}
                    variant="filled"
                    severity="error"
                >
                    {error?.message}
                </Alert>
            </Snackbar>

            {isLoading ? (
                <Box display="flex" justifyContent="center" m={1}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box m={1}>
                        <Typography variant="h5">
                            <FormattedMessage id={`${section}.Header`} />
                        </Typography>
                    </Box>

                    <Box m={1}>
                        <Divider />
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            <FormattedMessage id={`${section}.EmailAddress`} />
                        </Typography>
                        <Typography>{user?.email}</Typography>
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            <FormattedMessage id={`${section}.Name`} />
                        </Typography>
                        <TextField
                            id="user-name"
                            variant="standard"
                            value={name}
                            onChange={onChangeName}
                            fullWidth
                        />
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            <FormattedMessage id={`${section}.Iwi`} />
                        </Typography>
                        <Autocomplete
                            id="user-iwi"
                            options={allIwi ?? []}
                            getOptionLabel={(option) => option.name}
                            value={userIwi}
                            onChange={(event, value) => setUserIwi(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Add an Iwi"
                                />
                            )}
                        />
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            <FormattedMessage id={`${section}.Roles`} />
                        </Typography>
                        <Autocomplete
                            multiple
                            id="user-roles"
                            options={allRoles ?? []}
                            getOptionLabel={(option) => option}
                            value={userRoles}
                            onChange={(event, value) => setUserRoles(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Add a role"
                                />
                            )}
                        />
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            {`${intl.formatMessage({
                                id: `${section}.EmailConfirmed`,
                            })}?`}
                        </Typography>
                        <Checkbox
                            disabled
                            checked={emailConfirmed}
                            onChange={onChangeEmailConfirmed}
                        />
                    </Box>

                    <Box m={1}>
                        <Typography fontWeight="bold">
                            <FormattedMessage id={`${section}.Disabled`} />
                        </Typography>
                        <RadioGroup
                            row
                            value={disabled}
                            onChange={(event, value) => onChangeDisabled(value)}
                        >
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    </Box>

                    <Box m={1} component="span">
                        <Button
                            variant="contained"
                            startIcon={<Done />}
                            onClick={onSubmit}
                            disabled={error !== undefined || isLoading}
                        >
                            <FormattedMessage id={`Generic.Save`} />
                        </Button>
                    </Box>

                    <Box m={1} component="span">
                        <Button
                            variant="contained"
                            startIcon={<Close />}
                            onClick={onCancel}
                        >
                            <FormattedMessage id={`Generic.Cancel`} />
                        </Button>
                    </Box>

                    {!emailConfirmed && (
                        <Box m={1} component="span">
                            <Button
                                variant="outlined"
                                startIcon={<Email />}
                                onClick={sendInvite}
                                disabled={sendInviteLoading}
                            >
                                <FormattedMessage
                                    id={`${section}.ResendInvitation`}
                                />
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Grid>
    );

    function sendInvite() {
        setSendInviteLoading(true);
        apiPost({
            path: `Account/SendInvite?userId=${id}`,
            onSuccess: () => {
                setSuccessMessage(
                    intl.formatMessage({ id: `${section}.InvitationSent` })
                );
                setSubmitSuccess(true);
                setSendInviteLoading(false);
            },
            onError: () => {
                setError({
                    message: intl.formatMessage({
                        id: `${section}.InviteFailed`,
                    }),
                });
                setSendInviteLoading(false);
            },
        });
    }

    function getUser({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `User/GetById?id=${id}`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getAllRoles({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `Account/GetAllRoles`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function getAllIwi({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `IwiAdmin/GetAllIwi`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function submitUserUpdate({ onSuccess, onError }: FetchParams) {
        if (user === undefined) {
            const error: FetchError = {
                message: intl.formatMessage({ id: `${section}.UndefinedUser` }),
            };
            setError(error);
            return;
        }
        apiPost({
            path: `User/Update`,
            body: {
                id: user?.id,
                name: name,
                email: user?.email,
                disabled: disabled,
                roles: userRoles,
                emailConfirmed: user?.emailConfirmed,
                iwiId: userIwi?.id,
            },
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function onSubmitSuccess(response: any) {
        setSubmitSuccess(true);
        setSuccessMessage(
            intl.formatMessage({ id: `${section}.ChangesSaved` })
        );
    }

    function onChangeDisabled(value: string) {
        const bool = value === "true";
        setDisabled(bool);
    }

    function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function onSubmit() {
        submitUserUpdate({ onSuccess: onSubmitSuccess, onError: setError });
    }

    function onCancel() {
        navigate("/users");
    }

    function onChangeEmailConfirmed() {
        //Checkbox is disabled, this is required for a controlled checkbox
    }

    function handleSuccessAlertClose() {
        setSubmitSuccess(false);
    }

    function handleErrorAlertClose() {
        setError(undefined);
    }
}
