import {
    ChevronRight,
    Delete,
    ExpandMore,
    Folder,
    InsertDriveFile as File,
    Upload,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { TreeItem, TreeItemContentProps, useTreeItem } from "@mui/lab";
import TreeView from "@mui/lab/TreeView";
import {
    Box,
    BoxProps,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from "@mui/material";
import { height } from "@mui/system";
import _ from "lodash";
import React, { ChangeEvent } from "react";
import { IntlShape, useIntl } from "react-intl";

export interface FileManagerProps extends BoxProps {
    files: Item[];
    firstNodeExpanded?: boolean;
    shareable?: boolean;
    shared?: string[];
    onShareChange?: (shared: string[]) => void;
    uploadable?: boolean;
    onUpload?: (folderId: string) => void;
    deleteable?: boolean;
    onDelete?: (fileId: string) => void;
    downloadable?: boolean;
    onDownload?: (fileId: string) => void;
    categoryEditable?: boolean;
    categories?: DocumentCategory[];
    onCategoryEdit?: (fileId: string, id: number) => void;
    categoryFilter?: number;
    onCategoryFilterEdit?: (id: number) => void;
    sensitivityEditable?: boolean;
    sensitivities?: Sensitivity[];
    onSensitivityEdit?: (fileId: string, sensitivity: number) => void;
}

export type Item = FolderItem | FileItem;

export type FileItem = {
    id: string;
    fileName: string;
    categoryId?: number;
    sensitivity?: number;
};

export type FolderItem = {
    id: string;
    title: string;
    children: Item[];
};

export type DocumentCategory = {
    id: string;
    name: string;
};

export type Sensitivity = {
    sensitivity: number;
    name: string;
};

export function FileManager(props: FileManagerProps) {
    const {
        files,
        firstNodeExpanded,
        shareable,
        shared,
        onShareChange,
        uploadable,
        onUpload,
        deleteable,
        onDelete,
        downloadable,
        onDownload,
        categoryEditable,
        categories,
        onCategoryEdit,
        categoryFilter,
        onCategoryFilterEdit,
        sensitivityEditable,
        sensitivities,
        onSensitivityEdit,
        ...boxProps
    } = props;

    const CustomContent = React.forwardRef(TreeContent);
    const defaultExpanded = firstNodeExpanded ? ["0"] : [];
    const intl: IntlShape = useIntl();

    return (
        <Box {...boxProps}>
            {props.categories &&
                <Select
                    defaultValue={props.categoryFilter}
                    labelId="filter-label"
                    onChange={(e) => props.onCategoryFilterEdit && props.onCategoryFilterEdit(e.target.value as number)}
                    sx={{ mb: 2 }}
                >
                    <MenuItem value={-1} key={-1}>
                        <em>All Files</em>
                    </MenuItem>
                    {props.categories.map((category) =>
                        <MenuItem
                            value={category.id}
                            key={category.id}
                        >
                            {category.name}
                        </MenuItem>
                    )}
                </Select>
            }

            <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                defaultExpanded={defaultExpanded}
            >
                {files.map(getTreeItem)}
            </TreeView>
        </Box>
    );

    function getShared(item: Item): boolean {
        if (!("children" in item)) return shared?.includes(item.id) ?? false;
        const numShared = item.children
            .map((child) => getShared(child))
            .filter((isShared) => isShared).length;
        return numShared == item.children.length;
    }

    function getTreeItem(item: Item) {
        if ("children" in item) {
            return (
                <TreeItem
                    ContentComponent={CustomContent}
                    nodeId={`${item.id}`}
                    label={<FolderNode item={item} key={`Folder-${item.id}`} />}
                >
                    {item.children.map(getTreeItem)}
                </TreeItem>
            );
        }
        return (
            (props.categoryFilter == undefined || props.categoryFilter == -1
                || (item.categoryId && item.categoryId > 0 && item.categoryId == props.categoryFilter)) &&
            <TreeItem
                ContentComponent={CustomContent}
                nodeId={`${item.id}`}
                label={<FileNode item={item} key={`File-${item.id}`} />}
            />
        );
    }

    function FolderNode({ item }: { item: FolderItem }) {
        const isShared = getShared(item);
        const isEmpty = item.children.length == 0;

        return (
            <Box
                display="inline-flex"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="inline-flex" alignItems="center">
                    {shareable && (
                        <Checkbox
                            checked={isShared && !isEmpty}
                            icon={<VisibilityOff />}
                            checkedIcon={<Visibility />}
                            onChange={onShareClick}
                        />
                    )}
                    <Folder sx={{ p: 1 }} color="warning" />
                    <Typography>{item.title}</Typography>
                </Box>
                {uploadable && (
                    <IconButton onClick={onUploadClick}>
                        <Tooltip title={intl.formatMessage({
                            id: "Generic.FileRestrictionWarning",
                        })} placement="bottom" arrow >
                            <Upload />
                        </Tooltip>
                    </IconButton>
                )}
            </Box>
        );

        function getChildrenIds(item: FolderItem): string[] {
            const childrenIds = item.children.map((child) =>
                "children" in child ? getChildrenIds(child) : child.id
            );
            return childrenIds.flat();
        }

        function onUploadClick(event: React.MouseEvent<HTMLElement>) {
            if (!uploadable || !onUpload) return;
            onUpload(item.id);
            event.stopPropagation();
        }

        function onShareClick(
            event: ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) {
            if (!shared || !onShareChange) return;
            const ids = getChildrenIds(item);
            checked
                ? onShareChange(_.union(shared, ids))
                : onShareChange(_.difference(shared, ids));
            event.stopPropagation();
        }
    }

    function FileNode({ item }: { item: FileItem }) {
        return (
            <Box
                display="inline-flex"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="inline-flex" alignItems="center">
                    {shareable && (
                        <Checkbox
                            checked={getShared(item)}
                            icon={<VisibilityOff />}
                            checkedIcon={<Visibility />}
                            onChange={onShareClick}
                        />
                    )}
                    <File sx={{ p: 1 }} />
                    {downloadable ? (
                        <Link onClick={onDownloadClick}>{item.fileName}</Link>
                    ) : (
                        <Typography>{item.fileName}</Typography>
                    )}
                </Box>
                <Box display="inline-flex" alignItems="center">
                    {categoryEditable && (
                        <FormControl sx={{ m: 1, width: 300 }} size="small">
                            <InputLabel id="category-label">
                                Category
                            </InputLabel>
                            <Select
                                labelId="category-label"
                                label="Category"
                                value={item.categoryId}
                                onChange={onCategoryChange}
                            >
                                <MenuItem value={-1} key={-1}>
                                    <em>Not Set</em>
                                </MenuItem>
                                {categories?.map((category) => (
                                    <MenuItem
                                        value={category.id}
                                        key={category.id}
                                    >
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {sensitivityEditable && (
                        <FormControl sx={{ m: 1, width: 300 }} size="small">
                            <InputLabel id="sensitivity-label">
                                Sensitivity
                            </InputLabel>
                            <Select
                                labelId="sensitivity-label"
                                label="Sensitivity"
                                value={item.sensitivity ?? -1}
                                onChange={onSensitivityChange}
                            >
                                <MenuItem value={-1} key="None">
                                    <em>None</em>
                                </MenuItem>
                                {sensitivities?.map((sensitivity) => (
                                    <MenuItem
                                        value={sensitivity.sensitivity}
                                        key={sensitivity.name}
                                    >
                                        {sensitivity.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {deleteable && (
                        <IconButton onClick={onDeleteClick}>
                            <Delete />
                        </IconButton>
                    )}
                </Box>
            </Box>
        );

        function onCategoryChange(event: SelectChangeEvent<number>) {
            if (!categoryEditable || !onCategoryEdit) return;
            onCategoryEdit(item.id, event.target.value as number);
        }

        function onSensitivityChange(event: SelectChangeEvent<number>) {
            if (!sensitivityEditable || !onSensitivityEdit) return;
            onSensitivityEdit(item.id, event.target.value as number);
        }

        function onDeleteClick(event: React.MouseEvent<HTMLElement>) {
            if (!deleteable || !onDelete) return;
            onDelete(item.id);
            event.stopPropagation();
        }

        function onDownloadClick(event: React.MouseEvent<HTMLElement>) {
            if (!downloadable || !onDownload) return;
            onDownload(item.id);
            event.stopPropagation();
        }

        function onShareClick(
            event: ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) {
            if (!shared || !onShareChange) return;
            checked
                ? onShareChange(_.union(shared, [item.id]))
                : onShareChange(_.difference(shared, [item.id]));
            event.stopPropagation();
        }
    }

    function TreeContent(props: TreeItemContentProps, ref: React.Ref<any>) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;

        const { handleExpansion } = useTreeItem(nodeId);

        const icon = iconProp || expansionIcon || displayIcon;

        return (
            <Box className={className} ref={ref}>
                <Box
                    onClick={handleExpansion}
                    className={classes.iconContainer}
                >
                    {icon}
                </Box>
                <Typography className={classes.label} component="div">
                    {label}
                </Typography>
            </Box>
        );
    }
}
