export type TechnicalSiteAssessment = {
    mauri: TechnicalSiteAssessmentEntry;
    wahiTapu: TechnicalSiteAssessmentEntry;
    historical: TechnicalSiteAssessmentEntry;
    customaryResources: TechnicalSiteAssessmentEntry;
    customaryNeeds: TechnicalSiteAssessmentEntry;
    contemporaryEsteem: TechnicalSiteAssessmentEntry;
    horopaki: TechnicalSiteAssessmentEntry;
};

export type TechnicalSiteAssessmentEntry = {
    description: string;
    source: string;
    email: string;
    hasSensitiveInformation?: boolean;
    lastUpdate?: Date;
    comments: string;
    commentsLastUpdate?: Date;
};

export const DefaultTechnicalSiteAssessmentEntry: TechnicalSiteAssessmentEntry = {
    description: "",
    source: "",
    email: "",
    hasSensitiveInformation: undefined,
    lastUpdate: undefined,
    comments: "",
    commentsLastUpdate: undefined,
};

export const DefaultTechnicalSiteAssessment: TechnicalSiteAssessment = {
    mauri: DefaultTechnicalSiteAssessmentEntry,
    wahiTapu: DefaultTechnicalSiteAssessmentEntry,
    historical: DefaultTechnicalSiteAssessmentEntry,
    customaryResources: DefaultTechnicalSiteAssessmentEntry,
    customaryNeeds: DefaultTechnicalSiteAssessmentEntry,
    contemporaryEsteem: DefaultTechnicalSiteAssessmentEntry,
    horopaki: DefaultTechnicalSiteAssessmentEntry,
};


