import { Alert, AlertProps, Snackbar } from "@mui/material";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DefaultFutureThreats, FutureThreatsState } from "types/FutureThreats";

interface FutureThreatsContextState {
    futureThreats?: FutureThreatsState;
    setFutureThreats?: (value: FutureThreatsState) => void;
}

const contextDefaultValues: FutureThreatsContextState = {
    futureThreats: DefaultFutureThreats,
};

export const FutureThreatsContext =
    createContext<FutureThreatsContextState>(contextDefaultValues);

export function FutureThreatsProvider({ children }: { children: ReactNode }) {
    const [futureThreats, setFutureThreats] = useState<FutureThreatsState>();

    const { apiGet } = useApiFetch();
    const { id } = useParams();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        apiGet({
            path: `NominatedSite/GetFutureThreats?nominatedSiteId=${id}`,
            onSuccess: (data) => {
                setFutureThreats({
                    ...DefaultFutureThreats,
                    ...data,
                    customRatings: data.customRatings || [],
                });
            },
            onError: (error: FetchError) => {
                setSnackbar({ children: error.message, severity: "error" });
            },
        });
    }, []);

    return (
        <FutureThreatsContext.Provider
            value={{ futureThreats, setFutureThreats }}
        >
            {children}
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FutureThreatsContext.Provider>
    );
}
