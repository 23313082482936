import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Button, Stack } from "@mui/material";
import { MapUrlContext } from "context/mapUrlContext";
import { RoleContext } from "context/roleContext";
import { useCouncilMapUrl } from "hooks/useCouncilMapUrl";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";

type TaskMapsType = {
    mapUrl: string;
};

const section: string = "TaskMaps";

export function TaskMaps() {
    const roleContext = useContext(RoleContext);
    const mapUrlState = useContext(MapUrlContext);

    const isSysAdmin = roleContext.roles?.isSysAdmin;
    const isCouncilUser = roleContext.roles?.isCouncilUser;
    const councilMapUrl = useCouncilMapUrl();

    return (
        <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: 1, md: 1 }}
            sx={{
                flexWrap: "wrap",
            }}
        >
            <Button
                variant="contained"
                startIcon={<InsertPhotoIcon />}
                color="secondary"
                onClick={() => {
                    openMap(mapUrlState.mapUrl);
                }}
                disabled={!mapUrlState.mapUrl}
            >
                <FormattedMessage id={`${section}.OpenMap`} />
            </Button>

            {(isCouncilUser || isSysAdmin) && (
                <Button
                    variant="contained"
                    startIcon={<InsertPhotoIcon />}
                    onClick={() => {
                        openMap(councilMapUrl);
                    }}
                >
                    <FormattedMessage id={"CouncilMap"} />
                </Button>
            )}
        </Stack>
    );

    function openMap(url?: string) {
        if (!url) return;

        window.open(url, "_blank");
    }
}
