import { Stack } from "@mui/material";
import { CompletedDatesContext } from "context/completedDatesContext";
import { useContext } from "react";
import { CompletedDates } from "types/CompletedDates";
import { ProgressCard } from ".";

interface NominatedSiteProgressProps {
    completedDates?: CompletedDates;
    showText?: boolean;
}

export function NominatedSiteProgress(props: NominatedSiteProgressProps) {
    const completedDatesContext = useContext(CompletedDatesContext);

    const completedDates = props.completedDates
        ? props.completedDates
        : completedDatesContext.completedDates;

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
        >
            <ProgressCard
                title="Task1"
                date={completedDates?.assessmentProcessStarted}
                showText={props.showText}
            />
            <ProgressCard
                title="Task2Part2x2"
                date={completedDates?.traditionalKnowledgeComplete}
                showText={props.showText}
            />
            <ProgressCard
                title="Task2Part2x3"
                date={completedDates?.technicalSiteAssessmentComplete}
                showText={props.showText}
            />
            <ProgressCard
                title="Task3"
                date={completedDates?.siteVisitComplete}
                showText={props.showText}
            />
            <ProgressCard
                title="Task4"
                date={completedDates?.threatIssuesComplete}
                showText={props.showText}
            />
            <ProgressCard
                title="Task5"
                date={completedDates?.managementConfirmed}
                showText={props.showText}
            />
        </Stack>
    );
}
