import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { Pull } from "types/Pull";

export interface RefreshFromGisContextState extends BaseContextState {
    pull?: Pull;
    fetch?: () => void;
}

const contextDefaultValues: RefreshFromGisContextState = {
    pull: undefined,
    error: undefined,
    fetch: undefined,
    isLoading: false,
};

export const RefreshFromGisContext =
    createContext<RefreshFromGisContextState>(contextDefaultValues);

export function RefreshFromGisProvider({ children }: { children: ReactNode }) {
    const { apiPost } = useApiFetch();

    const [refreshFromGisState, setRefreshFromGisState] =
        useState<RefreshFromGisContextState>({
            ...contextDefaultValues,
            fetch: GetCouncilMapUrl,
        });

    function GetCouncilMapUrl() {
        setRefreshFromGisState({ ...contextDefaultValues, isLoading: true });

        apiPost({
            path: `Sync/Pull`,
            onSuccess: (data) => {
                setRefreshFromGisState({
                    ...contextDefaultValues,
                    fetch: GetCouncilMapUrl,
                    pull: data,
                    isLoading: false,
                });
                alert("Success: MHIDs refreshed");
            },
            onError: (error) => {
                setRefreshFromGisState({
                    ...contextDefaultValues,
                    fetch: GetCouncilMapUrl,
                    error: error,
                    isLoading: false,
                });
                alert("Refresh from GIS unsuccessful");
            },
        });
    }

    return (
        <RefreshFromGisContext.Provider value={refreshFromGisState}>
            {children}
        </RefreshFromGisContext.Provider>
    );
}
