import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createContext, ReactElement, ReactNode, useState } from "react";
import { IntlProvider } from "react-intl";
import { enNz } from "../content/en-nz";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

interface IntlWrapper {
    current: { locale: string; messages: Record<string, string> };
    update: (locale: Locales) => void;
}

export type Locales = "mi" | "en-nz";

const fetchMessages = (locale: Locales) => {
    switch (locale) {
        case "en-nz":
            return enNz;
        default:
            return enNz;
    }
};

export const IntlWrapperContext = createContext<IntlWrapper>({
    current: { locale: "en-nz", messages: enNz },
    update: fetchMessages,
});

export const IntlContainer = (props: {
    children?: ReactNode;
}): ReactElement => {
    const [userLocale, setUserLocale] = useState({
        locale: "en-nz",
        messages: enNz,
    });

    const updateContext = (locale: Locales) => {
        const newState = fetchMessages(locale);
        if (newState) setUserLocale({ locale: locale, messages: newState });
    };

    return (
        <IntlWrapperContext.Provider
            value={{ current: userLocale, update: updateContext }}
        >
            <IntlProvider {...userLocale}>
                <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={userLocale.locale}>
                    {props.children}
                </LocalizationProvider>
            </IntlProvider>
        </IntlWrapperContext.Provider>
    );
};
