import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import { TaskMaps } from "components/TaskMaps";
import { FormattedMessage } from "react-intl";
import { ManagementStatement } from "./ManagementStatement";
import { SupportingDocuments } from "./SupportingDocuments";

const section: string = "Task5.ManagementOutcomes";

export function ManagementOutcomes() {
    const theme = useTheme();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="management-outcomes-content"
                id="management-outcomes-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <TaskMaps />
                    </Grid>
                    <Grid item md={12}>
                        <ManagementStatement />
                    </Grid>
                    <Grid item md={12}>
                        <SupportingDocuments />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
