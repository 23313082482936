import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useContext, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { MapInformation } from "types/MapInformation";
import { CurrentUserContext } from "./currentUserContext";

interface CouncilTokenContextState extends BaseContextState {
    mapInformation?: MapInformation;
    fetch?: () => void;
}

const contextDefaultValues: CouncilTokenContextState = {
    mapInformation: undefined,
    error: undefined,
    isLoading: true,
    fetch: undefined,
};

export const CouncilTokenContext =
    createContext<CouncilTokenContextState>(contextDefaultValues);

export function CouncilTokenProvider({ children }: { children: ReactNode }) {
    const currentUserContext = useContext(CurrentUserContext);
    const { apiGet } = useApiFetch();

    const [councilMapState, setCouncilMapState] =
        useState<CouncilTokenContextState>({
            ...contextDefaultValues,
            fetch: getToken,
        });

    function getToken() {
        apiGet({
            path: `MapForCouncil/GetForCouncil`,
            onSuccess: (data) =>
                setCouncilMapState({
                    ...contextDefaultValues,
                    mapInformation: data,
                    isLoading: false,
                    fetch: getToken,
                }),
            onError: (error) =>
                setCouncilMapState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                    fetch: getToken,
                }),
        });
    }

    return (
        <CouncilTokenContext.Provider value={councilMapState}>
            {children}
        </CouncilTokenContext.Provider>
    );
}
