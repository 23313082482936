import { Grid, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ThreatAssessmentAddCustom } from "components/ThreatAssessment/ThreatAssessmentAddCustom";
import { ThreatAssessmentList } from "components/ThreatAssessment/ThreatAssessmentList";
import { ExistingImpactsContext } from "context/existingImpactsContext";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";

const section: string = "Task4.ExistingImpacts";

export function ExistingImpacts() {
    const theme = useTheme();

    const existingImpactsContext = useContext(ExistingImpactsContext);

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="existing-impacts-content"
                id="existing-impacts-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage id={`${section}.Description`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1">
                            <FormattedMessage
                                id={`${section}.ScaleDescription`}
                            />
                        </Typography>
                    </Grid>
                    {existingImpactsContext.existingImpacts &&
                        existingImpactsContext.setExistingImpacts && (
                            <>
                                <Grid item md={12}>
                                    <ThreatAssessmentList
                                        isFutureThreat={false}
                                        state={
                                            existingImpactsContext.existingImpacts
                                        }
                                        setState={
                                            existingImpactsContext.setExistingImpacts
                                        }
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <ThreatAssessmentAddCustom
                                        isFutureThreat={false}
                                        state={
                                            existingImpactsContext.existingImpacts
                                        }
                                        setState={
                                            existingImpactsContext.setExistingImpacts
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
