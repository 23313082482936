import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { LogoutPath } from "./NavMenu";

export interface MenuItemProps {
    title: string;
    visible: boolean;
    doNotTranslate?: boolean;
    link?: string | LogoutPath;
    isLinkLocal?: boolean;
}

interface NavButtonProps {
    menuItems: MenuItemProps[];
    renderButtonLabel: () => ReactElement<{}>;
    noDropdown?: boolean;
    doNotTranslate?: boolean;
    isButtonDisabled?: boolean;
}

const section: string = "Header";

export function NavButton(props: NavButtonProps) {
    const buttonLabel = props.renderButtonLabel();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disabled={props.isButtonDisabled}
                sx={{
                    color: "gray",
                    backgroundColor: "white",
                }}
                endIcon={!props.noDropdown && <ArrowDropDown />}
            >
                {props.noDropdown ? (
                    <NavLink to={`${props.menuItems[0].link}`}>
                        {buttonLabel}
                    </NavLink>
                ) : (
                    buttonLabel
                )}
            </Button>
            {!props.noDropdown && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {props.menuItems.map(
                        (menuItem) =>
                            menuItem.visible && (
                                <NavMenuItem
                                    key={menuItem.title}
                                    title={menuItem.title}
                                    visible={menuItem.visible}
                                    handleClose={handleClose}
                                    doNotTranslate={menuItem.doNotTranslate}
                                    link={menuItem.link}
                                    isLinkLocal={menuItem.isLinkLocal}
                                />
                            )
                    )}
                </Menu>
            )}
        </>
    );
}

interface NavMenuItemProps extends MenuItemProps {
    handleClose: () => void;
}

function NavMenuItem(props: NavMenuItemProps) {
    const link = props.link ?? `/${props.title}`;

    return (
        <div>
            <NavLink to={link} state={props.isLinkLocal && { local: true }}>
                <MenuItem
                    onClick={() => {
                        props.handleClose();
                    }}
                >
                    {props.doNotTranslate ? (
                        props.title
                    ) : (
                        <FormattedMessage id={`${section}.${props.title}`} />
                    )}
                </MenuItem>
            </NavLink>
        </div>
    );
}
