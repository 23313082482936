import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
    Alert,
    Button,
    IconButton,
    Paper,
    Skeleton,
    Snackbar,
    SnackbarOrigin,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    FetchError,
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import { useApiFetch } from "hooks/useApiFetch";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { User } from "types/User";

type TableDataState = {
    isLoading: boolean;
    users?: User[];
};

const section: string = "Users";

export default function UsersPage() {
    const paperStyle = { padding: "20px", width: "80vw", margin: "20px auto" };
    const [tableData, setTableData] = useState<TableDataState>({
        isLoading: true,
        users: undefined,
    });
    const [error, setError] = useState<FetchError>();

    const skeletonArray = Array(10).fill("");

    const { apiGet } = useApiFetch();

    const snackbarOrigin: SnackbarOrigin = {
        vertical: "bottom",
        horizontal: "center",
    };

    useEffect(() => {
        getAllUsers({ onSuccess: onGetSuccess, onError: setError });
    }, []);

    return (
        <TableContainer component={Paper} style={paperStyle}>
            <h2>Users</h2>

            <Snackbar
                open={error !== undefined}
                onClose={handleErrorAlertClose}
                anchorOrigin={snackbarOrigin}
            >
                <Alert
                    onClose={handleErrorAlertClose}
                    variant="filled"
                    severity="error"
                >
                    <>
                        Error loading users list, please try again.{" "}
                        {error?.message}
                    </>
                </Alert>
            </Snackbar>

            <Table className="classes.table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography fontWeight="bold">Email</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight="bold">Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight="bold">Iwi</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight="bold">Disabled</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.isLoading
                        ? skeletonArray.map((item, index) => (
                              <TableRow key={index}>
                                  <TableCell>
                                      <Skeleton />
                                  </TableCell>
                                  <TableCell>
                                      <Skeleton />
                                  </TableCell>
                                  <TableCell>
                                      <Skeleton />
                                  </TableCell>
                                  <TableCell>
                                      <Skeleton />
                                  </TableCell>
                                  <TableCell>
                                      <Skeleton />
                                  </TableCell>
                              </TableRow>
                          ))
                        : tableData.users?.map((row) => (
                              <TableRow key={row.id}>
                                  <TableCell>
                                      <IconButton
                                          component={Link}
                                          to={`/edituser/${row.id}`}
                                          aria-label="edit user"
                                      >
                                          <EditIcon />
                                      </IconButton>
                                  </TableCell>
                                  <TableCell>{row.email}</TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{row.iwiName ?? "None"}</TableCell>
                                  <TableCell>
                                      {row.disabled ? "Yes" : "No"}
                                  </TableCell>
                              </TableRow>
                          ))}
                </TableBody>
            </Table>
            <Button
                sx={{ marginTop: 3 }}
                variant="outlined"
                startIcon={<AddIcon />}
                component={Link}
                to="AddUser"
            >
                <FormattedMessage id={`${section}.AddUser`} />
            </Button>
        </TableContainer>
    );

    function handleErrorAlertClose() {
        setError(undefined);
    }

    function getAllUsers({ onSuccess, onError }: FetchParams) {
        apiGet({
            path: `User/GetAllUsers`,
            onSuccess,
            onError: (error) => onError && onError(error),
        });
    }

    function onGetSuccess(data: User[]) {
        setTableData({ isLoading: false, users: data });
    }
}
