import { AlertLayerUrlContext } from "context/alertLayerUrlContext";
import { CouncilTokenContext } from "context/councilTokenContext";
import { CurrentUserContext } from "context/currentUserContext";
import { IwiTokenContext } from "context/iwiTokenContext";
import { RoleContext } from "context/roleContext";
import { useContext, useEffect, useState } from "react";

export function useAlertLayerUrl() {
    const [alertLayerUrl, setAlertLayerUrl] = useState<string>("");

    const alertLayerUrlContext = useContext(AlertLayerUrlContext);
    const roleContext = useContext(RoleContext);
    const currentUserContext = useContext(CurrentUserContext);
    const councilTokenContext = useContext(CouncilTokenContext);
    const iwiTokenContext = useContext(IwiTokenContext);

    useEffect(() => {
        if (!roleContext.roles || !currentUserContext.currentUser) return;

        if (
            roleContext.roles.isSysAdmin ||
            roleContext.roles.isCouncilUser
        ) {
            councilTokenContext?.fetch && councilTokenContext.fetch();
        } else if (roleContext.roles.isIwiUser) {
            iwiTokenContext?.fetch &&
                iwiTokenContext.fetch(currentUserContext.currentUser.iwiId);
        }
    }, [roleContext.roles, currentUserContext.currentUser?.iwiId]);

    useEffect(() => {
        setAlertLayerUrl(
            `${alertLayerUrlContext.alertLayerDetails?.url}?id=${
                alertLayerUrlContext.alertLayerDetails?.id
            }&token=${
                roleContext.roles?.isIwiUser
                    ? iwiTokenContext.mapInformation?.esriToken.token
                    : councilTokenContext.mapInformation?.esriToken.token
            }`
        );
    }, [
        alertLayerUrlContext.alertLayerDetails,
        councilTokenContext.mapInformation?.esriToken.token,
        iwiTokenContext.mapInformation?.esriToken.token,
    ]);

    return alertLayerUrl;
}
