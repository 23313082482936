import { useEffect } from "react";

const Page = (props: any) => {
    useEffect(() => {
        document.title =
            `${props.title} - ${props.suffix}` ||
            "Māori Cultural Heritage Project";
    }, [props.title]);
    return props.children;
};

export default Page;
