import { Box, Stack, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

const section: string = "Task3.LandownerEngagementBlurb";

export function LandownerEngagementBlurb() {
    const theme = useTheme();

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="task3-landowner-engagement-blurb"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Typography variant="h5" align="left">
                    <FormattedMessage id={`${section}.SectionTitle`} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <Typography variant="body1">
                        <FormattedMessage id={`${section}.ReferToGuide`} />
                    </Typography>
                    <Typography variant="h6">
                        <FormattedMessage id={`${section}.PurposeHeader`} />
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage id={`${section}.PurposeDescription`} />
                        <Box />
                        <FormattedMessage id={`${section}.ListHeading`} />
                        <ul>
                            <li>
                                <FormattedMessage id={`${section}.List1`} />
                            </li>
                            <li>
                                <FormattedMessage id={`${section}.List2`} />
                            </li>
                            <li>
                                <FormattedMessage id={`${section}.List3`} />
                            </li>
                            <li>
                                <FormattedMessage id={`${section}.List4`} />
                                <ul>
                                    <li>
                                        <FormattedMessage id={`${section}.List4a`} />
                                    </li>
                                    <li>
                                        <FormattedMessage id={`${section}.List4b`} />
                                    </li>
                                    <li>
                                        <FormattedMessage id={`${section}.List4c`} />
                                    </li>
                                    <li>
                                        <FormattedMessage id={`${section}.List4d`} />
                                    </li>
                                    <li>
                                        <FormattedMessage id={`${section}.List4e`} />
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <FormattedMessage id={`${section}.List5`} />
                            </li>
                            <li>
                                <FormattedMessage id={`${section}.List6`} />
                            </li>
                        </ul>
                    </Typography>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
