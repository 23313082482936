import { useApiFetch } from "hooks/useApiFetch";
import { createContext, ReactNode, useContext, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { MapInformation } from "types/MapInformation";
import { CurrentUserContext } from "./currentUserContext";

interface IwiTokenContextState extends BaseContextState {
    mapInformation?: MapInformation;
    fetch?: (id?: number | null) => void;
}

const contextDefaultValues: IwiTokenContextState = {
    mapInformation: undefined,
    error: undefined,
    isLoading: true,
    fetch: undefined,
};

export const IwiTokenContext =
    createContext<IwiTokenContextState>(contextDefaultValues);

export function IwiTokenProvider({ children }: { children: ReactNode }) {
    const currentUserContext = useContext(CurrentUserContext);
    const { apiGet } = useApiFetch();

    const [iwiMapState, setIwiMapState] = useState<IwiTokenContextState>({
        ...contextDefaultValues,
        fetch: getToken,
    });

    function getToken(id?: number | null) {
        apiGet({
            path: `MapForIwi/GetForIwi?id=${id}&mr=false`,
            onSuccess: (data) =>
                setIwiMapState({
                    ...contextDefaultValues,
                    mapInformation: data,
                    isLoading: false,
                    fetch: getToken,
                }),
            onError: (error) =>
                setIwiMapState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                    fetch: getToken,
                }),
        });
    }

    return (
        <IwiTokenContext.Provider value={iwiMapState}>
            {children}
        </IwiTokenContext.Provider>
    );
}
