import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormLabel,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ExistingImpactsState } from "types/ExistingImpacts";
import { FutureThreatsState } from "types/FutureThreats";
import { RadioGroupInput } from "./RadioGroupInput";

interface ThreatAssessmentListInterface {
    isFutureThreat: boolean; // true if future threat, false if existing impact
    state: FutureThreatsState & ExistingImpactsState;
    setState: (value: FutureThreatsState & ExistingImpactsState) => void;
}

export function ThreatAssessmentList(props: ThreatAssessmentListInterface) {
    const theme = useTheme();
    const section: string = props.isFutureThreat
        ? "Task4.FutureThreats"
        : "Task4.ExistingImpacts";

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <InputRow
                    section={section}
                    name="earthworks"
                    onChange={onChange}
                    value={props.state.earthworks}
                />
                <InputRow
                    section={section}
                    name="placementOfUtilities"
                    onChange={onChange}
                    value={props.state.placementOfUtilities}
                />
                <InputRow
                    section={section}
                    name="stormwaterManagement"
                    onChange={onChange}
                    value={props.state.stormwaterManagement}
                />
                <InputRow
                    section={section}
                    name="onSiteWastewater"
                    onChange={onChange}
                    value={props.state.onSiteWastewater}
                />
                {props.isFutureThreat && (
                    <InputRow
                        section={section}
                        name="wastewaterNetworks"
                        onChange={onChange}
                        value={props.state.wastewaterNetworks}
                    />
                )}
                <InputRow
                    section={section}
                    name="otherDischarges"
                    onChange={onChange}
                    value={props.state.otherDischarges}
                />
                <InputRow
                    section={section}
                    name="groundwaterTake"
                    onChange={onChange}
                    value={props.state.groundwaterTake}
                />
                <InputRow
                    section={section}
                    name="dammingAndDiversionOfWater"
                    onChange={onChange}
                    value={props.state.dammingAndDiversionOfWater}
                />
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            sx={{
                                bgcolor: theme.accordion.bgcolor,
                                color: theme.accordion.color,
                                boxShadow: theme.accordion.boxShadow,
                            }}
                        >
                            <Typography variant="h6">
                                <FormattedMessage id="Task4.harbourHealthActivities" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            id={`harbour-health-activities-contents`}
                        >
                            <Grid container spacing={1} alignItems="center">
                                <InputRow
                                    section={section}
                                    name="roadRunoff"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.roadRunoff}
                                />
                                <InputRow
                                    section={section}
                                    name="industrialDischarges"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.industrialDischarges}
                                />
                                <InputRow
                                    section={section}
                                    name="litter"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.litter}
                                />
                                <InputRow
                                    section={section}
                                    name="wastewaterOverflows"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.wastewaterOverflows}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {props.isFutureThreat && (
                    <InputRow
                        section={section}
                        name="waterQualityStandards"
                        onChange={onChange}
                        value={props.state.waterQualityStandards}
                    />
                )}
                <InputRow
                    section={section}
                    name="landfills"
                    onChange={onChange}
                    value={props.state.landfills}
                />
                <InputRow
                    section={section}
                    name="changesToLakesRiversAndWetland"
                    onChange={onChange}
                    value={props.state.changesToLakesRiversAndWetland}
                />
                <InputRow
                    section={section}
                    name="changesToCostalArea"
                    onChange={onChange}
                    value={props.state.changesToCostalArea}
                />
                <InputRow
                    section={section}
                    name="vegetationRemoval"
                    onChange={onChange}
                    value={props.state.vegetationRemoval}
                />
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            sx={{
                                bgcolor: theme.accordion.bgcolor,
                                color: theme.accordion.color,
                                boxShadow: theme.accordion.boxShadow,
                            }}
                        >
                            <Typography variant="h6">
                                <FormattedMessage id="Task4.landuseActivities" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails id={`landuse-activities-contents`}>
                            <Grid container spacing={0} alignItems="center">
                                <InputRow
                                    section={section}
                                    name="subdivision"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.subdivision}
                                />
                                <InputRow
                                    section={section}
                                    name="newBuilding"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.newBuilding}
                                />
                                <InputRow
                                    section={section}
                                    name="alterationsToExistingBuildings"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={
                                        props.state
                                            .alterationsToExistingBuildings
                                    }
                                />
                                {props.isFutureThreat && (
                                    <InputRow
                                        section={section}
                                        name="intensificationOfHousing"
                                        formLabelWidth={3}
                                        onChange={onChange}
                                        value={
                                            props.state.intensificationOfHousing
                                        }
                                    />
                                )}
                                <InputRow
                                    section={section}
                                    name="constructionOfResidentialUnits"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={
                                        props.state
                                            .constructionOfResidentialUnits
                                    }
                                />
                                <InputRow
                                    section={section}
                                    name="schoolsHomesRetirementVillages"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={
                                        props.state
                                            .schoolsHomesRetirementVillages
                                    }
                                />
                                <InputRow
                                    section={section}
                                    name="healthcareServices"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.healthcareServices}
                                />
                                <InputRow
                                    section={section}
                                    name="campingGrounds"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.campingGrounds}
                                />
                                <InputRow
                                    section={section}
                                    name="communityFacilities"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.communityFacilities}
                                />
                                <InputRow
                                    section={section}
                                    name="cafesRestaurants"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.cafesRestaurants}
                                />
                                <InputRow
                                    section={section}
                                    name="horticulture"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.horticulture}
                                />
                                <InputRow
                                    section={section}
                                    name="serviceStations"
                                    formLabelWidth={3}
                                    onChange={onChange}
                                    value={props.state.serviceStations}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );

    function onChange(
        property: keyof FutureThreatsState | keyof ExistingImpactsState,
        value: string,
        index?: number
    ) {
        props.setState({ ...props.state, [property]: parseInt(value) });
    }
}

interface InputRowProps {
    section: string;
    name: keyof FutureThreatsState | keyof ExistingImpactsState;
    formLabelWidth?: number;
    value?: number | null;
    onChange: (
        property: keyof FutureThreatsState | keyof ExistingImpactsState,
        value: string,
        index?: number
    ) => void;
}

export function InputRow({
    section,
    name,
    formLabelWidth = 4,
    value,
    onChange,
}: InputRowProps) {
    return (
        <>
            <Grid item xs={formLabelWidth}>
                <FormLabel id="radio-buttons-group-label">
                    <Typography>
                        <FormattedMessage id={`${section}.${name}`} />
                    </Typography>
                </FormLabel>
            </Grid>
            <Grid item xs={12 - formLabelWidth}>
                <RadioGroupInput
                    name={name}
                    value={value}
                    onChange={onChange}
                />
            </Grid>
        </>
    );
}
