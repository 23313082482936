import { Stack, Typography } from "@mui/material";
import { CurrentUserContext } from "context/currentUserContext";
import { RoleContext } from "context/roleContext";
import { useIwiList } from "hooks/useIwiList";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";

const section: string = "MyDetails";

export function MyDetails() {
    const currentUserContext = useContext(CurrentUserContext);
    const roleContext = useContext(RoleContext);
    const iwiList = useIwiList();

    const isCouncilOrAdmin: boolean = roleContext.isCouncilOrAdmin;

    return (
        <Stack spacing={3}>
            <Typography variant="h4">
                <FormattedMessage id={`${section}.Heading`} />
            </Typography>
            <Typography variant="h6">
                <FormattedMessage id={`${section}.Name`} />
            </Typography>
            <Typography variant="body1">
                {currentUserContext.currentUser?.name}
            </Typography>
            <Typography variant="h6">
                <FormattedMessage id={`${section}.Email`} />
            </Typography>
            <Typography variant="body1">
                {currentUserContext.currentUser?.email}
            </Typography>
            <Typography variant="h6">
                <FormattedMessage id={`${section}.Iwi`} />
            </Typography>
            <Stack spacing={0}>
                {isCouncilOrAdmin && (
                    <Typography variant="body1">
                        <FormattedMessage id={`${section}.Council`} />
                    </Typography>
                )}
                {iwiList.map((iwi) => (
                    <Typography variant="body1">{iwi.name}</Typography>
                ))}
            </Stack>
        </Stack>
    );
}
