import { createContext, ReactNode, useEffect, useState } from "react";
import { BaseContextState } from "types/BaseContextState";
import { User } from "types/User";
import { useApiFetch } from "../hooks/useApiFetch";

interface CurrentUserContextState extends BaseContextState {
    currentUser: User | null;
}

const contextDefaultValues: CurrentUserContextState = {
    currentUser: null,
    error: undefined,
    isLoading: true,
};

export const CurrentUserContext =
    createContext<CurrentUserContextState>(contextDefaultValues);

export function CurrentUserProvider({ children }: { children: ReactNode }) {
    const { apiGet } = useApiFetch();

    const [currentUserState, setCurrentUserState] =
        useState<CurrentUserContextState>(contextDefaultValues);

    useEffect(() => {
        apiGet({
            path: `User/GetCurrentUser`,
            onSuccess: (data) =>
                setCurrentUserState({
                    ...contextDefaultValues,
                    currentUser: data,
                    isLoading: false,
                }),
            onError: (error) =>
                setCurrentUserState({
                    ...contextDefaultValues,
                    error: error,
                    isLoading: false,
                }),
        });
    }, []);

    return (
        <CurrentUserContext.Provider value={currentUserState}>
            {children}
        </CurrentUserContext.Provider>
    );
}
