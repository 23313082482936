import { Grid, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
    FileManagerState,
    FileManagerWrapper,
} from "components/FileManager/FileManagerWrapper";
import ProgressInput from "components/ProgressInput";
import { RoleContext } from "context/roleContext";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";

interface GovernanceCommunicationProps {
    completedDates?: CompletedDates;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
}

const section: string = "Task3.GovernanceCommunication";

export function GovernanceCommunication({
    completedDates,
    progressOnClick,
}: GovernanceCommunicationProps) {
    const [fileState, setFileState] = useState<FileManagerState>({
        files: [],
        numberOfFiles: 0,
        uploading: false
    });
    const theme = useTheme();

    const roleContext = useContext(RoleContext);
    const canAddOrDelete: boolean =
        roleContext.roles?.isSysAdmin ||
        roleContext.roles?.isCouncilUser ||
        roleContext.roles?.isIwiUser ||
        false;

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="task3-governance-communication"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>

                    <Typography component="h1" variant="h5" align="right">
                        {fileState.numberOfFiles}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <FileManagerWrapper
                            documentType="LandOwnerCommunication"
                            state={fileState}
                            setState={setFileState}
                            enableAddFile={canAddOrDelete}
                            disableDeleteFile={!canAddOrDelete}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ProgressInput
                            title="governanceNotified"
                            field={DateCompletedField.GovernanceNotified}
                            dateCompleted={completedDates?.governanceNotified}
                            progressOnClick={progressOnClick}
                            editable
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
