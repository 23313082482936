import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { RoleContext } from "context/roleContext";
import { ChangeEvent, useContext } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { ManagementResponseType } from "types/ManagementResponse";

interface ManagementResponseFormProps {
    state: ManagementResponseType;
    setState: (value: ManagementResponseType) => void;
}

const section: string = "Task5.ManagementResponse";

export function ManagementResponseForm(props: ManagementResponseFormProps) {
    const theme = useTheme();
    const intl: IntlShape = useIntl();

    const roleContext = useContext(RoleContext);
    const isCouncilOrAdmin: boolean = roleContext.isCouncilOrAdmin;

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                aria-controls="management-response-content"
                id="management-response-header"
                sx={{
                    bgcolor: theme.accordion.bgcolor,
                    color: theme.accordion.color,
                    boxShadow: theme.accordion.boxShadow,
                }}
            >
                <Grid container justifyContent="space-between">
                    <Typography component="h1" variant="h5" align="left">
                        <FormattedMessage id={`${section}.SectionTitle`} />
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "naming")
                                        }
                                        checked={props.state.naming}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.naming`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "activeProtectionLayerAlertLayer"
                                            )
                                        }
                                        checked={
                                            props.state
                                                .activeProtectionLayerAlertLayer
                                        }
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.activeProtectionLayerAlertLayer`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "iwiManagementPlanSchedule"
                                            )
                                        }
                                        checked={
                                            props.state
                                                .iwiManagementPlanSchedule
                                        }
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.iwiManagementPlanSchedule`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "hnzptListing")
                                        }
                                        checked={props.state.hnzptListing}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.hnzptListing`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "landAcquisition"
                                            )
                                        }
                                        checked={props.state.landAcquisition}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.landAcquisition`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "transferOfPowers"
                                            )
                                        }
                                        checked={props.state.transferOfPowers}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.transferOfPowers`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "heritageOrders")
                                        }
                                        checked={props.state.heritageOrders}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.heritageOrders`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "jointManagementAgreement"
                                            )
                                        }
                                        checked={
                                            props.state.jointManagementAgreement
                                        }
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.jointManagementAgreement`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(
                                                e,
                                                "coManagementGovernance"
                                            )
                                        }
                                        checked={
                                            props.state.coManagementGovernance
                                        }
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.coManagementGovernance`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "covenant")
                                        }
                                        checked={props.state.covenant}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.covenant`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "reserve")
                                        }
                                        checked={props.state.reserve}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.reserve`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "managementPlan")
                                        }
                                        checked={props.state.managementPlan}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.managementPlan`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "precinct")
                                        }
                                        checked={props.state.precinct}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.precinct`,
                                })}
                            />
                        </Stack>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component="h1" variant="h6">
                            <FormattedMessage id={`${section}.scheduling`} />
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule6")
                                        }
                                        checked={props.state.schedule6}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule6`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule7")
                                        }
                                        checked={props.state.schedule7}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule7`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule9")
                                        }
                                        checked={props.state.schedule9}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule9`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule11")
                                        }
                                        checked={props.state.schedule11}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule11`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule12")
                                        }
                                        checked={props.state.schedule12}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule12`,
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!isCouncilOrAdmin}
                                        onChange={(e) =>
                                            onChangeBoolean(e, "schedule14")
                                        }
                                        checked={props.state.schedule14}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: `${section}.schedule14`,
                                })}
                            />
                        </Stack>
                    </Grid>
                    <Grid item md={12}>
                        <Grid item md={12}>
                            <TextField
                                multiline
                                fullWidth
                                disabled={!isCouncilOrAdmin}
                                label={intl.formatMessage({
                                    id: `${section}.summary`,
                                })}
                                value={props.state.summary}
                                onChange={(event) =>
                                    onChangeEntry("summary", event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    function onChangeBoolean(
        event: ChangeEvent<HTMLInputElement>,
        property: keyof ManagementResponseType
    ) {
        props.setState({ ...props.state, [property]: event.target.checked });
    }

    function onChangeEntry(
        property: keyof ManagementResponseType,
        value: string | boolean | undefined
    ) {
        props.setState({ ...props.state, [property]: value });
    }
}
