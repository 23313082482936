import useFetchHook, {
    FetchParams,
} from "components/@beca-common-react-legacy/useFetchHook";
import authService from "components/api-authorization/AuthorizeService";

interface ApiFetchParams extends FetchParams {
    path: string;
}

export function useApiFetch() {
    const { useFetch: fetch } = useFetchHook();

    return { apiGet, apiPost, apiPatch, apiDelete };

    function apiGet(props: ApiFetchParams) {
        apiQuery(props);
    }

    function apiPost(props: ApiFetchParams) {
        apiQuery({
            ...props,
            method: "POST",
        });
    }

    function apiPatch(props: ApiFetchParams) {
        apiQuery({
            ...props,
            method: "PATCH",
        });
    }

    function apiDelete(props: ApiFetchParams) {
        apiQuery({
            ...props,
            method: "DELETE",
        });
    }

    function apiQuery(props: ApiFetchParams) {
        authService.getAccessToken().then((token) => {
            const apiUrl =
                window.location.hostname == "localhost"
                    ? "https://localhost:7059/api"
                    : `${window.location.origin}/api`;

            fetch({
                ...props,
                url: `${apiUrl}/${props.path}`,
                bearer: token,
            });
        });
    }
}
