import { ChevronRight, Menu } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RefreshFromGisContextState } from "context/refreshFromGisContext";
import { useState } from "react";
import { Iwi } from "types/Iwi";
import { Roles } from "types/Roles";
import { NavMenu } from "./NavMenu";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export interface ResponsiveMenuProps {
    roles?: Roles;
    iwiList: Iwi[];
    councilMapUrl?: string;
    alertLayerUrl?: string;
    refreshFromGisContext?: RefreshFromGisContextState;
    dataGridIwi?: Iwi;
}

export function ResponsiveMenu(props: ResponsiveMenuProps) {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
                <NavMenu
                    roles={props.roles}
                    iwiList={props.iwiList}
                    councilMapUrl={props.councilMapUrl}
                    alertLayerUrl={props.alertLayerUrl}
                    refreshFromGisContext={props.refreshFromGisContext}
                    dataGridIwi={props.dataGridIwi}
                />
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Drawer
                sx={{
                    width: 240,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 240,
                        boxSizing: "border-box",
                    },
                    mr: 2,
                    display: { md: "none" },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronRight />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <NavMenu
                    roles={props.roles}
                    iwiList={props.iwiList}
                    councilMapUrl={props.councilMapUrl}
                    alertLayerUrl={props.alertLayerUrl}
                    refreshFromGisContext={props.refreshFromGisContext}
                    dataGridIwi={props.dataGridIwi}
                />
            </Drawer>
            <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{ mr: 2, display: { md: "none" } }}
            >
                <Menu />
            </IconButton>
        </Box>
    );
}
