import { Pending } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Alert, AlertProps, Button, Snackbar, Tooltip } from "@mui/material";
import axios from "axios";
import { FetchError } from "components/@beca-common-react-legacy/useFetchHook";
import {
    FileItem,
    FileManager,
    Item,
    Sensitivity,
} from "components/FileManager/FileManager";
import { useApiFetch } from "hooks/useApiFetch";
import useDocumentUploader, { FileCreatedDto } from "hooks/useDocumentUploader";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ValidFileExtension } from "types/ValidFileExtension";

export type FileManagerState = {
    files: Item[];
    uploading: boolean;
    numberOfFiles: number;
};

interface FileManagerWrapperProps {
    documentType: string;
    state: FileManagerState;
    setState: (value: FileManagerState) => void;
    enableAddFile: boolean;
    disableDeleteFile: boolean;
    sensitivityEditable?: boolean,
    categoryEditable?: boolean
}

const defaultSensitivities: Sensitivity[] = [
    {
        sensitivity: 0,
        name: "Public",
    },
    {
        sensitivity: 1,
        name: "Restricted",
    },
    {
        sensitivity: 2,
        name: "Mana Whenua Only",
    },
];

export function FileManagerWrapper(props: FileManagerWrapperProps) {
    const { apiGet, apiPost, apiDelete } = useApiFetch();
    const inputFile = useRef<HTMLInputElement>(null);
    const { id } = useParams();

    const [extensions, setExtensions] = useState<string>();
    const intl: IntlShape = useIntl();
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        "children" | "severity"
    > | null>(null);
    const [sensitivities, setSensitivities] = useState(defaultSensitivities);

    const handleCloseSnackbar = () => setSnackbar(null);

    const { uploadDocuments } = useDocumentUploader({
        config: {
            siteId: id,
            documentTypeName: props.documentType
        },
        onError: (error) => handleError(error),
        onSuccess: (files) => onUploadSuccess(files)
    }
    )

    useEffect(() => {
        getFiles();
        getValidFileExtensions();
    }, []);

    return (
        <>
            {props.state.files && (
                <FileManager
                    files={props.state.files}
                    deleteable={!props.disableDeleteFile}
                    downloadable
                    onDownload={onDownload}
                    onDelete={onDelete}
                    sensitivityEditable={props.sensitivityEditable}
                    sensitivities={sensitivities}
                    onSensitivityEdit={onSensitivityEdit}
                    categoryEditable={props.categoryEditable}
                />
            )}
            <input
                type="file"
                id="file"
                ref={inputFile}
                accept={extensions}
                style={{ display: "none" }}
                onChange={(event) => onFileChange(event)}
                multiple
            />
            {props.enableAddFile && (
                <Tooltip title={intl.formatMessage({
                    id: "Generic.FileRestrictionWarning",
                })} placement="bottom" arrow>
                    <Button
                        fullWidth
                        variant="contained"
                        startIcon={props.state.uploading ? <Pending /> : <AddIcon />}
                        onClick={onUpload}
                        disabled={props.state.uploading}
                    >
                        {props.state.uploading ? (
                            <FormattedMessage id={`Generic.UploadingFile`} />
                        ) : (
                            <FormattedMessage id={`Generic.AddFile`} />
                        )}
                    </Button>
                </Tooltip>
            )}
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );

    function getFiles() {
        apiGet({
            path: `NotifiedSite/${id}/Document/Type/${props.documentType}`,
            onSuccess: (data) => onLoadSuccess(data),
            onError: (error) => handleFetchError(error),
        });
    }

    function onFileChange(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) return;

        let files = Array.from(event.target.files);

        uploadDocuments(files);

        props.setState({
            ...props.state,
            uploading: true,
        });
    }

    function onUpload() {
        inputFile.current?.click();
    }

    function onUploadSuccess(files: FileCreatedDto[]) {
        setSnackbar({
            children: `${files.length > 1 ? `${files.length} Files` : files[0].fileName} uploaded`,
            severity: "success",
        });
        props.setState({
            ...props.state,
            uploading: false
        });
        getFiles();
    }

    async function onDownload(fileId: string) {
        const file = props.state.files.find((x) => x.id === fileId) as FileItem;
        const filename = file.fileName;

        apiGet({
            path: encodeFileUrl(file, id!, "Document/Download"),
            onSuccess: (url) => axios.get(url, { responseType: "blob" })
                .then((data) => onDownloadSuccess(filename, data.data))
                .catch((error) => handleError(error)),
            onError: (error) => handleFetchError(error)
        });
    }

    function onDownloadSuccess(filename: string, data: Blob) {
        const blobAsFile = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = blobAsFile;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(blobAsFile);
        document.body.removeChild(a);
    }

    function onLoadSuccess(data: any) {
        const dataArray = data as Item[];
        console.log("FETCHED DATA:", dataArray);
        props.setState({
            files: dataArray,
            uploading: props.state.uploading,
            numberOfFiles: dataArray.length,
        });
    }

    function handleFetchError(error: FetchError) {
        handleError(error.message);
    }

    function handleError(error: string) {
        setSnackbar({
            children: error.includes("Request body too large") ? intl.formatMessage({
                id: "Generic.FileSizeLimitWarning",
            }) : error, severity: "error"
        });
        props.setState({
            ...props.state,
            uploading: false
        });
    }

    function onDelete(fileId: string) {
        const file = props.state.files.find((x) => x.id == fileId) as FileItem;
        const filename = file.fileName;
        apiDelete({
            path: `NotifiedSite/${id}/Document?fileName=${filename}`,
            onSuccess: () => onDeleteSuccess(filename),
            onError: handleFetchError,
        });
    }

    function onDeleteSuccess(filename: string) {
        setSnackbar({
            children: `${filename} deleted`,
            severity: "success",
        });
        getFiles();
    }

    function onSensitivityEdit(fileId: string, sensitivity: number) {
        var file = props.state.files.find((x) => x.id == fileId) as FileItem;
        const filename = file.fileName;
        file.sensitivity = sensitivity;

        var newFiles = props.state.files;
        var fileIndex = newFiles.findIndex((x) => x.id == fileId);
        newFiles[fileIndex] = file;

        props.setState({ ...props.state, files: newFiles });

        apiPost({
            path: encodeFileUrl(file, id!, "Document/Sensitivity"),
            body: {
                Sensitivity: sensitivity,
            },
            onSuccess: () => onSensitivityEditSuccess(filename),
            onError: handleFetchError,
        });
    }

    function onSensitivityEditSuccess(filename: string) {
        setSnackbar({
            children: `Sensitivity of ${filename} updated successfully`,
            severity: "success",
        });
        getFiles();
    }

    function getValidFileExtensions() {
        apiGet({
            path: "ValidFileExtension/List",
            onSuccess: (data) => onValidFileExtensionsGetSuccess(data),
            onError: handleFetchError,
        });
    }

    function onValidFileExtensionsGetSuccess(data: ValidFileExtension[]) {
        const extensions = data.map((e) => `.${e.extension.trim()}`).join(", ");
        setExtensions(extensions);
    }
}

export function encodeFileUrl(file: FileItem, siteId: string, endpoint: string) {
    const fileSearchParams = new URLSearchParams({ fileName: file.fileName });
    return `NotifiedSite/${siteId}/${endpoint}?${fileSearchParams}`;
}
