import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormattedMessage } from "react-intl";
import { CompletedDates } from "types/CompletedDates";
import { DateCompletedField, EditDateCompleted } from "types/EditDateCompleted";

const section: string = "ProgressInput";

interface ProgressInputProps {
    title: keyof CompletedDates;
    useStartIcon?: boolean;
    field: DateCompletedField;
    dateCompleted?: Date;
    progressOnClick?: (
        record: boolean,
        title: keyof CompletedDates,
        data: EditDateCompleted
    ) => void;
    editable?: boolean;
}

export default function ProgressInput(props: ProgressInputProps) {
    return (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                marginBottom: 2,
                marginTop: 2,
            }}
        >
            <Button
                variant="outlined"
                disabled={
                    props.dateCompleted != undefined || !props.editable
                }
                startIcon={
                    props.useStartIcon ? (
                        <PlayArrowIcon />
                    ) : (
                        <CheckCircleOutlineIcon />
                    )
                }
                size="small"
                onClick={() => {
                    props.progressOnClick &&
                        props.progressOnClick(true, props.title, {
                            field: props.field,
                            date: new Date(),
                        });
                }}
            >
                <FormattedMessage id={`${section}.${props.title}`} />
            </Button>
            <DatePicker
                label="dd/mm/yyyy"
                value={props.dateCompleted}
                disabled={!props.editable}
                onChange={(newValue: Date | null) => {
                    props.progressOnClick &&
                        props.progressOnClick(false, props.title, {
                            field: props.field,
                            date: newValue,
                        });
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
        </Stack>
    );
}
